@import './assets/css/bootstrap.min.css';

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Light mode */
@media (prefers-color-scheme: light) {
    body {
        background-color: white;
        color: black;
    }

    .card {
        background-color: white;
        color: black;
    }

    .footerup,
    .main-bar {
        background-color: white !important;
    }

    .sticky-header a {
        color: #666;
    }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    body {
        background-color: #222;
        color: white;
    }

    .card {
        background-color: #333;
        color: white;
    }

    .footerup,
    .main-bar {
        background-color: #333 !important;
    }

    .main-bar a {
        color: #999 !important;
    }

    .main-bar a:hover,
    .mainbar a:active {
        color: #00ABB3 !important;
    }

    .bg-white,
    .page-content {
        background-color: #3C4048 !important;
        color: #ccc;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #ccc !important;
    }

    .testimonial-home.number-slider .owl-dots {
        color: #ccc !important;
    }

    #slide-72-layer-7,
    #slide-73-layer-7 {
        border-bottom-color: #222 !important;
    }

    .sx-title-swip,
    .slide-left-social li {
        color: #00ABB3 !important;
    }

    .sx-title-swip:hover,
    .slide-left-social li a:hover {
        color: #ccc !important;
    }

    .slide-left-social ul li:after {
        background-color: #00ABB3 !important;
    }

    .tp-bannertimer {
        background-color: #333 !important;
    }

    .hilite-title strong {
        color: #00ABB3 !important;
    }

    .bg-gray {
        background-color: #222 !important;
    }

    .top-header a,
    .top-header svg {
        color: #ccc !important;
        fill: #ccc;
    }

    .top-header {
        background-color: #3C4048 !important;
        border-bottom: 1px solid black;
    }

    .st-count-number .counter {
        padding: 0 5px;
        background-color: #3C4048 !important;
    }

    .sep-line-one::before,
    .sep-line-one::after {
        background-color: #ccc !important;
    }

    .input-box input,
    .input-box textarea {
        color: #e1e6eb !important;
    }

    .contact-one .form-control {
        border-bottom: 1px solid #e1e6eb !important;
    }

    ::placeholder {
        color: #e1e6eb !important;
        opacity: 0.5 !important;
    }

    iframe {
        filter: invert(90%);
    }

    .footer-bottom {
        background-color: #111 !important;
    }

    .footer-top {
        background-color: #111 !important;
    }
}
/* 
 * @author    ThemePunch <info@themepunch.com>
 * @link      http://www.themepunch.com/
 * @copyright 2017 ThemePunch
*/

.rs-before-after-addon {
	
	overflow: hidden !important;
	
}

.rs-before-after-addon .tparrows {
	
	z-index: 1000 !important;
	
}

.rs-addon-beforeafter-btn {

	position: absolute;
	display: table;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;
	z-index: 102;
	
}

.rs-addon-beforeafter-btn i {
	
	display: table-cell;
	vertical-align: middle;
	position: relative;
	
}

.rs-beforeafter-shift-arrows .rs-addon-beforeafter-btn i {
	
	-webkit-transform: translateX(0) !important;
	transform: translateX(0) !important;
	
}

.rs-addon-beforeafter-btn-vertical,
.rs-addon-beforeafter-btn-vertical i {
	
	display: block;
	
}

.rs-addon-beforeafter-line {
	
	display: block;
	position: absolute;
	z-index: 102;
	
}

.rs-addon-beforeafter-line.rs-beforeafter-horizontal {
	
	top: 0;
	height: 100%;
	
}

.rs-addon-beforeafter-line.rs-beforeafter-vertical {
	
	left: 0;
	width: 100%;
	
}

.rs-before-after-element {
	
	opacity: 0;
	visibility: hidden;
	
}

.rs-before-after-element,
.rs-before-after-element:before {
	
	-webkit-user-select: none;
	-moz-user-select: none;
    user-select: none;
	
}

.rs-addon-beforeafter-bg {
	
	opacity: 0;
	z-index: 999;
	
}

.rs-addon-beforeafter-bg,
.rs-addon-beforeafter-revealer,
.rs-addon-beforeafter-revealer .tp-loop-wrap {

	width: 100%;
    height: 100%;
        
}

.rs-addon-beforeafter-bg,
.rs-addon-beforeafter-revealer {
     
	position: absolute;
	overflow: hidden;
      
} 

.rs-addon-beforeafter-before {
	
	top: 0;
	left: 0;
	
}

.rs-addon-beforeafter-horizontal .rs-addon-beforeafter-bg,
.rs-addon-beforeafter-horizontal .rs-addon-beforeafter-after {
	
	top: 0;
	right: 0;
	
} 

.rs-addon-beforeafter-vertical .rs-addon-beforeafter-bg,
.rs-addon-beforeafter-vertical .rs-addon-beforeafter-after {
	
	bottom: 0;
	left: 0;
	
}

.rs-addon-beforeafter-bg,
.rs-addon-beforeafter-after {
	
	opacity: 0;
	
}

.rs-addon-beforeafter-inner,
.rs-addon-beforeafter-bg-inner  {
	
	position: absolute;
	width: 100%;
	height: 100%;
	
}

.rs-addon-beforeafter-horizontal .rs-addon-beforeafter-inner,
.rs-addon-beforeafter-horizontal .rs-addon-beforeafter-bg-inner {
	
	right: 0;
	top: 0;
	
}

.rs-addon-beforeafter-vertical .rs-addon-beforeafter-inner,
.rs-addon-beforeafter-vertical .rs-addon-beforeafter-bg-inner {
	
	left: 0;
	bottom: 0;
	
}

.rs-video-beforeafter,
.rs-video-beforeafter .tp-mask-wrap {
	
	width: 100% !important;
	height: 100% !important;
	
}

.rs-before-after-addon:not(.before-after-carousel).dragging,
.rs-before-after-addon:not(.before-after-carousel).dragging *,
.rs-before-after-addon:not(.before-after-carousel).dragging .rs-addon-beforeafter-btn i:before {

    -webkit-user-select: none !important;
	-moz-user-select: none !important;
    user-select: none !important;
  
}

.rs-beforeafter-pointers,
.rs-beforeafter-pointers .rs-addon-beforeafter-inner {
	
	pointer-events: none;
	
}

.rs-beforeafter-pointers *:not(.rs-addon-beforeafter-inner) {
	
	pointer-events: auto;
	
}

.rs-beforeafter-pointers .rs-video-beforeafter,
.rs-beforeafter-pointers .rs-video-beforeafter * {
	
	pointer-events: none;
	
}






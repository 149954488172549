/* Light mode */
/* Dark mode */
/*____GENERAL 
======================================*/
body {
  background-color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

a {
  color: #3C4048;
  outline: 0 none;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: 0 none;
}

a:active,
a:hover,
a:focus {
  color: #00ABB3;
}

p {
  font-family: "Poppins", sans-serif;
}

p a {
  color: #4c4c4c;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

hr {
  clear: both;
}

section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
  display: block;
}

address {
  font-style: italic;
}

table {
  background-color: transparent;
  width: 100%;
}

table thead th {
  padding: 12px 8px !important;
  background-color: #f2f2f2;
}

table td {
  padding: 15px 10px !important;
}

@media only screen and (max-width: 360px) {
  table td {
    word-break: break-all;
  }
}
p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
  margin-bottom: 24px;
}

.overflow-hide {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.clear {
  clear: both;
}

.pull-none {
  float: none;
}

::selection {
  background: #3C4048;
  color: #fff;
}

::-moz-selection {
  background: #3C4048;
  color: #fff;
}

::-webkit-selection {
  background: #3C4048;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .col-xs-100pc {
    width: 100%;
  }
}
/*____HEADING & TITLE 
======================================*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 10px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #3C4048;
}

h1 a.sx-text-white,
h2 a.sx-text-white,
h3 a.sx-text-white,
h4 a.sx-text-white,
h5 a.sx-text-white,
h6 a.sx-text-white {
  color: #fff;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #00ABB3;
}

h1 a.sx-text-white:hover,
h2 a.sx-text-white:hover,
h3 a.sx-text-white:hover,
h4 a.sx-text-white:hover,
h5 a.sx-text-white:hover,
h6 a.sx-text-white:hover {
  color: #00ABB3;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 50px;
  color: #000;
}

h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 44px;
  color: #000;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 38px;
  color: #000;
  margin-bottom: 20px;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #000;
  margin-bottom: 15px;
}

h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}

h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

/*____TEXT & PARAGRAPH 
======================================*/
p {
  line-height: 24px;
  margin-bottom: 20px;
}

strong {
  font-weight: 600;
}

/*-------------------------------------
	text align
------------------------------------ */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/*-------------------------------------
	text vertical align
------------------------------------ */
.v-align-t {
  vertical-align: top;
}

.v-align-m {
  vertical-align: middle;
}

.v-align-b {
  vertical-align: bottom;
}

/*-------------------------------------
	text no line brack
------------------------------------ */
.text-nowrap {
  white-space: nowrap;
}

/*-------------------------------------
	text transform
------------------------------------ */
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/*-------------------------------------
	text size
------------------------------------ */
.font-12 {
  font-size: 12px;
  line-height: 18px;
}

.font-14 {
  font-size: 14px;
  line-height: 20px;
}

.font-16 {
  font-size: 16px;
  line-height: 22px;
}

.font-18 {
  font-size: 18px;
  line-height: 24px;
}

.font-20 {
  font-size: 20px;
  line-height: 26px;
}

.font-22 {
  font-size: 22px;
  line-height: 28px;
}

.font-24 {
  font-size: 24px;
  line-height: 30px;
}

.font-26 {
  font-size: 26px;
  line-height: 32px;
}

.font-28 {
  font-size: 28px;
  line-height: 34px;
}

.font-30 {
  font-size: 30px;
  line-height: 36px;
}

.font-32 {
  font-size: 32px;
  line-height: 38px;
}

.font-34 {
  font-size: 34px;
  line-height: 40px;
}

.font-36 {
  font-size: 36px;
  line-height: 42px;
}

.font-38 {
  font-size: 38px;
  line-height: 44px;
}

.font-40 {
  font-size: 40px;
  line-height: 46px;
}

.font-45 {
  font-size: 46px;
  line-height: 52px;
}

.font-50 {
  font-size: 50px;
  line-height: 56px;
}

.font-60 {
  font-size: 60px;
  line-height: 66px;
}

.font-70 {
  font-size: 70px;
  line-height: 76px;
}

.font-80 {
  font-size: 80px;
  line-height: 86px;
}

.font-90 {
  font-size: 90px;
  line-height: 96px;
}

.font-100 {
  font-size: 100px;
  line-height: 106px;
}

.font-120 {
  font-size: 120px;
  line-height: 126px;
}

.font-140 {
  font-size: 140px;
  line-height: 146px;
}

.font-160 {
  font-size: 160px;
  line-height: 166px;
}

.font-180 {
  font-size: 180px;
  line-height: 186px;
}

/*-------------------------------------
	text bold
------------------------------------ */
.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

/*-------------------------------------
	Letter Spacing
------------------------------------ */
.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

/*____LISTING STYLES 
======================================*/
dl,
ul,
ol {
  list-style-position: outside;
  padding: 0;
}

ul,
ol {
  margin-bottom: 24px;
}

ul li,
ol li {
  padding: 0;
}

dl {
  margin-left: 0;
  margin-bottom: 30px;
}

dl dd {
  margin-bottom: 10px;
}

.list-simple li {
  margin-bottom: 10px;
}

.list-simple li ul {
  margin-left: 15px;
  margin-top: 10px;
}

.list-simple.list-unstyled li ul {
  margin-left: 30px;
  margin-top: 10px;
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
  padding: 5px 5px 5px 20px;
  position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
  top: 3px;
  display: block;
  font-size: 15px;
  color: #000;
}

.list-circle li:before {
  top: 6px;
  font-size: 10px;
}

.list-circle li:before {
  content: "\f111";
}

.list-angle-right li:before {
  content: "\f105";
}

.list-arrow li:before {
  content: "\f0da";
}

.list-check li:before {
  content: "\f00c";
}

.list-checked li:before {
  content: "\f046";
}

.list-check-circle li:before {
  content: "\f058";
}

.list-chevron-circle li:before {
  content: "\f138";
}

.list-arrow-circle li:before {
  content: "\f0a9";
}

.list-times-circle li:before {
  content: "\f057";
}

/*-------------------------------------
	listing before icon color css
-------------------------------------*/
ul.primary li:before {
  color: #00ABB3;
}

ul.secondry li:before {
  color: #77C04B;
}

ul.black li:before {
  color: #000;
}

ul.white li:before {
  color: #fff;
}

ul.orange li:before {
  color: #FF6600;
}

ul.green li:before {
  color: #00CC00;
}

ul.red li:before {
  color: #FF3737;
}

dl.no-margin,
ul.no-margin,
ol.no-margin {
  margin: 0px !important;
  list-style: none;
}

/*-------------------------------------
	listing before numbers
-------------------------------------*/
ol.list-num-count {
  counter-reset: li;
  padding-left: 0;
}

ol.list-num-count > li {
  position: relative;
  margin: 0 0 6px 30px;
  padding: 4px 8px;
  list-style: none;
}

ol.list-num-count > li:before {
  content: counter(li, decimal);
  counter-increment: li;
  position: absolute;
  top: 0;
  left: -28px;
  width: 28px;
  height: 28px;
  padding: 5px;
  color: #fff;
  background: #3C4048;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
}

ol.list-num-count.lower-alpha > li:before {
  content: counter(li, lower-alpha);
}

ol.list-num-count.upper-alpha > li:before {
  content: counter(li, upper-alpha);
}

ol.list-num-count.list-outline > li:before {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}

ol.list-num-count.list-outline-none > li:before {
  background-color: transparent;
  color: #000;
}

/*____BOX SPACING (Padding & Margin)
======================================*/
/*-------------------------------------
	padding (around)
-------------------------------------*/
.p-a0 {
  padding: 0;
}

.p-a5 {
  padding: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-a40 {
  padding: 40px;
}

.p-a50 {
  padding: 50px;
}

.p-a60 {
  padding: 60px;
}

.p-a70 {
  padding: 70px;
}

.p-a80 {
  padding: 80px;
}

.p-a90 {
  padding: 90px;
}

.p-a100 {
  padding: 100px;
}

.p-a110 {
  padding: 110px;
}

.p-a120 {
  padding: 120px;
}

.p-a130 {
  padding: 130px;
}

.p-a140 {
  padding: 140px;
}

.p-a150 {
  padding: 150px;
}

/*-------------------------------------
	Padding (top)
-------------------------------------*/
.p-t0 {
  padding-top: 0;
}

.p-t5 {
  padding-top: 5px;
}

.p-t10 {
  padding-top: 10px;
}

.p-t15 {
  padding-top: 15px;
}

.p-t20 {
  padding-top: 20px;
}

.p-t30 {
  padding-top: 30px;
}

.p-t40 {
  padding-top: 40px;
}

.p-t50 {
  padding-top: 50px;
}

.p-t60 {
  padding-top: 60px;
}

.p-t70 {
  padding-top: 70px;
}

.p-t80 {
  padding-top: 80px;
}

.p-t90 {
  padding-top: 90px;
}

.p-t100 {
  padding-top: 100px;
}

.p-t110 {
  padding-top: 110px;
}

.p-t120 {
  padding-top: 120px;
}

.p-t130 {
  padding-top: 130px;
}

.p-t140 {
  padding-top: 140px;
}

.p-t150 {
  padding-top: 150px;
}

/*-------------------------------------
    Padding (bottom)
-------------------------------------*/
.p-b0 {
  padding-bottom: 0;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-b110 {
  padding-bottom: 110px;
}

.p-b120 {
  padding-bottom: 120px;
}

.p-b130 {
  padding-bottom: 130px;
}

.p-b140 {
  padding-bottom: 140px;
}

.p-b150 {
  padding-bottom: 150px;
}

/*-------------------------------------
	Padding (left)
-------------------------------------*/
.p-l0 {
  padding-left: 0;
}

.p-l5 {
  padding-left: 5px;
}

.p-l10 {
  padding-left: 10px;
}

.p-l15 {
  padding-left: 15px;
}

.p-l20 {
  padding-left: 20px;
}

.p-l30 {
  padding-left: 30px;
}

.p-l40 {
  padding-left: 40px;
}

.p-l50 {
  padding-left: 50px;
}

.p-l60 {
  padding-left: 60px;
}

.p-l70 {
  padding-left: 70px;
}

.p-l80 {
  padding-left: 80px;
}

.p-l90 {
  padding-left: 90px;
}

.p-l100 {
  padding-left: 100px;
}

.p-l110 {
  padding-left: 110px;
}

.p-l120 {
  padding-left: 120px;
}

.p-l130 {
  padding-left: 130px;
}

.p-l140 {
  padding-left: 140px;
}

.p-l150 {
  padding-left: 150px;
}

/*-------------------------------------
	Padding (right)
-------------------------------------*/
.p-r0 {
  padding-right: 0;
}

.p-r5 {
  padding-right: 5px;
}

.p-r10 {
  padding-right: 10px;
}

.p-r15 {
  padding-right: 15px;
}

.p-r20 {
  padding-right: 20px;
}

.p-r30 {
  padding-right: 30px;
}

.p-r40 {
  padding-right: 40px;
}

.p-r50 {
  padding-right: 50px;
}

.p-r60 {
  padding-right: 60px;
}

.p-r70 {
  padding-right: 70px;
}

.p-r80 {
  padding-right: 80px;
}

.p-r90 {
  padding-right: 90px;
}

.p-r100 {
  padding-right: 100px;
}

.p-r110 {
  padding-right: 110px;
}

.p-r120 {
  padding-right: 120px;
}

.p-r130 {
  padding-right: 130px;
}

.p-r140 {
  padding-right: 140px;
}

.p-r150 {
  padding-right: 150px;
}

/*-------------------------------------
	Padding (left right)
-------------------------------------*/
.p-lr0 {
  padding-left: 0;
  padding-right: 0;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-lr120 {
  padding-left: 120px;
  padding-right: 120px;
}

.p-lr150 {
  padding-left: 150px;
  padding-right: 150px;
}

/*-------------------------------------
	Padding (top bottom)
-------------------------------------*/
.p-tb0 {
  padding-bottom: 0;
  padding-top: 0;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.p-tb120 {
  padding-bottom: 120px;
  padding-top: 120px;
}

.p-tb150 {
  padding-bottom: 150px;
  padding-top: 150px;
}

/*-------------------------------------
	margin (around)
-------------------------------------*/
.m-a-1 {
  margin: -1px;
}

.m-a0 {
  margin: 0;
}

.m-a5 {
  margin: 5px;
}

.m-a10 {
  margin: 10px;
}

.m-a15 {
  margin: 15px;
}

.m-a20 {
  margin: 20px;
}

.m-a30 {
  margin: 30px;
}

.m-a40 {
  margin: 40px;
}

.m-a50 {
  margin: 50px;
}

.m-a60 {
  margin: 60px;
}

.m-a70 {
  margin: 70px;
}

.m-a80 {
  margin: 80px;
}

.m-a90 {
  margin: 90px;
}

.m-a100 {
  margin: 100px;
}

.m-a110 {
  margin: 110px;
}

.m-a120 {
  margin: 120px;
}

.m-a130 {
  margin: 130px;
}

.m-a140 {
  margin: 140px;
}

.m-a150 {
  margin: 150px;
}

/*-------------------------------------
	marging (top)
-------------------------------------*/
.m-t0 {
  margin-top: 0;
}

.m-t5 {
  margin-top: 5px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t15 {
  margin-top: 15px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t30 {
  margin-top: 30px;
}

.m-t40 {
  margin-top: 40px;
}

.m-t50 {
  margin-top: 50px;
}

.m-t60 {
  margin-top: 60px;
}

.m-t70 {
  margin-top: 70px;
}

.m-t80 {
  margin-top: 80px;
}

.m-t90 {
  margin-top: 90px;
}

.m-t100 {
  margin-top: 100px;
}

.m-t110 {
  margin-top: 110px;
}

.m-t120 {
  margin-top: 120px;
}

.m-t130 {
  margin-top: 130px;
}

.m-t140 {
  margin-top: 140px;
}

.m-t150 {
  margin-top: 150px;
}

/*-------------------------------------
	marging (bottom)
-------------------------------------*/
.m-b0 {
  margin-bottom: 0;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-b110 {
  margin-bottom: 110px;
}

.m-b120 {
  margin-bottom: 120px;
}

.m-b130 {
  margin-bottom: 130px;
}

.m-b140 {
  margin-bottom: 140px;
}

.m-b150 {
  margin-bottom: 150px;
}

/*-------------------------------------
	marging (left)
-------------------------------------*/
.m-l0 {
  margin-left: 0;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l20 {
  margin-left: 20px;
}

.m-l30 {
  margin-left: 30px;
}

.m-l40 {
  margin-left: 40px;
}

.m-l50 {
  margin-left: 50px;
}

.m-l60 {
  margin-left: 60px;
}

.m-l70 {
  margin-left: 70px;
}

.m-l80 {
  margin-left: 80px;
}

.m-l90 {
  margin-left: 90px;
}

.m-l100 {
  margin-left: 100px;
}

.m-l110 {
  margin-left: 110px;
}

.m-l120 {
  margin-left: 120px;
}

.m-l130 {
  margin-left: 130px;
}

.m-l140 {
  margin-left: 140px;
}

.m-l150 {
  margin-left: 150px;
}

/*-------------------------------------
	marging (right)
-------------------------------------*/
.m-r0 {
  margin-right: 0;
}

.m-r5 {
  margin-right: 5px;
}

.m-r10 {
  margin-right: 10px;
}

.m-r15 {
  margin-right: 15px;
}

.m-r20 {
  margin-right: 20px;
}

.m-r30 {
  margin-right: 30px;
}

.m-r40 {
  margin-right: 40px;
}

.m-r50 {
  margin-right: 50px;
}

.m-r60 {
  margin-right: 60px;
}

.m-r70 {
  margin-right: 70px;
}

.m-r80 {
  margin-right: 80px;
}

.m-r90 {
  margin-right: 90px;
}

.m-r100 {
  margin-right: 100px;
}

.m-r110 {
  margin-right: 110px;
}

.m-r120 {
  margin-right: 120px;
}

.m-r130 {
  margin-right: 130px;
}

.m-r140 {
  margin-right: 140px;
}

.m-r150 {
  margin-right: 150px;
}

/*-------------------------------------
	marging (left right)
-------------------------------------*/
.m-lr0 {
  margin-left: 0;
  margin-right: 0;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-lr120 {
  margin-left: 120px;
  margin-right: 120px;
}

.m-lr150 {
  margin-left: 150px;
  margin-right: 150px;
}

/*-------------------------------------
	marging (top bottom)
-------------------------------------*/
.m-tb0 {
  margin-bottom: 0;
  margin-top: 0;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

.m-tb120 {
  margin-bottom: 120px;
  margin-top: 120px;
}

.m-tb150 {
  margin-bottom: 150px;
  margin-top: 150px;
}

/*-------------------------------------
	colum gap less
-------------------------------------*/
.no-col-gap [class*=col-xs-],
.no-col-gap [class*=col-sm-],
.no-col-gap [class*=col-md-],
.no-col-gap [class*=col-lg-] {
  padding-left: 0;
  padding-right: 0;
}

/*____POSITIONS ( absolute , relative , statice & z-index)
======================================*/
/*-------------------------------------
	position
-------------------------------------*/
/*relative*/
.relative {
  position: relative;
}

/*absolute*/
.absolute {
  position: absolute;
}

/*static*/
.static {
  position: static;
}

/*-------------------------------------
	z-index
-------------------------------------*/
.z-index1 {
  z-index: 1;
}

.z-index2 {
  z-index: 2;
}

.z-index3 {
  z-index: 3;
}

.z-index4 {
  z-index: 4;
}

.z-index5 {
  z-index: 5;
}

.z-index6 {
  z-index: 6;
}

.z-index7 {
  z-index: 7;
}

.z-index8 {
  z-index: 8;
}

.z-index9 {
  z-index: 9;
}

.z-index10 {
  z-index: 10;
}

.z-index100 {
  z-index: 100;
}

.z-index999 {
  z-index: 999;
}

/*____COLOR
======================================*/
/*-------------------------------------
	text
-------------------------------------*/
.text-white {
  color: #fff;
}

.sx-text-primary {
  color: #00ABB3;
}

.text-secondry {
  color: #3C4048;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #f5f6f6;
}

.text-gray-dark {
  color: #d3d3d3;
}

.text-red {
  color: #99090A;
}

.text-green {
  color: #557131;
}

.text-yellow {
  color: #D5DD02;
}

.text-light-blue {
  color: #004F83;
}

/*-------------------------------------
	background color
-------------------------------------*/
.bg-transparent {
  background-color: transparent;
}

.sx-bg-primary {
  background-color: #00ABB3;
}

.sx-bg-secondry {
  background-color: #3C4048;
}

.bg-dark {
  background-color: #141414;
}

.bg-dark-light {
  background-color: #2a2a2a;
}

.bg-white {
  background-color: #FFF;
}

.bg-black {
  background-color: #000;
}

.bg-black-light {
  background-color: #212121;
}

.bg-gray {
  background-color: #eef1f2;
}

.bg-gray-light {
  background-color: #ececec;
}

.bg-gray-dark {
  background-color: #d3d3d3;
}

.bg-red {
  background-color: #fb5455;
}

.bg-green {
  background-color: #a5de5c;
}

.bg-yellow {
  background-color: #fff000;
}

.bg-dark-blue {
  background-color: #161b23;
}

.bg-light-blue {
  background-color: #3097db;
}

/*-------------------------------------
	background image
-------------------------------------*/
.bg-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 768px) {
  .bg-parallax {
    background-attachment: fixed !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
/*____OVERLAY ( transparient box style  )
======================================*/
.overlay-wraper {
  position: relative;
}

.overlay-main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.overlay-light .overlay-main {
  opacity: 0.3;
}

.overlay-dark .overlay-main {
  opacity: 0.9;
}

.overlay-wraper > .container,
.overlay-wraper > .container-fluid,
.overlay-wraper > .sx-icon-box-wraper,
.overlay-wraper > .sx-left-part,
.overlay-wraper > .sx-right-part {
  position: relative;
  z-index: 1;
}

/*left right part css*/
.sx-left-part,
.sx-right-part {
  width: auto;
}

.sx-left-part {
  padding: 80px 15px 50px 15px;
}

.sx-right-part {
  padding: 80px 15px 50px 15px;
  max-width: 555px;
}

.sx-right-part:hover {
  cursor: col-resize;
}

@media only screen and (max-width: 991px) {
  .sx-left-part {
    max-width: 720px;
    margin: 0px auto;
  }
  .sx-right-part {
    max-width: 720px;
    margin: 0px auto;
    padding-bottom: 130px;
  }
}
@media only screen and (max-width: 768px) {
  .sx-right-part {
    width: 100%;
    padding: 0px 15px;
  }
  .sx-right-part {
    padding-bottom: 80px !important;
  }
  .sx-left-part {
    padding: 30px 0px 0px 0px !important;
  }
}
@media only screen and (max-width: 575px) {
  .sx-left-part {
    padding: 30px 0px 30px 0px !important;
  }
}
@media only screen and (max-width: 768px) {
  .sx-right-part {
    padding-bottom: 80px !important;
  }
}
@media only screen and (max-width: 480px) {
  .sx-right-part {
    padding-bottom: 55px !important;
  }
}
/*use for box*/
.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.overlay-icon {
  list-style: none;
  position: absolute;
  left: 0%;
  top: 50%;
  width: 100%;
  z-index: 1 !important;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
}

.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0px;
}

.overlay-icon .sx-icon-box-xs {
  line-height: 40px;
}

.overlay-icon a:hover {
  color: #fff;
}

.overlay-bx:hover a > i,
.sx-thum-bx:hover .overlay-bx a > i,
.sx-box:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.overlay-bx:hover,
.sx-thum-bx:hover .overlay-bx,
.sx-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

/*____BOXES CSS 
======================================*/
.rounded-bx,
.sx-box,
.sx-icon-box,
.sx-icon-box-small,
.sx-thum-bx,
.sx-post-thum {
  position: relative;
}

/*-------------------------------------
	box content
-------------------------------------*/
.sx-box {
  position: relative;
}

.sx-box.no-margin {
  margin-bottom: 0;
}

/*-------------------------------------
	For icons with box
-------------------------------------*/
.sx-icon-box-xld,
.sx-icon-box-xl,
.sx-icon-box-lg,
.sx-icon-box-md,
.sx-icon-box-sm,
.sx-icon-box-xs {
  display: inline-block;
  text-align: center;
}

.sx-icon-box-xld {
  width: 180px;
  height: 180px;
}

.sx-icon-box-xl {
  width: 150px;
  height: 150px;
}

.sx-icon-box-lg {
  width: 120px;
  height: 120px;
}

.sx-icon-box-md {
  width: 100px;
  height: 100px;
}

.sx-icon-box-sm {
  width: 80px;
  height: 80px;
}

.sx-icon-box-xs {
  width: 40px;
  height: 40px;
}

.sx-icon-box-xld.radius,
.sx-icon-box-xl.radius,
.sx-icon-box-lg.radius,
.sx-icon-box-md.radius,
.sx-icon-box-sm.radius,
.sx-icon-box-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.sx-icon-box-xld i,
.sx-icon-box-xl i,
.sx-icon-box-lg i,
.sx-icon-box-md i,
.sx-icon-box-sm i,
.sx-icon-box-xs i {
  vertical-align: middle;
}

.sx-icon-box-xld i {
  font-size: 100px;
}

.sx-icon-box-xl i {
  font-size: 80px;
}

.sx-icon-box-lg i {
  font-size: 60px;
}

.sx-icon-box-md i {
  font-size: 45px;
}

.sx-icon-box-sm i {
  font-size: 30px;
}

.sx-icon-box-xs i {
  font-size: 20px;
}

.sx-icon-box-xld img,
.sx-icon-box-xl img,
.sx-icon-box-lg img,
.sx-icon-box-md img,
.sx-icon-box-sm img,
.sx-icon-box-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.sx-icon-box-xld img {
  height: 90px;
}

.sx-icon-box-xl img {
  height: 80px;
}

.sx-icon-box-lg img {
  height: 55px;
}

.sx-icon-box-md img {
  height: 40px;
}

.sx-icon-box-sm img {
  height: 30px;
}

.sx-icon-box-xs img {
  height: 20px;
}

/*-------------------------------------
	Border
-------------------------------------*/
.bdr-white {
  border-color: #ffffff;
}

.bdr-black {
  border-color: #000000;
}

.bdr-primary {
  border-color: #00ABB3;
}

.bdr-gray {
  border-color: #dddddd;
}

.bdr-gray-light {
  border-color: #eeeeee;
}

.bdr-gray-dark {
  border-color: #333333;
}

.bdr-solid {
  border-style: solid;
}

.bdr-dot {
  border-style: dotted;
}

.bdr-dash {
  border-style: dashed;
}

.bdr-1 {
  border-width: 1px;
}

.bdr-2 {
  border-width: 2px;
}

.bdr-3 {
  border-width: 3px;
}

.bdr-4 {
  border-width: 4px;
}

.bdr-5 {
  border-width: 5px;
}

.bdr-l-1 {
  border-width: 0px 0px 0px 1px;
}

.bdr-r-1 {
  border-width: 0px 1px 0px 0px;
}

.bdr-t-1 {
  border-width: 1px 0px 0px 0px;
}

.bdr-b-1 {
  border-width: 0px 0px 1px 0px;
}

.bdr-l-2 {
  border-width: 0px 0px 0px 2px;
}

.bdr-r-2 {
  border-width: 0px 2px 0px 0px;
}

.bdr-t-2 {
  border-width: 2px 0px 0px 0px;
}

.bdr-b-2 {
  border-width: 0px 0px 2px 0px;
}

.bdr-l-3 {
  border-width: 0px 0px 0px 3px;
}

.bdr-r-3 {
  border-width: 0px 3px 0px 0px;
}

.bdr-t-3 {
  border-width: 3px 0px 0px 0px;
}

.bdr-b-3 {
  border-width: 0px 0px 1px 0px;
}

.bdr-l-4 {
  border-width: 0px 0px 0px 4px;
}

.bdr-r-4 {
  border-width: 0px 4px 0px 0px;
}

.bdr-t-4 {
  border-width: 4px 0px 0px 0px;
}

.bdr-b-4 {
  border-width: 0px 0px 4px 0px;
}

.bdr-l-5 {
  border-width: 0px 0px 0px 5px;
}

.bdr-r-5 {
  border-width: 0px 5px 0px 0px;
}

.bdr-t-5 {
  border-width: 5px 0px 0px 0px;
}

.bdr-b-5 {
  border-width: 0px 0px 5px 0px;
}

/*-------------------------------------
	For only icons
-------------------------------------*/
.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}

.icon-xld i,
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
  vertical-align: middle;
}

.icon-xld {
  width: 120px;
}

.icon-xld i {
  font-size: 100px;
}

.icon-xld.inline-icon {
  width: auto;
}

.icon-xl {
  width: 80px;
}

.icon-xl i {
  font-size: 60px;
}

.icon-xl.inline-icon {
  width: auto;
}

.icon-lg {
  width: 80px;
}

.icon-lg i {
  font-size: 60px;
}

.icon-lg.inline-icon {
  width: auto;
}

.icon-md {
  width: 60px;
}

.icon-md i {
  font-size: 45px;
}

.icon-md.inline-icon {
  width: auto;
}

.icon-sm {
  width: 40px;
}

.icon-sm i {
  font-size: 30px;
}

.icon-sm.inline-icon {
  width: auto;
}

.icon-xs {
  width: 30px;
}

.icon-xs i {
  font-size: 20px;
}

.icon-xs.inline-icon {
  width: auto;
}

.icon-xld img,
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.icon-xld img {
  height: 90px;
}

.icon-xl img {
  height: 80px;
}

.icon-lg img {
  height: 70px;
}

.icon-md img {
  height: 60px;
}

.icon-sm img {
  height: 30px;
}

.icon-xs img {
  height: 20px;
}

/*-------------------------------------
	place icon with box
-------------------------------------*/
.about-types .sx-icon-box-wraper {
  border-width: 0px 0px 0px 3px;
}

.sx-icon-box-wraper {
  position: relative;
}

.sx-icon-box-wraper .sx-tilte {
  margin-top: 0;
}

.sx-icon-box-wraper .after-titile-line {
  margin-bottom: 10px;
}

.sx-icon-box-wraper p:last-child {
  margin: 0px;
}

.icon-content {
  overflow: hidden;
}

.sx-icon-box-xld,
.sx-icon-box-xl,
.sx-icon-box-lg,
.sx-icon-box-md,
.sx-icon-box-sm,
.sx-icon-box-xs {
  display: table;
}

.sx-icon-box-xld .icon-cell,
.sx-icon-box-xl .icon-cell,
.sx-icon-box-lg .icon-cell,
.sx-icon-box-md .icon-cell,
.sx-icon-box-sm .icon-cell,
.sx-icon-box-xs .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

/*-------------------------------------
	icon box left align
-------------------------------------*/
.sx-icon-box-wraper.left .sx-icon-box-xld,
.sx-icon-box-wraper.left .sx-icon-box-xl,
.sx-icon-box-wraper.left .sx-icon-box-lg,
.sx-icon-box-wraper.left .sx-icon-box-md,
.sx-icon-box-wraper.left .sx-icon-box-sm,
.sx-icon-box-wraper.left .sx-icon-box-xs {
  float: left;
  margin-right: 20px;
}

.sx-icon-box-wraper.left .icon-xld,
.sx-icon-box-wraper.left .icon-xl,
.sx-icon-box-wraper.left .icon-lg,
.sx-icon-box-wraper.left .icon-md,
.sx-icon-box-wraper.left .icon-sm,
.sx-icon-box-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
}

/*-------------------------------------
	icon box right align
-------------------------------------*/
.sx-icon-box-wraper.right {
  text-align: right;
}

.sx-icon-box-wraper.right .sx-icon-box-xld,
.sx-icon-box-wraper.right .sx-icon-box-xl,
.sx-icon-box-wraper.right .sx-icon-box-lg,
.sx-icon-box-wraper.right .sx-icon-box-md,
.sx-icon-box-wraper.right .sx-icon-box-sm,
.sx-icon-box-wraper.right .sx-icon-box-xs {
  float: right;
  display: table;
  margin-left: 20px;
}

.sx-icon-box-wraper.right .icon-xld,
.sx-icon-box-wraper.right .icon-xl,
.sx-icon-box-wraper.right .icon-lg,
.sx-icon-box-wraper.right .icon-md,
.sx-icon-box-wraper.right .icon-sm,
.sx-icon-box-wraper.right .icon-xs {
  float: right;
  margin-left: 10px;
}

/*-------------------------------------
	icon box center align
-------------------------------------*/
.sx-icon-box-wraper.center {
  text-align: center;
}

.sx-icon-box-wraper.center .sx-icon-box-xld,
.sx-icon-box-wraper.center .sx-icon-box-xl,
.sx-icon-box-wraper.center .sx-icon-box-lg,
.sx-icon-box-wraper.center .sx-icon-box-md,
.sx-icon-box-wraper.center .sx-icon-box-sm,
.sx-icon-box-wraper.center .sx-icon-box-xs {
  margin-left: auto;
  margin-right: auto;
}

.sx-icon-box-wraper.bx-style-1,
.sx-icon-box-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
}

.sx-icon-box-wraper.bx-style-2.center [class*=sx-icon-box-],
.sx-icon-box-wraper.bx-style-2.left [class*=sx-icon-box-],
.sx-icon-box-wraper.bx-style-2.right [class*=sx-icon-box-] {
  position: absolute;
}

.sx-icon-box-wraper.bx-style-2.center [class*=sx-icon-box-] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sx-icon-box-wraper.bx-style-2.center .sx-icon-box-xl {
  margin-left: -75px;
}

.sx-icon-box-wraper.bx-style-2.center .sx-icon-box-lg {
  margin-left: -60px;
}

.sx-icon-box-wraper.bx-style-2.center .sx-icon-box-md {
  margin-left: -50px;
}

.sx-icon-box-wraper.bx-style-2.center .sx-icon-box-sm {
  margin-left: -40px;
}

.sx-icon-box-wraper.bx-style-2.center .sx-icon-box-xs {
  margin-left: -20px;
}

.sx-icon-box-wraper.bx-style-2.left [class*=sx-icon-box-] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.sx-icon-box-wraper.bx-style-2.right [class*=sx-icon-box-] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

/*-------------------------------------
	media with content box css
-------------------------------------*/
.sx-box,
.sx-info,
.sx-tilte,
.sx-tilte-inner {
  position: relative;
}

.sx-tilte-inner {
  display: inline-block;
}

.sx-tilte-inner.skew-title:after {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: -1;
  -moz-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  transform: skewX(20deg);
}

.sx-box[class*=border-],
.sx-info[class*=border-],
.sx-icon-box-wraper[class*=border-] {
  border-color: #eee;
}

.sx-info.border-1,
.sx-info.border-2,
.sx-info.border-3,
.sx-info.border-4,
.sx-info.border-5 {
  border-top: none;
}

/*-------------------------------------
	for images
-------------------------------------*/
.sx-thum-bx,
.sx-post-thum {
  background-color: #000;
}

.sx-thum-bx img,
.sx-post-thum img {
  width: 100%;
  height: auto;
}

.yt-thum-box {
  max-height: 270px;
}

/*____IMAGES OVERLAY EFFECTS (overly animation on images)  
======================================*/
.sx-img-overlay1,
.sx-img-overlay2,
.sx-img-overlay7 {
  position: relative;
}

.sx-img-overlay1:before,
.sx-img-overlay1:after,
.sx-img-overlay2:before,
.sx-img-overlay2:after,
.sx-img-overlay7:before,
.sx-img-overlay7:after {
  content: "";
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.gradi-black:before,
.gradi-black:after,
.gradi-white:before,
.gradi-white:after {
  content: "";
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

/*-------------------------------------
	effect 1
-------------------------------------*/
.sx-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.sx-img-overlay1:hover:before,
.sx-box:hover .sx-img-overlay1:before {
  opacity: 0.5;
}

/*-------------------------------------
	effect 2
-------------------------------------*/
.sx-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );*/
  /* IE6-9 */
}

.sx-img-overlay2:hover:before,
.sx-box:hover .sx-img-overlay2:before {
  opacity: 0.9;
}

/*-------------------------------------
	7. effect 7
-------------------------------------*/
.sx-img-overlay7:before,
.sx-img-overlay7:after {
  height: 100%;
}

.sx-img-overlay7:after {
  left: auto;
  right: 0;
}

.sx-img-overlay7:hover:before,
.sx-img-overlay7:hover:after,
.sx-box:hover .sx-img-overlay7:before,
.sx-box:hover .sx-img-overlay7:after {
  width: 50%;
}

/*-------------------------------------
	Gradient-black
-------------------------------------*/
.gradi-black:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 90%, rgb(0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 90%, rgb(0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 90%, rgb(0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#000000", GradientType=0);
  /* IE6-9 */
}

/*-------------------------------------
	Gradient-white
-------------------------------------*/
.gradi-white:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.9) 90%, rgb(255, 255, 255) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.9) 90%, rgb(255, 255, 255) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.9) 90%, rgb(255, 255, 255) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#ffffff", GradientType=0);
  /* IE6-9 */
}

/*____shortcodeS 
======================================*/
/*-------------------------------------
	Buttons 
--------------------------------------- */
/* 1.1 theme default button */
.btn {
  padding-top: 9px;
  padding-bottom: 9px;
}

.site-button,
.site-button-secondry {
  position: relative;
  padding: 15px 15px;
  letter-spacing: 8px;
  font-weight: 400;
  display: inline-block;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  outline: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  line-height: 1.42857;
  margin-left: -1px;
  text-decoration: none !important;
}

@media only screen and (max-width: 768px) {
  .site-button,
  .site-button-secondry {
    padding: 10px 10px;
    letter-spacing: 3px;
    font-size: 12px;
  }
}
.rev-btn .site-button,
.rev-btn .site-button-secondry {
  letter-spacing: 10px !important;
}

@media only screen and (max-width: 480px) {
  .rev-btn .site-button,
  .rev-btn .site-button-secondry {
    letter-spacing: 0px !important;
    font-size: 14px !important;
  }
}
.site-button {
  background-color: #00ABB3;
  color: #fff;
}

.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button {
  background-color: #00ABB3;
  color: #fff;
}

.site-button:hover {
  background-color: #222;
  color: #fff;
}

.site-button-secondry {
  background-color: #000;
  color: #fff;
}

.site-button-secondry:active,
.site-button-secondry:hover,
.site-button-secondry:focus,
.active > .site-button-secondry {
  background-color: #222;
  color: #fff;
}

.mfp-video.video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: -25px;
  color: #fff;
  display: block;
  z-index: 10;
  font-size: 14px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.mfp-video.video-play-btn:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid transparent;
}

.mfp-video.video-play-btn i {
  margin-right: -5px;
}

/* button text uppercase */
.site-button.text-uppercase {
  text-transform: uppercase;
}

/*button size */
.button-sm {
  padding: 5px 10px;
  font-size: 12px;
}

.button-lg {
  padding: 10px 20px;
  font-size: 16px;
}

.button-xl {
  padding: 30px 50px;
  font-size: 24px;
}

/* button rounded */
.radius-no {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.radius-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.radius-xl {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.radius-bx {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}

/* white */
.site-button.white {
  background-color: #fff;
  color: #777;
}

.site-button.white:hover,
.site-button.white:active,
.site-button.white:focus {
  background-color: #F4F4F4;
  color: #555;
}

/* black */
.site-button.black {
  background-color: #000;
  color: #fff;
}

.site-button.black:hover,
.site-button.black:active,
.site-button.black:focus {
  background-color: #171717 !important;
  color: #fff !important;
}

/* Gray */
.site-button.gray {
  background-color: #666666;
  color: #fff;
}

.site-button.gray:hover,
.site-button.gray:active,
.site-button.gray:focus {
  background-color: #555555;
  color: #fff;
}

/* pink */
.site-button.pink {
  background-color: #e63f75;
  color: #fff;
}

.site-button.pink:hover,
.site-button.pink:active,
.site-button.pink:focus {
  background-color: #d22b61;
  color: #fff;
}

/* Blue */
.site-button.blue {
  background-color: #42B8D4;
  color: #fff;
}

.site-button.blue:hover,
.site-button.blue:active,
.site-button.blue:focus {
  background-color: #2ca2be;
  color: #fff;
}

/* Green */
.site-button.green {
  background-color: #35B494;
  color: #fff;
}

.site-button.green:hover,
.site-button.green:active,
.site-button.green:focus {
  background-color: #26a585;
  color: #fff;
}

/* Orange */
.site-button.orange {
  background-color: #E56713;
  color: #fff;
}

.site-button.orange:hover,
.site-button.orange:active,
.site-button.orange:focus {
  background-color: #d55703;
  color: #fff;
}

/* Red */
.site-button.red {
  background-color: #D93223;
  color: #fff;
}

.site-button.red:hover,
.site-button.red:active,
.site-button.red:focus {
  background-color: #c51e0f;
  color: #fff;
}

/*Brown */
.site-button.brown {
  background-color: #69441F;
  color: #fff;
}

.site-button.brown:hover,
.site-button.brown:active,
.site-button.brown:focus {
  background-color: #5f3a15;
  color: #fff;
}

/* Yellow */
.site-button.yellow {
  background-color: #ecc731;
  color: #fff;
}

.site-button.yellow:hover,
.site-button.yellow:active,
.site-button.yellow:focus {
  background-color: #d4af19;
  color: #fff;
}

/* purple */
.site-button.purple {
  background-color: #AE1AF7;
  color: #fff;
}

.site-button.purple:hover,
.site-button.purple:active,
.site-button.purple:focus {
  background-color: #9804e1;
  color: #fff;
}

/* Outline */
.site-button.outline {
  color: #888;
  background: none;
  border-width: 2px;
  border-style: solid;
  border-color: #eee;
}

/* Outline white  */
.site-button.outline.white {
  color: #e7e7e7;
  border-color: #e7e7e7;
}

.site-button.outline.white:hover {
  color: #666666;
}

/* Outline black */
.site-button.outline.black {
  color: #171717;
  border-color: #171717;
}

/* Outline gray */
.site-button.outline.gray {
  color: #666666;
  border-color: #666666;
}

/* Outline pink */
.site-button.outline.pink {
  color: #e63f75;
  border-color: #e63f75;
}

/* Outline blue */
.site-button.outline.blue {
  color: #42b8d4;
  border-color: #42b8d4;
}

/* Outline green */
.site-button.outline.green {
  color: #35b494;
  border-color: #35b494;
}

/* Outline orange */
.site-button.outline.orange {
  color: #e56713;
  border-color: #e56713;
}

/* Outline red */
.site-button.outline.red {
  color: #d93223;
  border-color: #d93223;
}

/* Outline brown */
.site-button.outline.brown {
  color: #69441f;
  border-color: #69441f;
}

/* Outline yellow */
.site-button.outline.yellow {
  color: #00ABB3;
  border-color: #00ABB3;
}

/* Outline purple */
.site-button.outline.purple {
  color: #ae1af7;
  border-color: #ae1af7;
}

/* Outline hover */
.site-button.outline.black:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}

/* button text link */
.site-button-link {
  display: inline-block;
  font-weight: 500;
  position: relative;
  padding-left: 0;
  font-size: 14px;
  z-index: 1;
  text-transform: uppercase;
}

.site-button-link i {
  display: inline-block;
  margin: -4px 0px 0px 5px;
  font-size: 16px;
  vertical-align: middle;
  line-height: 24px;
}

.site-button-link:hover {
  -webkit-animation: focus-in-contract 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-contract 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.site-button-link:before {
  content: "";
  left: 0px;
  bottom: 0px;
  height: 5px;
  background-color: #00ABB3;
  width: 80%;
  position: absolute;
  z-index: -1;
  -webkit-border-radius: 0px 50% 15px 0px;
  border-radius: 0px 50% 15px 0px;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.site-button-link:hover:before {
  background: none;
}

@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 8px;
    -webkit-filter: blur(8px);
    filter: blur(8px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-contract {
  0% {
    letter-spacing: 8px;
    -webkit-filter: blur(8px);
    filter: blur(8px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
/*----------------------
	HOVER Animation
------------------------*/
.arrow-animation {
  position: relative;
  animation: linear infinite alternate;
  transform: translateX(-50%) translateY(-50%);
  animation-name: run;
  animation-duration: 1s;
  -webkit-animation: linear infinite alternate;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -webkit-animation-name: run;
  -webkit-animation-duration: 1s;
}

@keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 5px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 5px;
  }
  100% {
    left: 0;
  }
}
/*text link white */
.site-button-link.white {
  color: #e7e7e7;
}

.site-button-link.white:hover,
.site-button-link.white:active,
.site-button-link.white:focus {
  color: #CCC;
}

/* text link black */
.site-button-link.black {
  color: #171717;
}

.site-button-link.black:hover,
.site-button-link.black:active,
.site-button-link.black:focus {
  color: #000;
}

/* text link Gray */
.site-button-link.gray {
  color: #666666;
}

.site-button-link.gray:hover,
.site-button-link.gray:active,
.site-button-link.gray:focus {
  color: #555555;
}

/* text link pink */
.site-button-link.pink {
  color: #e63f75;
}

.site-button-link.pink:hover,
.site-button-link.pink:active,
.site-button-link.pink:focus {
  color: #2ca2be;
}

/* text link Blue */
.site-button-link.blue {
  color: #42B8D4;
}

.site-button-link.blue:hover,
.site-button-link.blue:active,
.site-button-link.blue:focus {
  color: #2ca2be;
}

/* text link Green */
.site-button-link.green {
  color: #35B494;
}

.site-button-link.green:hover,
.site-button-link.green:active,
.site-button-link.green:focus {
  color: #26a585;
}

/*  text link Orange */
.site-button-link.orange {
  color: #E56713;
}

.site-button-link.orange:hover,
.site-button-link.orange:active,
.site-button-link.orange:focus {
  color: #d55703;
}

/* text link Red */
.site-button-link.red {
  color: #D93223;
}

.site-button-link.red:hover,
.site-button-link.red:active,
.site-button-link.red:focus {
  color: #c51e0f;
}

/* text link Brown */
.site-button-link.brown {
  color: #69441F;
}

.site-button-link.brown:hover,
.site-button-link.brown:active,
.site-button-link.brown:focus {
  color: #5f3a15;
}

/* text link Yellow */
.site-button-link.yellow {
  color: #00ABB3;
}

.site-button-link.yellow:hover,
.site-button-link.yellow:active,
.site-button-link.yellow:focus {
  color: #d4af19;
}

/* text link purple */
.site-button-link.purple {
  color: #ae1af7;
}

.site-button-link.purple:hover,
.site-button-link.purple:active,
.site-button-link.purple:focus {
  color: #9804e1;
}

/* app strore */
.site-button.button-app {
  text-align: left;
  padding: 5px 10px;
}

.site-button.button-app i {
  display: inline-block;
  font-size: 45px;
  margin-right: 15px;
}

.site-button.button-app strong {
  display: block;
  font-size: 16px;
}

/* filter buttons css */
.masonry-filter {
  display: table;
  margin-bottom: 0;
}

.masonry-filter > li {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 20px;
  position: relative;
}

.masonry-filter > li a {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.masonry-filter > li.active a,
.masonry-filter > li a:hover,
.masonry-filter > li a:active,
.masonry-filter > li a:focus {
  color: #00ABB3;
}

@media only screen and (max-width: 600px) {
  .masonry-filter > li {
    margin-bottom: 10px;
  }
}
/* Masonry filter alignment [Left-Right-Center] */
.filter-wrap.right > .masonry-filter {
  float: right;
}

@media only screen and (max-width: 991px) {
  .filter-wrap.right > .masonry-filter {
    float: none;
  }
}
.filter-wrap.center > .masonry-filter {
  margin-left: auto;
  margin-right: auto;
}

/* Masonry filter link style */
.masonry-filter.link-style > li a {
  font-size: 14px;
}

.masonry-filter.link-style > li:after {
  content: "|";
  position: relative;
  opacity: 0.3;
}

.masonry-filter.link-style.white > li:after {
  color: #fff;
}

.masonry-filter.link-style > li:last-child:after {
  display: none;
}

.masonry-filter.link-style.white > li a {
  color: #fff;
}

.masonry-filter.link-style.white > li.active a,
.masonry-filter.link-style.white > li a:hover,
.masonry-filter.link-style.white > li a:active,
.masonry-filter.link-style.white > li a:focus {
  color: #f7c20a;
}

.masonry-filter.has-bg > li a {
  color: #fff;
}

.masonry-filter.link-style.has-bg > li.active a,
.masonry-filter.link-style.has-bg > li:hover a,
.masonry-filter.link-style.has-bg > li:active a,
.masonry-filter.link-style.has-bg > li:focus a {
  color: #00ABB3;
}

/* Masonry filter button style */
.masonry-filter.button-style > li {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .masonry-filter.button-style > li {
    margin-bottom: 10px;
  }
}
.masonry-filter.button-style > li a {
  background-color: #666666;
  color: #fff;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}

.masonry-filter.button-style > li.active a,
.masonry-filter.button-style > li a:hover,
.masonry-filter.button-style > li a:active,
.masonry-filter.button-style > li a:focus {
  background-color: #000;
  color: #fff;
}

.masonry-filter.button-style.has-bg > li a {
  background-color: #fff;
  color: #666;
}

.masonry-filter.button-style.has-bg > li.active a,
.masonry-filter.button-style.has-bg > li a:hover,
.masonry-filter.button-style.has-bg > li a:active,
.masonry-filter.button-style.has-bg > li a:focus {
  background-color: #000;
  color: #fff;
}

/* Masonry filter button outline style */
.masonry-filter.outline-style > li {
  margin-right: 10px;
}

.masonry-filter.outline-style > li a {
  border-width: 2px;
  border-style: solid;
  border-color: #666666;
  color: #666666;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}

.masonry-filter.outline-style.rounded-corner > li a {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.masonry-filter.outline-style > li.active a,
.masonry-filter.outline-style > li a:hover,
.masonry-filter.outline-style > li a:active,
.masonry-filter.outline-style > li a:focus {
  border-color: #000;
  color: #000;
}

.masonry-filter.outline-style.has-bg > li a {
  border-color: #fff;
  color: #fff;
}

.masonry-filter.outline-style.has-bg > li.active a,
.masonry-filter.outline-style.has-bg > li a:hover,
.masonry-filter.outline-style.has-bg > li a:active,
.masonry-filter.outline-style.has-bg > li a:focus {
  border-color: #000;
  color: #000;
}

@media only screen and (max-width: 768px) {
  .masonry-item {
    width: 100%;
  }
}
/* half effect button outline style */
btn-half {
  cursor: pointer;
  z-index: 0;
  display: inline-block;
  position: relative;
}

.btn-half span {
  color: #000;
  display: block;
  padding-left: 0%;
  text-transform: uppercase;
  font-weight: 600;
  transform: scaleX(0.9);
  transform-origin: center center;
  transition: color 0.3s ease;
  position: relative;
  z-index: 1;
}

.btn-half.site-button span {
  color: #000;
}

.btn-half.site-button-secondry span {
  color: #fff;
}

.btn-half:before,
.btn-half:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.2);
  height: 50%;
  width: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.btn-half:before {
  top: 0;
  left: 0;
  right: auto;
}

.btn-half:after {
  bottom: 0;
  right: 0;
  left: auto;
}

.btn-half.site-button:hover {
  background-color: #000;
}

.btn-half:hover:before {
  width: 100%;
  right: 0;
  left: auto;
}

.btn-half:hover:after {
  width: 100%;
  left: 0;
  right: auto;
}

.btn-half:hover span {
  color: #fff;
}

/*-------------------------------------
	Title separators 
-------------------------------------*/
.sx-separator-outer {
  overflow: hidden;
}

.sx-separator {
  display: inline-block;
  margin-top: 0px;
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.sep-line-one {
  position: relative;
}

.sep-line-one:after,
.sep-line-one:before {
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.sep-line-one:after,
.sep-line-one:before {
  position: absolute;
  content: "";
  background-color: #000;
  height: 4px;
}

.sep-line-one:after {
  width: 20px;
  right: -40px;
  top: 50%;
  margin-top: -2px;
}

.sep-line-one:before {
  width: 20px;
  left: -40px;
  top: 50%;
  margin-top: -2px;
}

.section-head:hover .sep-line-one:after {
  width: 35px;
}

.section-head:hover .sep-line-one:before {
  width: 35px;
}

/*Separator defualt*/
.sx-separator-outer.separator-left {
  text-align: left;
}

.sx-separator-outer.separator-right {
  text-align: right;
}

.sx-separator-outer.separator-center {
  text-align: center;
}

.sx-separator-outer.separator-left .sep-line-one {
  padding: 20px 60px 20px 20px;
  font-size: 42px;
  font-weight: 800;
}

.sx-separator-outer.separator-left .sep-line-one:after {
  width: 20px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
}

.sx-separator-outer.separator-right .sep-line-one {
  padding: 20px 20px 20px 60px;
  font-size: 42px;
  font-weight: 800;
}

.sx-separator-outer.separator-right .sep-line-one:before {
  width: 20px;
  left: 20px;
  top: 50%;
  margin-top: -2px;
}

.sx-separator-outer.separator-center .sep-line-one {
  padding: 20px 60px 20px 60px;
  font-size: 42px;
  font-weight: 800;
}

.sx-separator-outer.separator-center .sep-line-one:before {
  width: 20px;
  left: 20px;
  top: 50%;
  margin-top: -2px;
}

.sx-separator-outer.separator-center .sep-line-one:after {
  width: 20px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
}

.section-head:hover .sx-separator-outer .sep-line-one:after {
  width: 35px;
}

.section-head:hover .sx-separator-outer .sep-line-one:before {
  width: 35px;
}

@media only screen and (max-width: 768px) {
  .sx-separator h1,
  .sx-separator h2,
  .sx-separator h3 {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .sx-separator {
    margin-bottom: 0px;
  }
  .sx-separator-outer.separator-left .sep-line-one {
    padding: 10px 60px 10px 20px;
    font-size: 26px;
    font-weight: 700;
  }
  .sx-separator-outer.separator-right .sep-line-one {
    padding: 10px 20px 10px 60px;
    font-size: 26px;
    font-weight: 700;
  }
  .sx-separator-outer.separator-center .sep-line-one {
    padding: 10px 60px 10px 60px;
    font-size: 26px;
    font-weight: 700;
  }
}
@media only screen and (max-width: 640px) {
  .sx-separator-outer.separator-left {
    text-align: left;
  }
  .sx-separator-outer.separator-right {
    text-align: left;
  }
  .sx-separator-outer.separator-center {
    text-align: left;
  }
}
@media only screen and (max-width: 540px) {
  .sx-separator-outer.separator-center .sep-line-one {
    padding: 20px 50px 14px 50px;
    letter-spacing: inherit;
  }
}
/*-------------------------------------
	Deviders 
-------------------------------------*/
.sx-divider {
  height: 1px;
  position: relative;
  margin: 30px 0;
}

.sx-divider.divider-2px {
  height: 2px;
}

.sx-divider.divider-3px {
  height: 3px;
}

.sx-divider.divider-4px {
  height: 4px;
}

.sx-divider i {
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sx-divider.icon-left {
  margin-left: 40px;
}

.sx-divider.icon-left i {
  left: -40px;
}

.sx-divider.icon-right {
  margin-right: 40px;
}

.sx-divider.icon-right i {
  left: auto;
  right: -40px;
}

.sx-divider.icon-center i {
  left: 50%;
  margin-left: -5px;
}

/*-------------------------------------
	TABS 
-------------------------------------*/
.sx-tabs:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

@media only screen and (max-width: 767px) {
  .sx-tabs .nav-tabs > li {
    float: none;
  }
}
.sx-tabs p:last-child {
  margin-bottom: 0;
}

/*tabs style 1 [ default ] */
.sx-tabs .tab-pane {
  padding: 20px 0px;
}

.sx-tabs .nav-tabs > li > a {
  color: #000;
  font-size: 12px;
  padding: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 0px;
  border-bottom: 3px solid transparent;
}

.sx-tabs .nav-tabs > li.active > a,
.sx-tabs .nav-tabs > li.active > a:focus,
.sx-tabs .nav-tabs > li.active > a:hover {
  background-color: #fff;
  border-bottom: 3px solid #3C4048;
  color: #000;
}

.sx-tabs .nav > li > a:focus,
.sx-tabs .nav > li > a:hover {
  background-color: transparent;
  border: 0px;
}

.sx-tabs .nav li a:hover {
  border: 1px;
}

.sx-tabs .nav-tabs > li > a i {
  margin-right: 5px;
}

.sx-tabs.tabs-default.has-bg .nav-tabs > li > a {
  color: #fff;
}

.sx-tabs.tabs-default.has-bg .nav-tabs > li.active > a,
.sx-tabs.tabs-default.has-bg .nav-tabs > li.active > a:focus,
.sx-tabs.tabs-default.has-bg .nav-tabs > li.active > a:hover {
  color: #000;
  border-color: #ddd #ddd #fff;
}

.sx-tabs.tabs-default.has-bg .tab-pane {
  color: #fff;
}

/* tabs nav center */
.sx-tabs.nav-center > .nav-tabs {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

/*tabs bg  [ tabs background ] */
.sx-tabs.bg-tabs .nav-tabs > li > a {
  background-color: #f0f0f0;
  border: 0px;
  margin-right: -1px;
  border-bottom: 4px solid transparent;
}

.sx-tabs.bg-tabs .nav-tabs > li.active > a {
  border-bottom: 0px;
  background-color: #00ABB3;
  color: #000;
  border-bottom: 4px solid #00ABB3;
}

.sx-tabs.bg-tabs.has-bg .tab-pane {
  color: #fff;
}

/*tabs bg  [ tabs background ] */
.sx-tabs.vertical.bg-tabs .nav-tabs > li > a {
  border-bottom: 4px solid transparent;
}

.sx-tabs.vertical.bg-tabs .nav-tabs > li.active > a {
  border-bottom: 1px solid transparent;
  background-color: #3C4048;
  color: #fff;
  border-bottom: 4px solid #3C4048;
}

.sx-tabs.vertical.right.bg-tabs .nav-tabs > li > a {
  border: 1px solid #ddd;
}

.sx-tabs.vertical.right.bg-tabs .nav-tabs > li.active > a {
  border-left: 1px solid transparent;
}

/*tabs bg & top border  [ tabs background & top border in active ] */
.sx-tabs.border-top .nav-tabs > li.active > a {
  color: #3C4048;
}

.sx-tabs.border-top .nav-tabs > li.active > a:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
}

.sx-tabs.border-top.vertical .nav-tabs > li.active > a:after {
  top: 0px;
  left: -1px;
  width: 3px;
  height: 100%;
}

/*tabs style 2 [ content with border outer ] */
.sx-tabs.border .tab-pane {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: -1px;
}

/*tabs style 3  [ left-nav ] */
.sx-tabs.vertical .nav-tabs {
  float: left;
  width: 170px;
  border-bottom: none;
  border-right: 0px;
}

@media only screen and (max-width: 720px) {
  .sx-tabs.vertical .nav-tabs {
    width: 100%;
    float: none;
    border-right: 0px;
  }
  .sx-tabs.vertical .tab-content {
    width: 100%;
    margin-left: 0px !important;
  }
}
.sx-tabs.vertical .tab-pane {
  padding: 10px 0 10px 20px;
}

.sx-tabs.vertical .nav-tabs li {
  float: none;
  margin-right: 0px;
}

.sx-tabs.vertical .nav-tabs li a {
  margin-right: 0;
  border-right: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0;
  border-radius: 0px;
  color: #000;
  border-bottom: 3px solid transparent;
}

.sx-tabs.vertical .nav-tabs li.active a {
  border-bottom: 3px solid #3C4048;
  background-color: #fff;
}

.sx-tabs.vertical .tab-content {
  border-left: 0px;
  margin-left: 169px;
}

@media only screen and (max-width: 720px) {
  .sx-tabs.vertical .tab-pane {
    padding: 10px 0 10px 0px;
  }
}
/*tabs style 4  [ left-nav & content with border ] */
.sx-tabs.vertical.border .tab-pane {
  padding: 20px;
  margin-left: -1px;
}

/*tabs style 5  [ right-nav ] */
.sx-tabs.vertical.right .nav-tabs {
  border-left: 1px solid #ddd;
  border-right: none;
  float: right;
}

.sx-tabs.vertical.right .nav-tabs li {
  margin-right: 0;
  margin-left: -1px;
}

.sx-tabs.vertical.right .nav-tabs li a {
  border-right: 1px solid transparent;
  border-left: none;
}

.sx-tabs.vertical.right .nav-tabs li.active a {
  border-right: 1px solid #ddd;
  border-left: none;
}

.sx-tabs.vertical.right .tab-content {
  border-left: none;
  border-right: 1px solid #ddd;
  margin-right: 169px;
  margin-left: 0;
}

.sx-tabs.vertical.right .tab-pane {
  padding: 10px 20px 10px 0;
}

/*tabs style 6  [ right-nav & content with border ]*/
.sx-tabs.vertical.right.border .tab-pane {
  padding: 20px;
  margin-right: -1px;
}

/*-------------------------------------
	Accordians 
-------------------------------------*/
.sx-accordion .sx-panel {
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  border-radius: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.sx-accordion .sx-panel:last-child {
  margin-bottom: 0px;
}

.acod-head {
  position: relative;
}

.acod-title {
  margin-top: 0;
  margin-bottom: 0;
}

.acod-title a {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.acod-head .fa {
  margin-right: 5px;
}

.acod-head a {
  display: block;
  padding: 15px 50px 15px 15px;
  font-size: 16px;
  font-weight: 600;
}

.acod-head a,
.acod-head a:hover,
.acod-head a:active,
.acod-head a:focus {
  color: #000;
}

.acod-body {
  color: #000;
}

/*Accodian open close indicator css*/
.acod-head .indicator {
  padding: 15px;
  color: #000;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.acod-head .indicator .fa {
  position: relative;
}

.acod-content p:last-child {
  margin-bottom: 0px;
}

/*.acod-head .indicator .fa:after,
.acod-head .indicator .fa:before{	position:absolute;	content:"";	right:0px;	top:-4px;	height:1px;	width:13px;	background-color:#000;}*/
.acc-bg-dark .acod-head .indicator .fa:after,
.acc-bg-dark .acod-head .indicator .fa:before {
  color: #000;
}

.acod-head.acc-actives .indicator .fa:before,
.acod-head [aria-expanded=false] .indicator .fa:before {
  content: "\f067";
  font-family: "FontAwesome";
}

.acod-head.acc-actives .indicator .fa:before,
.acod-head [aria-expanded=true] .indicator .fa:before {
  font-family: "FontAwesome";
  content: "\f068";
}

.acod-head .collapsed .indicator .fa:before,
.acod-head .collapsed [aria-expanded=false] .indicator .fa:before {
  content: "\f067";
  font-family: "FontAwesome";
}

.acod-head .indicator .fa:before,
.acod-head [aria-expanded=true] .indicator .fa:before {
  font-family: "FontAwesome";
  content: "\f068";
}

/* Accordion defult */
.acc-default .acod-title a {
  padding: 5px 30px 5px 20px;
  border-bottom: 1px solid #ececec;
  position: relativel;
}

.acc-default .acod-title a i {
  position: absolute;
  left: 0px;
  top: 5px;
}

.acc-default .acod-head .indicator {
  padding: 0;
}

.acc-default.acc-has-bg .acod-head a,
.acc-default.acc-has-bg .acod-head a:hover,
.acc-default.acc-has-bg .acod-head a:active,
.acc-default.acc-has-bg .acod-head a:focus,
.acc-default.acc-has-bg .acod-head .indicator,
.acc-default.acc-has-bg .acod-body {
  color: #fff;
}

/* Accordion outline */
.acc-outline .acod-title a {
  border: 1px solid #ddd;
}

.acc-outline.acc-has-bg .acod-head a,
.acc-outline.acc-has-bg .acod-head a:hover,
.acc-outline.acc-has-bg .acod-head a:active,
.acc-outline.acc-has-bg .acod-head a:focus,
.acc-outline.acc-has-bg .acod-head .indicator,
.acc-outline.acc-has-bg .acod-body {
  color: #fff;
}

/* Accordion bg gray */
.acc-bg-gray a {
  background-color: #F5F5F5;
}

.acc-bg-gray .acod-head .indicator {
  color: #000;
}

.acc-bg-gray.acc-has-bg .acod-body {
  color: #fff;
}

/* Accordion bg primary */
.acc-bg-primary a {
  background-color: #f5be23;
}

.acc-bg-primary .acod-head a,
.acc-bg-primary .acod-head a:hover,
.acc-bg-primary .acod-head a:active,
.acc-bg-primary .acod-head a:focus {
  color: #fff;
}

.acc-bg-primary .acod-head .indicator {
  color: #fff;
}

.acc-bg-primary.acc-has-bg .acod-body {
  color: #fff;
}

/* Accordion bg white */
.acc-bg-white a {
  background-color: #fff;
}

.acc-bg-white .acod-head a,
.acc-bg-white .acod-head a:hover,
.acc-bg-white .acod-head a:active,
.acc-bg-white .acod-head a:focus {
  color: #222;
}

.acc-bg-white .acod-head .indicator {
  color: #222;
}

.acc-bg-white.acc-has-bg .acod-body {
  color: #222;
}

/* Accordion bg dark */
.acc-bg-dark a {
  background-color: #000;
}

.acc-bg-dark .acod-head a,
.acc-bg-dark .acod-head a:hover,
.acc-bg-dark .acod-head a:active,
.acc-bg-dark .acod-head a:focus {
  color: #fff;
}

.acc-bg-dark .acod-head .indicator {
  color: #000;
  background-color: #f8f8f8;
}

.acc-bg-dark .acod-head .fa {
  color: #00ABB3;
}

.acc-bg-dark.acc-has-bg .acod-body {
  color: #fff;
}

/*-------------------------------------
	Carousels
-------------------------------------*/
/* Client carousel 1*/
.ow-client-logo {
  display: table;
  width: 100%;
}

.client-logo {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}

.owl-carousel .ow-client-logo img {
  max-height: 100%;
  width: auto;
  display: inline-block;
}

.client-logo-media img {
  max-width: 100% !important;
  margin: 0px auto !important;
  width: 50% !important;
}

/*-------------------------------------
	7. Testimonials 
-------------------------------------*/
.testimonial-pic {
  background: #FFF;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
}

.quote-left,
.quote-right {
  position: relative;
}

.quote-left:before,
.quote-right:after {
  font-family: "FontAwesome";
  position: absolute;
  z-index: 10;
  top: 15px;
}

.quote-left:before {
  content: "\f10d";
  left: 0;
}

.quote-right:after {
  content: "\f10e";
  left: auto;
  right: 0;
}

.testimonial-text {
  padding: 15px;
  position: relative;
}

.testimonial-text p:last-child {
  margin: 0;
}

.testimonial-detail {
  padding: 5px;
}

.testimonial-name,
.testimonial-position {
  display: block;
}

.testimonial-text p {
  margin: 0;
}

/*testimonial 1*/
.testimonial-1 {
  position: relative;
  text-align: center;
  padding: 30px 30px 30px 30px;
}

.testimonial-1 .fa-quote-right {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 32px;
  color: #00ABB3;
}

.testimonial-1 .testimonial-detail {
  padding: 0px;
  padding-bottom: 0px;
}

.testimonial-1 .testimonial-text {
  padding: 0px;
}

.testimonial-1 .testimonial-pic {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.testimonial-1 .testimonial-name {
  color: #3C4048;
}

.testimonial-1 .testimonial-position {
  color: #3C4048;
  font-size: 16px;
  margin-bottom: 10px;
}

.testimonial-1 .quote-left:before {
  top: 50px;
}

/*testimonial 2 with background image*/
.testimonial-1.testimonial-bg .testimonial-name,
.testimonial-1.testimonial-bg .testimonial-position {
  color: #00ABB3;
}

@media only screen and (max-width: 768px) {
  .testimonial-1.no-bg-testimonial {
    margin: 0px;
    border: 1px solid #f0f0f0;
  }
}
/*testimonial 2*/
.testimonial-2 {
  position: relative;
}

.testimonial-2 .testimonial-text {
  background-color: #fff;
  padding: 30px 30px 70px 30px;
  border: 1px solid #eef1f2;
}

.testimonial-2 .fa-quote-right {
  position: absolute;
  right: 15px;
  top: 0px;
  font-size: 70px;
  color: #00ABB3;
}

.testimonial-2 .testimonial-detail {
  padding: 0px;
  margin-bottom: 30px;
  margin-right: 90px;
}

.testimonial-2 .testimonial-pic {
  float: left;
  margin-right: 15px;
  width: 100px;
  height: 100px;
}

.testimonial-2 .testimonial-name {
  color: #3C4048;
}

.testimonial-2 .testimonial-position {
  color: #3C4048;
  font-size: 14px;
}

.testimonial-2 .quote-left:before {
  top: 50px;
}

/*testimonial 2 with background image*/
.testimonial-2.testimonial-bg .testimonial-name,
.testimonial-2.testimonial-bg .testimonial-position {
  color: #00ABB3;
}

@media only screen and (max-width: 768px) {
  .testimonial-2.no-bg-testimonial {
    margin: 0px;
    border: 1px solid #f0f0f0;
  }
  .testimonial-2 {
    padding: 0px;
    margin: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .testimonial-2 .fa-quote-right {
    position: inherit;
    right: inherit;
    top: inherit;
    font-size: 22px;
    padding-top: 12px;
  }
}
@media only screen and (max-width: 480px) {
  .testimonial-2 .testimonial-text {
    padding: 20px 20px 40px 20px;
  }
  .testimonial-2 .testimonial-pic {
    float: none;
    clear: both;
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .testimonial-2 .testimonial-detail {
    margin-bottom: 20px;
    margin-right: 0px;
    text-align: center;
  }
}
.testimonial-home .owl-prev,
.testimonial-home .owl-next {
  background-color: transparent;
  color: #ffffff;
  padding: 8px 10px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
}

.testimonial-home .owl-prev i,
.testimonial-home .owl-next i {
  font-size: 24px;
  font-weight: 800;
}

.testimonial-home.number-slider .owl-dots {
  width: 60px;
  height: 60px;
  background-color: #00ABB3;
  line-height: 60px;
  font-size: 24px;
  top: auto;
  bottom: 0px;
  right: 0px;
  left: auto;
}

.testimonial-home.number-slider .owl-dot.active:before {
  content: counter(slides-num);
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  left: 10px;
  top: 2px;
  color: #000;
}

.testimonial-home.number-slider .owl-dot.active:after {
  content: "/";
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  font-weight: 300;
  position: absolute;
  left: 56%;
  top: 3px;
  margin-left: -10px;
  color: #000;
}

.testimonial-home.number-slider .owl-dots:after {
  content: counter(slides-num);
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  vertical-align: middle;
  padding-left: 30px;
  color: #000;
}

@media only screen and (max-width: 480px) {
  .testimonial-home.number-slider .owl-dots {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    bottom: -25px;
    margin-top: -25px;
    right: 30px;
    left: auto;
  }
  .testimonial-home.number-slider .owl-dot {
    margin-right: 4px;
  }
  .testimonial-home.number-slider .owl-dot.active:before {
    font-size: 16px;
    left: 10px;
  }
  .testimonial-home.number-slider .owl-dot.active:after {
    font-size: 16px;
    margin-left: -5px;
  }
  .testimonial-home.number-slider .owl-dots:after {
    font-size: 16px;
    padding-left: 25px;
  }
}
.block-shadow {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

/*-------------------------------------
	Pricing table
 -------------------------------------*/
.pricingtable-inner {
  text-align: center;
}

.pricingtable-price {
  padding: 10px;
  background-color: #00ABB3;
}

.pricingtable-bx {
  font-size: 70px;
  font-family: Arial;
  color: #666666;
  color: #3C4048;
}

.pricingtable-type {
  font-size: 20px;
  text-transform: uppercase;
  font-size: 24px;
  color: #fff;
}

.pricingtable-type:before {
  content: "/";
  margin-right: 3px;
}

.pricingtable-title {
  background-color: #3C4048;
  padding: 20px;
  text-transform: uppercase;
}

.pricingtable-title * {
  margin: 0;
  color: #fff;
}

.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #E9E9E9;
}

.pricingtable-features li {
  padding: 12px;
  border-bottom: 1px solid #E9E9E9;
}

.pricingtable-features li i {
  margin: 0 3px;
}

.pricingtable-features li:nth-child(even) {
  background-color: #F4F7F8;
}

.pricingtable-features li:last-child {
  border-bottom: none;
}

.pricingtable-footer {
  margin-top: 15px;
  padding: 20px;
  background-color: #3C4048;
}

.pricingtable-highlight {
  margin: -20px 0;
  position: relative;
  z-index: 99;
}

.pricingtable-highlight .pricingtable-price {
  padding: 20px 10px;
}

.pricingtable-highlight .pricingtable-footer {
  padding: 30px 20px;
}

.no-col-gap .pricingtable-wrapper {
  margin-left: -1px;
}

@media only screen and (max-width: 767px) {
  .pricingtable-highlight .pricingtable-price {
    padding: 10px 10px;
  }
  .pricingtable-highlight .pricingtable-footer {
    padding: 20px 20px;
  }
  .pricingtable-highlight {
    margin: 0px;
  }
  .pricingtable-inner.pricingtable-highlight.shadow-lg {
    box-shadow: none !important;
  }
  .pricingtable-row .no-gutters > .col {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 420px) {
  .pricingtable-bx {
    font-size: 40px;
  }
  .pricingtable-type {
    font-size: 18px;
  }
}
/* pricing table style 4*/
/*8.2 pricing table style 5*/
.pricing-table-style-5 .pricingtable-inner {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.pricing-table-style-3 .pricingtable-inner.radius {
  -webkit-border-radius: 40px 40px 40px 40px;
  border-radius: 40px 40px 40px 40px;
}

.pricing-table-style-5 .pricingtable-inner.pricingtable-highlight {
  z-index: 2;
}

.pricing-table-style-5 .pricingtable-type {
  color: #3C4048;
  display: block;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.pricing-table-style-5 .pricingtable-type::before {
  display: none;
}

.pricing-table-style-5 .pricingtable-title {
  background-color: inherit;
  padding: 40px 20px 0px 20px;
}

.pricing-table-style-5 .pricingtable-title * {
  color: #222;
  font-weight: 700;
}

.pricing-table-style-5 .pricingtable-price {
  background-color: #fff;
}

.pricing-table-style-5 .pricingtable-features li:nth-child(2n) {
  background-color: inherit;
}

.pricing-table-style-5 .pricingtable-features {
  border: none;
}

.pricing-table-style-5 .pricingtable-features li {
  border-bottom: 0px;
}

.pricing-table-style-5 .overlay-main {
  z-index: -1;
}

.pricing-table-style-5 .pricingtable-bx {
  font-size: 60px;
  display: block;
  font-weight: 900;
}

@media only screen and (max-width: 767px) {
  .pricingtable-row .no-gutters > .col,
  .pricingtable-row .no-gutters > [class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .pricingtable-row .no-gutters {
    margin-right: -15px;
    margin-left: -15px;
  }
}
/*-------------------------------------
	Alert box 
-------------------------------------*/
.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px;
}

.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px;
}

.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px;
}

.alert[class*=alert-] i {
  margin-right: 8px;
}

.alert.no-radius {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.alert.no-bg {
  background-color: transparent;
  border-width: 2px;
}

.alert[class*=alert-] ul {
  padding-left: 25px;
  margin-top: 10px;
}

.alert[class*=alert-] ul li:before {
  color: #a94442;
}

/*-------------------------------------
	Image effects 
-------------------------------------*/
.sx-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
}

.sx-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
}

/* 10.1 image-opacity */
.sx-img-effect.opacity img:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  -webkit-opacity: 0.8;
}

/*image-zoom */
.sx-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}

.sx-img-effect.zoom-slow:hover img {
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
}

/*image-zoom-slow */
.sx-img-effect.zoom:hover img {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

/*image-shrink */
.sx-img-effect.shrink:hover img {
  transform: scale(0.8);
  -ms-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -o-transform: scale(0.8);
  -moz-transform: scale(0.8);
}

/*image-side-pan */
.sx-img-effect.side-pan:hover img {
  margin-left: -9%;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
}

/*image-vertical-pan */
.sx-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
}

/*image-fade-in */
.sx-img-effect.fade-in {
  background: #000;
}

.sx-img-effect.fade-in img {
  opacity: 0.65;
  -moz-opacity: 0.65;
  -webkit-opacity: 0.65;
}

.sx-img-effect.fade-in:hover img {
  opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
}

/*image-fade-out */
.sx-img-effect.fade-out {
  background: #000;
}

.sx-img-effect.fade-out:hover img {
  opacity: 0.7;
  -moz-opacity: 0.7;
  -webkit-opacity: 0.7;
}

/*image-rotate */
.sx-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

/*image-sepia */
.sx-img-effect.sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}

.algo-image-hover.sepia:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}

/*image-blurr */
.sx-img-effect.blurr img {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.sx-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

/*image-blurr-invert */
.sx-img-effect.blurr-invert img {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.sx-img-effect.blurr-invert img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

.sx-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}

/*image off color */
.sx-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.sx-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

/*image on color */
.sx-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

.sx-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

/*Glass reflection */
.img-reflection {
  background-position: left top;
  position: relative;
  overflow: hidden;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.img-reflection:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  width: 35%;
  height: 200%;
  left: -100%;
  top: -50%;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -moz-transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

.img-reflection:hover:after {
  left: 150%;
}

/*-------------------------------------
	Modal pop 
-------------------------------------*/
.modal-xlg {
  width: 1000px;
}

@media only screen and (max-width: 1024px) {
  .modal-xlg {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
}
/*-------------------------------------
	Social icons 
-------------------------------------*/
.social-icons {
  list-style: none;
  margin-left: -3px;
  margin-right: -3px;
  font-size: 12px;
  margin: 0px;
}

.social-icons li {
  display: inline-block;
  text-align: center;
  margin-right: 3px;
}

.social-icons li:last-child {
  margin-right: 0px;
}

.social-icons li a {
  display: block;
  padding: 3px;
}

.social-icons.social-md {
  font-size: 18px;
}

.social-icons.social-lg li a {
  font-size: 24px;
}

.social-icons.has-bg li a:hover {
  color: #fff;
}

.social-icons.social-light a {
  color: #fff;
}

.social-icons.social-light.has-bg li a:hover {
  color: #777;
}

.social-icons.social-dark a {
  color: #000000;
}

.social-icons.social-dark a:hover {
  color: #fff;
}

.social-icons.social-dark.has-bg li a:hover {
  color: #777;
}

.social-icons.social-square a,
.social-icons.social-radius a {
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;
}

.social-icons.social-square.social-md a,
.social-icons.social-radius.social-md a {
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 0;
}

.social-icons.social-square.social-lg a,
.social-icons.social-radius.social-lg a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
}

.social-icons.social-square.social-primary a,
.social-icons.social-radius.social-primary a {
  background-color: #000;
  color: #FFF;
}

.social-icons.social-square.social-primary a:hover,
.social-icons.social-radius.social-primary a:hover {
  background-color: #3C4048;
}

.social-icons.social-square.social-dark a,
.social-icons.social-radius.social-dark a {
  background-color: #fff;
}

.social-icons.social-square.social-dark a:hover,
.social-icons.social-radius.social-dark a:hover {
  background-color: #3C4048;
  color: #fff;
}

.social-icons.social-square.social-dark.has-bg a:hover,
.social-icons.social-radius.social-dark.has-bg a:hover {
  border: 1px solid #777;
}

.social-icons.social-darkest a {
  color: #3C4048;
}

.social-icons.social-square.social-darkest a:hover,
.social-icons.social-radius.social-darkest a:hover {
  background-color: #000;
  color: #fff;
}

.social-icons.social-radius a {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.social-icons.social-square.social-dark.white-border a,
.social-icons.social-radius.social-dark.white-border a {
  background: none;
  color: #fff;
  border: 1px solid rgb(255, 255, 255);
}

/*social icon default */
.sx-social-icon {
  display: inline-block;
  margin: 0 -3px;
  padding: 0;
}

.sx-social-icon li {
  display: inline-block;
  padding: 0;
  font-size: 12px;
}

.sx-social-icon li a {
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 4px;
}

.sx-social-icon li .fa {
  vertical-align: middle;
}

/*social icon with border */
.sx-social-icon.border li {
  padding: 0 3px;
}

.sx-social-icon.border li a {
  border: 1px solid #efeded;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

/*social icon dark */
.sx-social-links.dark li {
  padding: 0 3px;
}

.sx-social-icon.dark li a {
  border: 1px solid #777777;
  color: #777777;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

/*social share icon style 1 */
.sx-share-icon li {
  border: none;
  width: 36px;
  display: block;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0;
}

.sx-share-icon li a {
  color: #FFF;
}

.sx-share-icon li i {
  border-right: none;
  font-size: 14px;
  color: #FFF;
  width: 36px;
  height: 34px;
  line-height: 34px;
  padding: 0;
  text-align: center;
}

.sx-share-icon li.fb {
  background: #354d89;
}

.sx-share-icon li.fb i {
  background: #3a5799;
}

.sx-share-icon li.gp {
  background: #d34b2b;
}

.sx-share-icon li.gp i {
  background: #e35736;
}

.sx-share-icon li.tw {
  background: #029fdf;
}

.sx-share-icon li.tw i {
  background: #00abf0;
}

.sx-share-icon li.dig {
  background: #1d61aa;
}

.sx-share-icon li.dig i {
  background: #2B6FB8;
}

.sx-share-icon li.lin {
  background: #0176be;
}

.sx-share-icon li.lin i {
  background: #0082CA;
}

.sx-share-icon li.pin {
  background: #ac0104;
}

.sx-share-icon li.pin i {
  background: #BD0E15;
}

.sx-share-icon li:hover {
  width: 90px;
}

/*-------------------------------------
	Breadcrumb 
-------------------------------------*/
/*breadcrumb-row-1*/
.sx-breadcrumb {
  margin: 0;
  list-style: none;
}

.sx-breadcrumb li {
  padding: 0;
  margin-right: 6px;
  color: #fff;
  position: relative;
  display: inline-block;
  font-size: 12px;
}

.sx-breadcrumb li a {
  color: #00ABB3;
}

.sx-breadcrumb li a:hover {
  color: #00ABB3;
}

.sx-breadcrumb li:last-child {
  color: #fff;
}

.sx-breadcrumb li:first-child a i {
  font-size: 18px;
  vertical-align: text-top;
}

.sx-breadcrumb li:last-child:after {
  display: none;
}

/*Breadcrumb-1*/
.breadcrumb-style-2 li:after {
  content: "\f101";
  margin-left: 7px;
  font-family: "FontAwesome";
  color: #fff;
  font-size: 16px;
  font-weight: normal;
}

/*-------------------------------------
	Google map 
-------------------------------------*/
.google-map {
  width: 100%;
  height: 460px;
}

/*-------------------------------------
	Form elements 
-------------------------------------*/
label {
  font-weight: 600;
  margin-bottom: 10px;
}

.panel-default,
.panel-default > .panel-heading {
  border-color: #e7ecf1;
  color: inherit;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e7ecf1;
}

.form-group {
  margin-bottom: 25px;
}

.form-control {
  border-color: #e1e6eb;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  height: 44px;
  font-size: 13px;
  line-height: 20px;
  padding: 9px 12px 9px 12px;
}

.form-control:focus {
  border-color: #e1e1e1;
  outline: 0;
}

.form-control:focus,
.has-error .form-control:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control.kv-fileinput-caption {
  height: 39px;
}

.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
  margin-right: 15px;
}

/* input group */
.input-group {
  width: 100%;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell;
}

.input-group-addon {
  background: #fff;
  border-color: #e1e1e1;
  padding: 6px 14px;
  font-size: 16px;
}

.input-group-addon.font-size-20 {
  font-size: 20px;
}

.input-group-addon.fixed-w {
  text-align: center;
  padding: 6px 0;
  width: 40px;
}

.input-group-addon.v-align-t {
  line-height: 30px;
}

/* radio & checkbox */
input[type=checkbox],
input[type=radio] {
  opacity: 0;
  margin-left: 1px !important;
}

input[type=checkbox] + label,
input[type=radio] + label {
  display: block;
  padding-left: 20px !important;
  position: relative;
}

input[type=checkbox] + label:hover:before,
input[type=radio] + label:hover:before {
  border-color: #3396d1;
}

input[type=checkbox] + label:before,
input[type=radio] + label:before {
  background-color: #fff;
  border: 2px solid #b6b7b8;
  border-radius: 0px;
  content: "";
  display: inline-block;
  height: 16px;
  line-height: 1;
  margin-left: -20px;
  position: absolute;
  top: 2px;
  transition: all linear 0.1s;
  width: 16px;
}

input[type=checkbox] + label:after {
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 0px;
  -webkit-font-smoothing: antialiased;
  left: 6px;
  position: absolute;
  top: 7px;
  transition: all linear 0.1s;
}

input[type=checkbox]:checked + label:before {
  border-width: 7px;
  border-color: #3396d1;
}

input[type=checkbox]:checked + label:after {
  font-size: 10px;
  left: 2px;
  top: 2px;
}

input[type=radio] + label:before {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  content: "";
}

input[type=radio]:checked + label:before {
  border-color: #3396d1;
  border-width: 5px;
}

.form-inline .checkbox input[type=checkbox],
.form-inline .radio input[type=radio] {
  position: absolute;
  left: 0;
  bottom: 0;
}

/*Browse Button css */
.btn-file {
  overflow: hidden;
  position: relative;
}

.btn-file input[type=file] {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  cursor: inherit;
  display: block;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

/*validation symbol */
.has-feedback label ~ .form-control-feedback {
  top: 31px;
}

/*error for select box */
.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:hover,
.has-error .bootstrap-select .dropdown-toggle:focus {
  border-color: #a94442 !important;
}

/*succes for select box */
.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:hover,
.has-success .bootstrap-select .dropdown-toggle:focus {
  border-color: #3c763d !important;
}

/*textarea */
/* resize-vertical*/
.resize-vertical {
  resize: vertical;
}

/*resize-horizontal*/
.resize-horizontal {
  resize: horizontal;
}

/*resize-none*/
.resize-none {
  resize: none;
}

/*-------------------------------------
	 Loading 
-------------------------------------*/
/*loading - 1*/
.loading-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: hidden;
}

.loading-box {
  width: 100%;
  height: 100%;
  background-color: #3C4048;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 9999;
}

.loading-pic {
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 99999;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*loading - 2*/
.loading-cover,
.loading-srh-bar,
.loading-map {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.loading-cover .fa-spinner,
.loading-srh-bar .fa-spinner,
.loading-map .fa-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px;
  font-size: 25px;
  color: #333;
}

/*-------------------------------------
	Widgets 
-------------------------------------*/
.widget {
  margin-bottom: 40px;
}

.widget-title {
  margin: 0 0 25px;
}

.widget-title {
  margin-bottom: 20px;
}

.recent-posts-entry ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

/* widget listing*/
.widget_services ul {
  list-style: none;
  margin-bottom: 0;
}

.widget_services ul li {
  margin-bottom: 13px;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
  padding: 15px 10px 15px 15px;
  margin-bottom: 0;
  line-height: 20px;
}

.widget_services ul li:last-child {
  border-bottom: 0px;
}

.widget_services ul li {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.widget_services ul li a {
  color: #000;
  display: block;
}

.widget_services ul li a span {
  text-align: right;
  display: block;
  float: right;
}

.widget_services ul li a:hover {
  color: #00ABB3;
}

.widget_services li {
  border-bottom: none;
  line-height: 28px;
}

.widget_services li:before {
  top: 0;
  left: 1px;
}

.widget_services.inline-links li {
  display: block;
  border-bottom: none;
}

/* widget listing*/
.site-footer .widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  border: 0px;
}

.site-footer .widget_services ul li {
  margin-bottom: 13px;
  border-bottom: 1px dashed #666;
  position: relative;
  padding: 13px 10px 12px 0px;
  margin-bottom: 0;
  line-height: 20px;
  font-size: 14px;
  background: none;
}

.site-footer .widget_services ul li:first-child {
  padding-top: 0px;
}

.site-footer .widget_services ul li:last-child {
  border-bottom: 0px;
}

.site-footer .widget_services ul li:hover {
  border-left: 0px solid #00ABB3;
  box-shadow: none;
}

.site-footer .widget_services ul li a {
  color: #000;
  font-size: 14px;
}

.site-footer .widget_services ul li a:hover {
  color: #00ABB3;
}

.site-footer .widget_services li {
  border-bottom: none;
  line-height: 28px;
}

.site-footer .widget_services li:before {
  top: 0;
  left: 1px;
}

.widget_services.inline-links li {
  display: block;
  border-bottom: none;
}

/*widget search custom*/
.search-bx .site-button {
  border-left-color: #FFF;
  padding: 10px 15px;
  color: #000;
  background-color: #fff;
  border: none;
}

.search-bx .input-group .form-control {
  border: none;
}

/*widget search wp-default*/
.widget_search .screen-reader-text {
  display: block;
}

.searchform {
  position: relative;
}

.searchform input[type=text] {
  width: 100%;
  height: 40px;
  padding: 10px 90px 10px 15px;
  border: 1px solid #CCCCCC;
}

.searchform input[type=submit] {
  height: 40px;
  padding: 10px 15px;
  background-color: #00ABB3;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ffffff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.searchform input[type=submit]:hover,
.searchform input[type=submit]:focus,
.searchform input[type=submit]:active {
  background-color: #6ab33e;
  color: #ffffff !important;
  border-bottom-color: #5a9e2f;
}

/*widget recent-posts*/
.recent-posts-entry .post-date,
.tweets-feed-entry .tweet-date {
  color: #3396d1;
  font-style: normal;
}

.widget .post-title {
  font-size: 14px;
  margin-top: 0px;
  line-height: normal;
}

.recent-posts-entry .widget-post {
  margin-bottom: 0px;
  padding: 15px 0px;
  border-bottom: 1px solid #eee;
}

.recent-posts-entry .widget-post:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.recent-posts-entry .widget-post-bx:last-child {
  border-bottom: none;
}

.recent-posts-entry .widget-post:last-child {
  margin-bottom: 0px;
}

.recent-posts-entry .widget-post:first-child {
  padding-top: 0px;
}

.recent-posts-entry .widget-post .sx-post-meta {
  margin-bottom: 0px;
}

.recent-posts-entry .sx-post-media {
  float: left;
  width: 90px;
}

.recent-posts-entry .sx-post-info {
  background: transparent;
  padding: 0;
  margin-left: 105px;
  border: none;
}

.recent-posts-entry .post-meta span {
  margin-right: 10px;
}

.post-controls .prev-post a,
.post-controls .next-post a {
  border: 1px solid #ddd;
  padding: 10px 15px;
  display: block;
}

/*widget tags-list*/
.widget_tag_cloud .tagcloud {
  padding-bottom: 5px;
}

.widget_tag_cloud a {
  padding: 8px 14px;
  font-size: 12px;
  display: inline-block;
  margin: 0px 3px 5px;
  font-weight: 600;
  color: #000;
  position: relative;
  border: 1px solid #e3e3e3;
  background-color: #fff;
}

.widget_tag_cloud a:hover {
  color: #00ABB3;
}

.widget_tag_cloud a:hover:after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  width: 10px;
  right: -5px;
}

/* widget gallery */
.widget_gallery ul {
  margin-bottom: 0px;
}

.widget_gallery li {
  display: block;
  width: 50%;
  float: left;
  background-color: #000;
}

.widget_gallery li a {
  position: relative;
}

.widget_gallery li:hover img {
  margin-bottom: 0;
  opacity: 0.3;
}

.widget_gallery li:hover a:after {
  opacity: 1;
}

.widget_gallery a {
  display: block;
}

@media only screen and (max-width: 991px) {
  .widget_gallery li {
    width: 25%;
  }
}
@media only screen and (max-width: 360px) {
  .widget_gallery li {
    width: 50%;
  }
}
.widget_newsletter-2 .newsletter-bx {
  text-align: left;
}

.widget_newsletter-2 .newsletter-bx .form-control {
  height: 50px;
  background-color: #f6f7f8;
  border: 0px;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon {
  background-color: #00ABB3;
  display: block;
  width: 100px;
  height: 100px;
  margin: 0px auto 15px;
  text-align: center;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon i {
  font-size: 32px;
  color: #fff;
  line-height: 100px;
}

/*widget client slider */
.widget-client {
  border: 1px solid #f6f7f8;
}

/*-------widget service-------*/
.widget_address li {
  margin-bottom: 20px;
}

.widget_address_outer .social-icons li {
  padding-right: 10px;
}

/*-------widget newsletter-------*/
.widget_newsletter .newsletter-bx .form-control {
  background-color: #000;
  border: 0px;
  color: #fff;
}

.footer-dark .social-icons {
  margin-top: 50px;
}

.footer-dark .social-icons li a {
  color: #3C4048;
  font-size: 14px;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 28px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid transparent;
}

.footer-dark .social-icons li a:hover {
  color: #000;
  background-color: #00ABB3;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-dark .widget_newsletter .newsletter-bx {
  text-align: left;
}

.footer-dark .widget_newsletter .newsletter-bx input {
  padding: 10px;
}

.footer-dark .widget_newsletter .newsletter-bx button {
  background-color: #fff;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  height: 50px;
  padding: 15px;
  font-size: 14px;
}

.call-to-action-wrap {
  position: relative;
}

.call-to-action-right {
  padding-top: 5px;
}

@media only screen and (max-width: 767px) {
  .call-to-action-left {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .call-to-action-wrap {
    position: inherit;
    margin: 0px;
    padding: 0px;
    transform: none;
    left: inherit;
    top: inherit;
  }
}
.footer-light .social-icons {
  margin-top: 50px;
}

.footer-light .social-icons li a {
  color: #000;
  font-size: 14px;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 28px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-light .social-icons li a:hover {
  color: #000;
  background-color: #00ABB3;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-light .widget_newsletter .newsletter-bx {
  text-align: left;
}

.footer-light .widget_newsletter .newsletter-bx input {
  padding: 10px;
  height: 50px;
}

.footer-light .widget_newsletter .newsletter-bx button {
  background-color: #fff;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  height: 50px;
  padding: 15px;
  font-size: 14px;
}

.footer-light .footer-top p,
.footer-light .footer-top strong,
.footer-light .footer-top b,
.footer-light .footer-top,
.footer-light .footer-top .p-month,
.footer-light .footer-top .p-year {
  color: #000;
}

/*-------------------------------------
	Table 
-------------------------------------*/
/* Full-responsive */
.sx-responsive-table td,
.sx-responsive-table th {
  padding: 8px !important;
}

@media only screen and (max-width: 800px) {
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: none;
  }
  #no-more-tables tr {
    border: 1px solid #ccc;
  }
  #no-more-tables td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left;
  }
  #no-more-tables td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  #no-more-tables td:before {
    content: attr(data-title);
  }
}
/*-------------------------------------
	Video 
-------------------------------------*/
.yt-thum-box img {
  margin: -9.5% 0%;
}

/*======================================
1WP DEFAULT  
======================================*/
.alignnone {
  margin: 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 15px;
}

.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}

.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}

a img.alignnone {
  margin: 5px 0 25px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}

a img.aligncenter {
  display: block;
  margin: 5px auto 15px;
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}

.wp-caption img[class*=wp-image-] {
  display: block;
  margin: 0;
}

.wp-caption.alignnone {
  margin: 5px 0 25px 0;
}

.wp-caption.alignleft {
  margin: 5px 25px 25px 0;
}

.wp-caption.alignright {
  margin: 5px 0 25px 25px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  color: #9d9d9d;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  padding: 10px 0;
  text-align: left;
}

blockquote.author-quote {
  padding: 30px 30px 30px 100px;
  color: #000;
  margin: 30px 0;
  position: relative;
  clear: both;
}

blockquote.author-quote p {
  font-size: 24px;
  line-height: 32px;
}

blockquote.author-quote strong {
  font-size: 18px;
  display: block;
}

blockquote.author-quote span {
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 0px;
}

blockquote.author-quote {
  position: relative;
}

blockquote.author-quote .fa-quote-right {
  font-size: 50px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: #3C4048;
  padding-left: 20px;
  opacity: 0.2;
}

blockquote.author-quote .fa-quote-left {
  font-size: 50px;
  position: absolute;
  top: 30px;
  left: 30px;
  color: #3C4048;
  padding-left: 0;
  opacity: 0.2;
}

@media only screen and (max-width: 640px) {
  blockquote.author-quote {
    padding: 30px;
  }
  blockquote h4 {
    text-indent: 50px;
  }
  blockquote.author-quote .fa-quote-left {
    font-size: 24px;
    position: absolute;
    top: 30px;
    left: -16px;
    color: #3C4048;
    padding-left: 0;
    opacity: 0.2;
  }
}
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

/*____BLOG  
======================================*/
.blog-post {
  position: relative;
  margin-bottom: 30px;
}

.sx-post-meta {
  margin-bottom: 10px;
}

.sx-post-meta ul {
  margin: 0px;
  list-style: none;
}

.sx-post-meta li {
  padding: 0;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: #3C4048;
}

.sx-post-meta i {
  margin: 0 5px;
}

.sx-post-meta li:after {
  content: "-";
  display: inline-block;
  font-weight: normal;
  margin-left: 5px;
  opacity: 0.5;
}

.sx-post-meta li:last-child:after {
  display: none;
}

.sx-post-text {
  margin-bottom: 20px;
}

.sx-post-text p a {
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
}

.sx-post-text p:last-child {
  margin: 0;
}

.sx-post-readmore {
  margin-bottom: 0px;
}

@media only screen and (max-width: 420px) {
  .masonry-item .sx-post-info {
    padding-bottom: 0px;
  }
  .masonry-item .sx-post-title h2.post-title {
    line-height: 14px;
  }
  .masonry-item .sx-post-title h2.post-title a {
    font-size: 14px;
    line-height: none;
  }
  .news-grid .sx-post-text {
    display: none;
  }
}
/*-------------------------------------
	side bar
-------------------------------------*/
.side-bar .widget {
  margin-bottom: 30px;
}

.side-bar .widget:last-child {
  margin-bottom: 0px;
}

.widget .widget-post img {
  width: 100%;
}

/*-------------------------------------
	comment list
-------------------------------------*/
.comments-area {
  padding: 0;
}

.comments-area .comments-title {
  text-transform: uppercase;
  font-size: 20px;
}

ol.comment-list {
  list-style: none;
}

ol.comment-list li.comment {
  position: relative;
  padding: 0;
}

ol.comment-list li.comment .comment-body {
  position: relative;
  padding: 20px 30px 20px 120px;
  margin-left: 0px;
  color: #000;
  position: relative;
  background-color: #eef1f2;
  margin-bottom: 10px;
}

ol.comment-list li.comment .comment-author {
  display: block;
  margin-bottom: 10px;
}

ol.comment-list li.comment .comment-author .avatar {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

ol.comment-list li.comment .comment-author .fn {
  display: inline-block;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
}

ol.comment-list li.comment .comment-author .says {
  display: none;
  color: #999999;
  font-weight: 600;
}

ol.comment-list li.comment .comment-meta {
  margin-bottom: 5px;
  font-size: 12px;
}

ol.comment-list li.comment .comment-meta a {
  color: #050505;
  display: inline-block;
}

ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
  font-family: "FontAwesome";
  font-size: 16px;
  vertical-align: top;
}

ol.comment-list li.comment p {
  margin: 0 0 15px;
}

ol.comment-list li.comment .reply {
  text-align: right;
}

ol.comment-list li.comment .reply a {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
}

ol.comment-list li .children {
  list-style: none;
  margin-left: 80px;
}

ol.comment-list li .children li {
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .comments-outer {
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .comments-area .comments-title {
    margin-bottom: -10px;
  }
}
@media only screen and (max-width: 500px) {
  ol.comment-list {
    padding: 15px;
  }
  ol.comment-list li.comment .comment-body {
    padding: 20px 30px 20px 20px;
    text-align: center;
  }
  ol.comment-list li.comment .comment-author .avatar {
    left: -15px;
    top: 12px;
    width: 60px;
    height: 60px;
    position: inherit;
    display: block;
    margin: 0px auto;
  }
  ol.comment-list li .children {
    list-style: none;
    margin-left: 40px;
  }
  ol.comment-list li.comment .reply {
    text-align: center;
  }
}
@media only screen and (max-width: 380px) {
  ol.comment-list {
    padding: 0px;
  }
  ol.comment-list li.comment .comment-author .avatar {
    position: inherit;
    left: inherit;
    right: inherit;
    margin: 0px auto 20px;
    width: 50%;
    height: 20%;
    padding: 2%;
  }
  ol.comment-list li.comment {
    position: relative;
    padding: 0;
    margin-bottom: 10px;
  }
}
/*-------------------------------------
	comment form
-------------------------------------*/
.comment-respond .comment-reply-title {
  font-size: 20px;
}

.comments-area .comment-form .comment-notes {
  display: none;
}

.comments-area .comment-form p {
  width: 33.333%;
  float: left;
  padding: 0 15px;
  margin-bottom: 30px;
  position: relative;
  font-weight: 600;
}

.comments-area .comment-form p.form-allowed-tags {
  width: 100%;
}

ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0 15px !important;
}

.comments-area .comment-form p label {
  display: none;
  line-height: 18px;
  margin-bottom: 10px;
}

.comments-area .comment-form p input[type=text],
.comments-area .comment-form p textarea {
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  border: none;
  background-color: #eef1f2;
}

.comments-area .comment-form p.comment-form-comment {
  width: 100%;
  display: block;
  clear: both;
}

.comments-area .comment-form p textarea {
  height: 120px;
  resize: none;
}

.comments-area .comment-form p.form-submit {
  clear: both;
  float: none;
  width: 100%;
  margin: 0;
}

.comments-area .comment-form p input[type=submit] {
  background-color: #77c04b;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-width: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 10px 20px;
  text-transform: uppercase;
}

.comments-area .comment-form p input[type=submit]:hover,
.comments-area .comment-form p input[type=submit]:focus,
.comments-area .comment-form p input[type=submit]:active {
  background-color: #6ab33e;
  border-color: #6ab33e;
  color: #fff;
}

.comment-respond .form-submit .site-button {
  font-weight: 300;
  padding: 15px 30px;
}

.comment-form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.comment-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.comment-form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.comment-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-form p {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1280px) {
  .sx-post-info-inner {
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .sx-post-info-inner {
    height: auto;
  }
}
/*-------------------------------------
	blog grid-post
-------------------------------------*/
.blog-post .grid-post li {
  float: left;
  width: 50%;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  list-style: none;
}

.blog-post .grid-post li:first-child {
  width: 100%;
}

.blog-post .grid-post li .portfolio-item {
  width: 100%;
}

.portfolio-item {
  width: 100%;
  position: relative;
  overflow: hidden;
  color: #fff;
  width: 100%;
  border: 0;
  position: relative;
}

.blog-post .grid-post li img {
  width: 100%;
}

/*-------------------------------------
	if no image with blog post
-------------------------------------*/
.date-style-2 .post-date {
  color: #000;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  text-transform: uppercase;
  background-color: #f2f2f2;
  z-index: 1;
  padding: 15px;
}

.date-style-2 .sx-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 30px;
  border: 1px solid #d8d8d8;
}

.date-style-2 .post-date strong,
.date-style-2 .post-date span {
  display: block;
  padding: 10px 5px;
}

.date-style-2 .post-date strong {
  font-size: 36px;
  line-height: 40px;
  padding: 0px;
  position: relative;
}

.date-style-2 .post-date span {
  font-size: 14px;
  font-weight: 500;
  padding: 0px;
  padding-left: 2px;
  letter-spacing: 2px;
  text-align: center;
}

.date-style-2 .post-date:after,
.date-style-2 .post-date .fa,
.date-style-outline .post-date:after,
.date-style-outline2 .post-date:after {
  display: none;
}

@media only screen and (max-width: 380px) {
  .date-style-2 .post-date strong {
    font-size: 22px;
    line-height: 26px;
  }
}
/*-------------------------------
	date style 3
--------------------------------*/
.date-style-3 .sx-post-text {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.date-style-3 .sx-post-info {
  padding: 0px 40px 30px 30px;
  position: relative;
}

.date-style-3 .sx-post-info .sx-post-meta,
.date-style-3 .sx-post-info .sx-post-title,
.date-style-3 .sx-post-info .sx-post-text {
  padding-right: 30px;
}

.date-style-3 .widget_social_inks {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  padding-left: 0px;
  padding-top: 0px;
  background-color: #eef1f2;
}

.date-style-3 .social-icons li {
  display: block;
  margin-right: 0px;
}

.date-style-3 .social-icons.social-square a,
.date-style-3 .social-icons.social-radius a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  background-color: #eef1f2;
}

.date-style-3 .social-icons li:last-child a {
  border-bottom: 0px;
}

.date-style-3 .sx-post-meta {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.date-style-3 .sx-post-meta li {
  padding: 11px 20px 9px 20px;
  border-right: 1px solid #eee;
  font-weight: 600;
}

.date-style-3 .sx-post-meta li strong {
  font-weight: 600;
}

.date-style-3 .sx-post-meta li:first-child {
  padding-left: 0px;
}

.date-style-3 .sx-post-meta li:after {
  display: none;
}

@media only screen and (max-width: 480px) {
  .date-style-3 .sx-post-meta li {
    padding: 11px 4px;
    border-right: none;
  }
  .date-style-3 .widget_social_inks {
    position: inherit;
    height: 100%;
  }
  .date-style-3 .social-icons li {
    float: left;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
  }
  .date-style-3 .social-icons li:last-child a {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  .date-style-3 .sx-post-info .sx-post-meta,
  .date-style-3 .sx-post-info .sx-post-title,
  .date-style-3 .sx-post-info .sx-post-text {
    padding-right: 0px;
  }
  .sx-post-readmore.pull-left {
    float: none;
    margin-bottom: 20px;
  }
  .widget_social_inks.pull-right {
    float: none;
  }
  .date-style-3 .sx-post-info {
    padding: 10px 20px 20px 20px;
  }
  .side-bar {
    padding: 20px;
  }
}
/*____date style outline End========*/
.recent-posts-entry-date .widget-post:last-child {
  border: none;
  margin: 0px;
  padding: 0px;
}

.recent-posts-entry-date .widget-post {
  border-bottom: 1px solid #2e313b;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.recent-posts-entry-date .widget-post .sx-post-meta {
  margin-bottom: 13px;
}

.recent-posts-entry-date .sx-post-date {
  width: 50px;
  float: left;
}

.recent-posts-entry-date .sx-post-date strong {
  display: block;
  font-size: 24px;
  line-height: 24px;
}

.recent-posts-entry-date .sx-post-date span {
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 2px;
}

.recent-posts-entry-date .sx-post-info {
  margin-left: 70px;
}

.recent-posts-entry-date .sx-post-info .post-title {
  margin-bottom: 8px;
  font-weight: 500;
}

.footer-light .recent-posts-entry-date .sx-post-info .post-title a {
  color: #000;
}

.recent-posts-entry-date .sx-post-info .sx-post-meta ul {
  margin-bottom: 0px;
}

.recent-posts-entry-date .sx-post-info .sx-post-meta ul li {
  font-weight: 500;
  font-size: 12px;
  padding: 0px;
  display: inline-block;
  color: inherit;
}

.recent-posts-entry-date .sx-post-info .sx-post-meta ul li i {
  color: #7b7b7b;
  margin: 0px 5px 0px 0px;
}

recent-posts-entry-date .sx-post-info .post-title {
  font-weight: bold;
  margin-top: 0px;
}

/*-------------------------------------
	if no image with blog post Latest-blog-3
-------------------------------------*/
.date-style-1.post-overlay .sx-post-info {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: table;
}

.date-style-1.post-overlay {
  height: 498px;
}

.date-style-1.post-overlay .sx-post-info .post-overlay-position {
  display: table-cell;
  vertical-align: bottom;
}

.date-style-1.post-overlay .sx-post-meta a {
  color: #fff;
}

.date-style-1.post-overlay .sx-post-meta li {
  color: #fff;
}

.date-style-1.post-overlay .sx-post-meta i {
  color: #fff;
}

.date-style-1 .sx-post-media img {
  width: 100%;
}

.date-style-1 .sx-post-meta .post-date strong {
  font-size: 38px;
  line-height: 38px;
  border-bottom: 3px solid;
  min-height: 56px;
  display: inline-block;
  margin-right: 10px;
}

/*======================================
HEADER (top part of page)
======================================*/
.site-header {
  position: relative;
  z-index: 999;
}

@media only screen and (max-width: 1200px) {
  .site-header .container {
    width: auto;
  }
}
.site-header ul,
.site-header ol {
  margin-bottom: 0;
}

/*-------------------------------------
	header full screen
-------------------------------------*/
.nav-wide .container {
  width: 100%;
}

/*-------------------------------------
	without top bar
-------------------------------------*/
.extra-nav {
  display: table;
  float: right;
  height: 70px;
  position: relative;
  z-index: 9;
}

.extra-nav .extra-cell {
  display: table-cell;
  padding-left: 20px;
  vertical-align: middle;
}

.extra-nav .extra-cell > a {
  font-size: 18px;
  cursor: pointer;
}

.extra-nav .extra-cell > a i.arrow-animation {
  font-size: 24px;
}

/*-------------------------------------
	map page header
-------------------------------------*/
#header-part.fix-map-header {
  height: 90px;
}

#header-part.fix-map-header .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

@media only screen and (max-width: 480px) {
  #header-part.fix-map-header {
    height: 160px;
  }
}
/*____ LOGO ( company identify  symbol )
======================================*/
/*-------------------------------------
	logo for header 
-------------------------------------*/
.logo-header {
  display: table;
  float: left;
  width: 265px;
  height: 70px;
  vertical-align: middle;
  padding: 0;
  font-size: 36px;
  color: #1bbce8;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 11;
}

.logo-header-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.logo-header-inner img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 991px) {
  .logo-header {
    width: 220px;
  }
}
@media only screen and (max-width: 480px) {
  .logo-header {
    width: 100px;
  }
}
.logo-footer {
  display: table;
  width: 170px;
  height: 70px;
  vertical-align: middle;
  padding: 0px 0px 15px 0px;
  font-size: 36px;
  color: #1bbce8;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
}

.logo-footer-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.logo-footer-inner img {
  max-width: 100%;
  max-height: 100%;
}

/*____MAIN BAR ( place logo & main-navigation )
======================================*/
.appint-btn a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

.appint-btn a:hover {
  background-color: #222;
  color: #fff;
}

/*-------------------------------------
	eamil-phone (left) 
-------------------------------------*/
.e-p-bx {
  padding: 7px 0px;
}

.e-p-bx li {
  display: inline;
  font-size: 12px;
  font-weight: 500;
  position: relative;
}

.e-p-bx li:first-child {
  padding-right: 35px;
  margin-right: 10px;
}

.e-p-bx li:first-child:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 10px;
  background-color: #000;
  height: 1px;
  width: 26px;
}

.e-p-bx.text-white li:first-child:after {
  background-color: #fff;
}

.e-p-bx li span {
  text-transform: uppercase;
  display: inline-block;
  padding-right: 10px;
  font-weight: 600;
}

.e-p-bx li a {
  color: #FFF;
}

.e-p-bx li i {
  margin-right: 5px;
  vertical-align: middle;
}

@media only screen and (max-width: 480px) {
  .nav-transparent .e-p-bx li {
    display: block;
    padding: 3px 0px;
  }
  .nav-transparent .e-p-bx.text-white li:first-child:after {
    display: none;
  }
}
/*-------------------------------------
	social-links (right) 
-------------------------------------*/
.social-bx {
  margin: 0px;
}

.social-bx li {
  font-size: 12px;
  margin: 0;
  position: relative;
}

.social-bx li a {
  font-size: 14px;
  padding: 0px;
  width: 34px;
  text-align: right;
  display: inline-block;
}

.social-bx.text-white li a {
  color: #fff;
}

@media only screen and (max-width: 480px) {
  .header-style-3 .extra-cell:last-child {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .header-top-info .sx-topbar-right {
    padding-left: 0px;
    padding-right: 0px;
  }
  .header-top-info .social-bx {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 480px) {
  .header-top-info {
    display: none;
  }
}
/*-----------------------------------*/
.main-bar {
  position: relative;
  width: 100%;
  padding: 0px 0px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.main-bar::after,
.main-bar::after {
  clear: both;
  content: "";
  display: table;
}

/*main bar with box view*/
@media only screen and (max-width: 480px) {
  .main-bar {
    padding-left: 0px;
    padding-right: 0px;
  }
}
/*header info css*/
.header-info {
  float: right;
  padding: 10px 0;
}

.header-info > ul {
  list-style: none;
  display: table;
}

.header-info > ul li {
  display: table-cell;
  padding: 15px 10px;
  vertical-align: middle;
}

.header-info > ul li .icon-sm {
  float: left;
  margin-right: 15px;
}

.header-info > ul li strong {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  color: #3C4048;
}

@media only screen and (max-width: 767px) {
  .header-info > ul li.btn-col-last,
  .header-info {
    display: none;
  }
}
.header-botton {
  background-color: #222;
}

.header-botton .header-nav .nav {
  float: none;
}

.header-botton .header-nav .nav > li > a {
  padding: 20px 12px;
  color: #fff;
}

.header-botton .extra-nav {
  padding: 13px 0;
}

.header-botton .extra-nav .site-search-btn {
  color: #fff;
  padding: 7px 15px;
}

.header-botton .extra-nav .sx-cart .woo-cart-count {
  border: 2px solid #fff;
  color: #fff;
  min-width: 36px;
  padding: 5px 5px 5px;
}

.header-botton .extra-nav .sx-cart .woo-cart-count::before {
  border-color: #fff;
}

.header-style-1 .is-fixed.color-fill .main-bar {
  background: #fff;
  padding: 5px 0px;
}

.nav-transparent .is-fixed.color-fill .main-bar {
  background: #3C4048;
}

.nav-transparent .extra-nav .extra-cell > a {
  color: #fff;
}

.nav-transparent .appint-btn a {
  color: #fff;
}

.nav-transparent .navbar-toggler .icon-bar {
  background-color: #fff;
}

.header-style-1 .main-bar {
  border-bottom: 1px solid #efefef;
}

.header-style-1 .top-bar {
  position: relative;
  z-index: 8;
}

.top-bar.bg-gray {
  color: inherit;
  padding: 0px 0;
}

.top-bar.bg-gray .fa {
  color: #222;
}

.sx-topbar-left,
.sx-topbar-right,
.sx-topbar-center {
  padding-left: 15px;
  padding-right: 15px;
}

.sx-topbar-left {
  float: left;
}

.sx-topbar-right {
  float: right;
}

.sx-topbar-center {
  text-align: center;
}

/*header style 1*/
.header-style-1.nav-wide .container {
  padding-left: 40px;
  max-width: 100%;
}

.header-style-1 .header-left-gray-block .logo-header {
  position: relative;
  z-index: 0;
  width: 180px;
  margin-right: 30px;
  padding-right: 20px;
}

.header-style-1 .header-left-gray-block .logo-header:after {
  content: "";
  right: 0px;
  top: -11px;
  position: absolute;
  width: 3000px;
  background-color: #eef1f2;
  z-index: -1;
  bottom: -11px;
}

.header-style-1 .is-fixed.color-fill .header-left-gray-block .logo-header:after {
  top: -14px;
  bottom: -14px;
}

.header-style-1.nav-wide .header-nav .nav {
  float: right;
}

@media only screen and (max-width: 991px) {
  .header-style-1 .sx-topbar-left,
  .header-style-1 .sx-topbar-right {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .header-style-1 .header-left-gray-block .logo-header:after {
    display: none;
  }
  .header-style-1.nav-wide .container {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .header-style-1 .sx-topbar-left ul li {
    display: block;
    padding-bottom: 1px;
  }
  .header-style-1 .sx-topbar-left ul li:last-child {
    padding-bottom: 0px;
  }
  .header-style-1 .top-bar .row {
    display: table;
    width: 100%;
    float: none;
    margin: 0px;
  }
  .header-style-1 .top-bar .row .sx-topbar-left,
  .header-style-1 .top-bar .row .sx-topbar-right {
    display: table-cell;
    vertical-align: middle;
    float: none;
    padding: 0px;
  }
  .header-style-1 .top-bar .row .sx-topbar-left {
    text-align: left;
  }
  .header-style-1 .top-bar .row .sx-topbar-left .e-p-bx {
    float: none !important;
  }
  .header-style-1 .top-bar .row .sx-topbar-right {
    text-align: right;
  }
  .header-style-1 .header-left-gray-block .logo-header {
    width: 120px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 480px) {
  .header-style-1 .top-bar .row .sx-topbar-left,
  .header-style-1 .top-bar .row .sx-topbar-right {
    display: block;
    float: none;
    padding: 0px;
    margin-bottom: 10px;
  }
  .header-style-1 .top-bar .row .sx-topbar-left {
    text-align: left;
  }
  .header-style-1 .top-bar .row .sx-topbar-right {
    text-align: left;
  }
}
@media only screen and (max-width: 400px) {
  .header-style-1 .header-left-gray-block .logo-header {
    width: 100px;
    margin-right: 0px;
    padding-right: 10px;
  }
}
.top-bar.bg-gray {
  color: inherit;
}

/*____ NAVIGATIONS 
======================================*/
/*-------------------------------------
	mega-menu
-------------------------------------*/
.navbar-toggler {
  border: none;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 15px;
  margin-right: -10px;
  padding: 12px 10px;
}

.navbar-toggler .icon-bar {
  background: #000;
  height: 3px;
  border-radius: 0;
  -webkit-border-radius: 0;
  display: block;
  width: 22px;
}

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media only screen and (max-width: 480px) {
  .navbar-toggler {
    margin-left: 5px;
  }
  .extra-nav .extra-cell {
    margin-left: 0px;
    padding-left: 10px;
  }
}
.navbar-toggler {
  display: none;
  margin-top: 14px;
  margin-bottom: 14px;
}

.header-nav {
  position: relative;
  padding: 0px;
  z-index: 7;
}

.header-nav .nav {
  float: right;
  display: flex;
  justify-content: flex-end;
  flex-direction: inherit;
}

.header-nav .nav i {
  font-size: 9px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: middle;
  opacity: 0.7;
}

.header-nav .nav > li {
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
  margin: -11px 0;
  padding: 10px 0;
}

.header-nav .nav > li > a {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  color: #222;
  display: inline-block;
  font-size: 14px;
  padding: 33px 12px;
  cursor: pointer;
  margin: -7px 0;
  font-weight: 600;
  text-transform: capitalize;
}

.header-nav .nav > li > a:hover {
  background-color: transparent;
  color: #212427;
}

.header-nav .nav > li > a:active,
.header-nav .nav > li > a:focus {
  background-color: transparent;
}

.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a {
  background-color: transparent;
  color: #00ABB3;
}

.header-nav .nav > li:hover > a {
  color: #00ABB3;
}

.header-nav .nav > li:hover > .sub-menu,
.header-nav .nav > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-nav .nav > li .sub-menu {
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  display: block;
  left: 0;
  top: 100%;
  list-style: none;
  margin: 20px 0 0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
}

.header-nav .nav > li .sub-menu li {
  position: relative;
  text-transform: none;
}

.header-nav .nav > li .sub-menu li a {
  color: #000;
  display: block;
  font-size: 14px;
  padding: 14px 20px;
  font-weight: 400;
  border-left: 0px solid transparent;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.header-nav .nav > li .sub-menu li a:hover {
  color: #000;
  text-decoration: none;
  padding-left: 10px;
}

.header-nav .nav > li .sub-menu li a:hover:after {
  width: 4px;
  left: -1px;
}

.header-nav .nav > li .sub-menu li:hover > a {
  color: #000;
  background-color: #f6f6f6;
}

.nav-dark.header-nav .nav > li .sub-menu li:hover > a {
  color: #3C4048;
  background-color: #00ABB3;
}

.header-nav .nav > li .sub-menu li:last-child {
  border-bottom: 0px;
}

.header-nav .nav > li .sub-menu li .fa {
  color: #000;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 6px;
}

.nav-dark.header-nav .nav > li .sub-menu li .fa {
  color: #00ABB3;
}

.header-nav .nav > li .sub-menu li:hover .fa {
  color: #000;
}

.header-nav .nav > li .sub-menu li > .sub-menu {
  left: 220px;
  top: 0px;
  margin: 0 0 0 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-nav .nav > li .sub-menu li:hover > .sub-menu {
  left: 220px;
  margin: 0px;
  opacity: 1;
  top: -1px;
  visibility: visible;
}

.header-nav .nav > li .sub-menu li:hover > .sub-menu:before {
  background-color: transparent;
  bottom: 0px;
  content: "";
  display: block;
  height: 100%;
  left: -6px;
  position: absolute;
  top: 0px;
  width: 6px;
}

.header-nav .nav > li.has-mega-menu {
  position: inherit;
}

.header-nav .nav > li .mega-menu li a > i {
  font-size: 13px;
  margin-right: 5px;
  min-width: 20px;
}

.header-nav .nav > li .mega-menu {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  display: table;
  left: 0px;
  list-style: none;
  opacity: 0;
  position: absolute;
  right: 0px;
  visibility: hidden;
  width: 100%;
  margin-top: 20px;
  z-index: 10;
}

.header-nav .nav > li .mega-menu > li {
  display: table-cell;
  padding: 10px 0;
  position: relative;
  vertical-align: top;
  width: 25%;
  text-transform: none;
}

.header-nav .nav > li .mega-menu > li:after {
  content: "";
  background-color: rgba(204, 204, 204, 0.4);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}

.header-nav .nav > li .mega-menu > li:last-child:after {
  display: none;
}

.header-nav .nav > li .mega-menu > li > a {
  color: #555555;
  display: block;
  font-size: 14px;
  padding: 0 20px;
}

.header-nav .nav > li .mega-menu > li ul {
  list-style: none;
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

.header-nav .nav > li .mega-menu > li ul a {
  color: #474747;
  display: block;
  font-size: 13px;
  line-height: 34px;
  padding: 2px 20px;
}

.header-nav .nav > li .mega-menu > li ul a:hover {
  color: #1BBCE8;
  background-color: #F2F2F2;
}

.header-nav .nav > li.menu-item-has-children:before {
  content: "\f078";
  display: block;
  font-family: "FontAwesome";
  right: 4px;
  position: absolute;
  top: 50%;
  color: #999;
  margin-top: -8px;
  font-size: 8px;
}

/*-- Submenu direction---*/
.header-nav .nav > li.submenu-direction .sub-menu {
  left: auto;
  right: 0;
}

.header-nav .nav > li.submenu-direction .sub-menu li > .sub-menu {
  left: auto;
  right: 220px;
  margin: 0 20px 0 0;
}

.header-nav .nav > li.submenu-direction .sub-menu li:hover > .sub-menu {
  left: auto;
  right: 220px;
  margin: 0 0 0 0;
}

.has-child .submenu-toogle {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #000;
  background-color: transparent;
  z-index: 900;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  display: none;
}

.header-nav .has-child li .submenu-toogle {
  opacity: 0.9;
}

.header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "\f107";
}

/*.header-nav .nav > li:last-child{display:none;}*/
@media only screen and (max-width: 991px) {
  header .logo {
    display: block;
    padding: 4px 0;
  }
  .header-nav .nav i {
    margin-top: 6px;
  }
  .header-nav .nav li.has-child.nav-active > a {
    color: #00ABB3;
  }
  .header-nav .nav > li .sub-menu li a {
    font-size: 12px;
  }
}
@media only screen and (max-width: 991px) {
  /* responsive strat when media screen [991px] css START*/
  .navbar-header {
    float: none;
  }
  .navbar-toggler {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
    float: right;
    outline: none !important;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapse.show {
    display: block !important;
  }
  /* Responsive strat when medai screen [991px] css END*/
  .header-nav {
    clear: both;
    margin: 0 -15px;
    border-bottom: 1px solid #E9E9E9;
  }
  .header-nav .nav {
    float: none;
    margin: 0px;
    background: #fff;
    width: 100%;
  }
  .header-nav .nav li {
    float: none;
    width: 100%;
    display: block;
  }
  .header-nav .nav > li .sub-menu > li,
  .header-nav .nav > li .mega-menu > li {
    float: none;
    display: block;
    width: auto;
  }
  .header-nav .nav li .sub-menu > li a {
    padding-left: 30px;
  }
  .header-nav .nav > li > a {
    padding: 12px 15px;
    border-top: 1px solid #E9E9E9;
    color: #777;
  }
  .header-nav .nav > li.active > a,
  .header-nav .nav > li.current-menu-item > a {
    color: #000;
  }
  .header-nav .nav i {
    float: right;
  }
  .header-nav .nav > li > a:hover,
  .header-nav .nav > li > a:active,
  .header-nav .nav > li > a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
  .header-nav .nav > li .mega-menu > li:after {
    display: none;
  }
  .header-nav .nav > li ul,
  .header-nav .nav > li .sub-menu,
  .header-nav .nav > li .mega-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
  .header-nav .nav > li ul.mega-menu ul {
    display: block;
  }
  .header-nav .nav > li .mega-menu > li {
    padding: 0px;
  }
  .header-nav .nav > li ul.mega-menu li:hover ul {
    display: block;
  }
  .header-nav .nav li.has-child {
    position: relative;
  }
  .header-nav .nav li .sub-menu,
  .header-nav .nav li .mega-menu {
    opacity: 1;
    margin: 0 !important;
  }
  .has-child .submenu-toogle {
    display: block;
  }
}
.right-nav-header {
  display: table-header-group;
  vertical-align: top;
}

.right-nav-header .r-nav-h-content {
  vertical-align: middle;
  display: table-cell;
}

.right-nav-footer {
  display: table-footer-group;
}

.right-nav-footer .r-nav-f-content {
  vertical-align: bottom;
  display: table-cell;
}

.social-rounded {
  text-align: center;
}

.social-rounded li a {
  font-size: 12px;
  color: #000;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.social-rounded li a:hover {
  background-color: #000;
  color: #fff;
}

@media only screen and (max-width: 360px) {
  .social-rounded.list-inline > li {
    padding-right: 0px;
  }
}
.vertical-content-wrap .right-nav-header,
.vertical-content-wrap .vertical-content-area,
.vertical-content-wrap .right-nav-footer {
  position: relative;
  top: 20px;
  opacity: 0;
}

.vertical-content-wrap .right-nav-header {
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.vertical-content-wrap .vertical-content-area {
  -webkit-transition: all 1s ease-in;
  -moz-transition: all 1s ease-in;
  -ms-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
}

.vertical-content-wrap .right-nav-footer {
  -webkit-transition: all 1.5s ease-in;
  -moz-transition: all 1.5s ease-in;
  -ms-transition: all 1.5s ease-in;
  -o-transition: all 1.5s ease-in;
  transition: all 1.5s ease-in;
}

/*-------------------------------------
	header dark
-------------------------------------*/
.header-dark-1 .main-bar {
  background-color: #3C4048;
}

.header-dark-1 .main-bar .header-nav .nav > li.active > a {
  color: #00ABB3;
}

.header-dark-1 .main-bar .header-nav .nav > li > a {
  color: #fff;
}

.header-dark-1 .main-bar .header-nav .nav > li:hover > a {
  color: #00ABB3;
}

.header-dark-1 .main-bar .header-nav .nav > li .sub-menu li .fa {
  color: #fff;
}

.header-dark-1 .main-bar .header-nav .nav > li .sub-menu li:hover > a {
  background-color: #121212;
}

.header-dark-1 .main-bar .extra-cell a {
  color: #fff;
}

.header-dark-1 .main-bar .navbar-toggler .icon-bar {
  background-color: #fff;
}

.header-style-1.header-dark-1 .is-fixed.color-fill .main-bar {
  background: #3C4048 !important;
}

.header-style-1 .top-bar {
  border-bottom: 1px solid #eee;
}

.nav-transparent .main-bar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  left: 0px;
  padding: 0px;
}

.nav-transparent .main-bar .container {
  padding: 10px 15px;
}

.nav-transparent .main-bar .header-nav .nav > li > a {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .nav-transparent .main-bar .header-nav .nav > li > a {
    display: block;
    color: #000;
  }
  .nav-transparent .main-bar .header-nav.nav-dark .nav > li > a {
    display: block;
    color: #fff;
  }
  .nav-transparent .main-bar .container {
    padding: 0px 15px;
    max-width: 100%;
  }
  .nav-transparent .top-bar .container {
    max-width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  .nav-transparent .top-bar .container .d-flex {
    display: block !important;
  }
  .nav-transparent .top-bar .container .d-flex .social-bx li {
    display: inline-block;
    padding-bottom: 7px;
  }
  .nav-transparent .top-bar .container .d-flex .social-bx li a {
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  .header-style-1 .top-bar {
    display: none;
  }
}
/*-------------------------------------
	nav dark version
-------------------------------------*/
.nav-dark.header-nav .nav > li .sub-menu,
.nav-dark.header-nav .nav > li .mega-menu {
  background-color: #3C4048;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-dark.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid #23282f;
}

.nav-dark.header-nav .nav > li .sub-menu li:last-child {
  border-bottom: 0px;
}

.nav-dark.header-nav .nav > li .sub-menu li a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a,
.nav-dark.header-nav .nav > li .mega-menu > li > a {
  color: #fff;
}

.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover {
  background-color: #23282f;
  color: #00ABB3;
}

.nav-dark.header-nav .nav > li .mega-menu > li:after {
  background-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
  .nav-dark.header-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .nav-dark.header-nav .nav {
    background-color: #3C4048;
  }
  .nav-dark.header-nav .nav > li > a {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .nav-dark.header-nav .nav > li > a {
    color: #fff;
    display: block;
  }
  .nav-dark.header-nav .nav > li > a:hover,
  .nav-dark.header-nav .nav > li > a:active,
  .nav-dark.header-nav .nav > li > a:focus {
    background-color: #3C4048;
    color: #00ABB3;
  }
  .nav-dark.header-nav .nav > li ul,
  .nav-dark.header-nav .nav > li .sub-menu,
  .nav-dark.header-nav .nav > li .mega-menu {
    background-color: #3C4048;
  }
  .nav-dark.header-nav .nav > li .sub-menu {
    background-color: #000;
  }
  .nav-dark .has-child .submenu-toogle {
    color: #fff;
  }
}
/*-------------------------------------
	when header is sticky
-------------------------------------*/
.sticky-no .main-bar {
  position: static !important;
}

/*-------------------------------------
	when header is sticky 
-------------------------------------*/
/* header sticky  */
/*____WHEN HEADER IS STICKY____*/
.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
}

.is-fixed .header-nav .nav > li > a {
  padding: 34px 12px;
}

.is-fixed .extra-nav {
  padding: 13px 0;
}

.is-fixed.color-fill .header-nav .nav > li > a {
  padding: 34px 12px;
}

.is-fixed.color-fill.main-bar {
  background: #141b1f;
}

@media only screen and (max-width: 540px) {
  .is-fixed .navbar-toggler {
    margin-bottom: 0px;
  }
  .contact-slide-hide {
    right: -100%;
    width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .is-fixed .header-nav .nav {
    overflow: auto;
    height: 300px;
  }
  .is-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }
  .is-fixed#header-part .navbar-toggler {
    margin: 15px 0;
  }
}
@media only screen and (max-width: 768px) {
  .is-fixed .extra-nav {
    padding: 13px 7px;
  }
}
@media only screen and (max-width: 767px) {
  .is-fixed .extra-nav {
    padding: 13px 0px;
  }
}
/*-------------------------------------
 header id Fixed
-------------------------------------*/
/*.is-fixed.color-fill .main-bar {    padding:0px 64px;}*/
@media only screen and (max-width: 767px) {
  .main-bar,
  .is-fixed .main-bar,
  .is-fixed.color-fill .main-bar {
    padding: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .main-bar .container {
    max-width: 100%;
    padding: 0px 15px;
  }
}
/*-------------------------------------
	header with bg primary color 1
-------------------------------------*/
.sx-bg-primary .header-nav .nav > li > a {
  color: #fff;
}

.sx-bg-primary .header-nav .nav > li:hover > a,
.sx-bg-primary .header-nav .nav > li.active > a,
.sx-bg-primary .header-nav .nav > li.current-menu-item > a {
  color: #3C4048;
}

.sx-bg-primary .site-search-btn,
.sx-bg-primary .sx-cart .woo-cart-total,
.sx-bg-primary .sx-cart .woo-cart-count {
  color: #fff;
}

.sx-bg-primary .sx-cart .woo-cart-count,
.sx-bg-primary .sx-cart .woo-cart-count::before {
  border-color: #fff;
}

.sx-bg-primary .navbar-toggler .icon-bar {
  background-color: #fff;
}

@media only screen and (max-width: 991px) {
  .sx-bg-primary .header-nav .nav > li > a {
    color: #777;
  }
  .sx-bg-primary .header-nav .nav > li:hover > a,
  .sx-bg-primary .header-nav .nav > li.active > a,
  .sx-bg-primary .header-nav .nav > li.current-menu-item > a {
    color: #00ABB3;
  }
  .header-nav .nav > li {
    margin: 0;
    padding: 0;
  }
  .header-nav .nav > li > a {
    margin: 0;
    padding: 10px 15px;
    font-weight: 600;
    display: block;
  }
  .is-fixed.color-fill .header-nav .nav > li {
    margin: 0;
    padding: 0;
  }
  .is-fixed.color-fill .header-nav .nav > li > a {
    margin: 0;
    padding: 10px 15px;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .header-middle .logo-header {
    float: none;
    margin: 9px auto;
  }
}
/*____BANNER
======================================*/
/*-------------------------------------
	inner page banner 
-------------------------------------*/
.sx-bnr-inr {
  height: 500px;
  background-position: center center;
  display: table;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.sx-bnr-inr .container {
  display: table;
  height: 100%;
}

.sx-bnr-inr-entry {
  display: table-cell;
  vertical-align: bottom;
  text-align: left;
  position: relative;
  z-index: 1;
  vertical-align: bottom;
  width: 100%;
  padding-bottom: 30px;
}

.sx-bnr-inr-entry .banner-title-outer {
  position: relative;
  margin-bottom: 20px;
}

.sx-bnr-inr-entry .banner-title-outer h2 {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 0px;
}

.sx-bnr-inr-entry .banner-title-outer p {
  color: #fff;
  max-width: 555px;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .sx-bnr-inr-entry .banner-title-name h1,
  .sx-bnr-inr-entry .banner-title-name h2,
  .sx-bnr-inr-entry .banner-title-name h3 {
    font-size: 26px;
    margin-bottom: 10px;
  }
}
.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
  color: #FFF;
}

/*____SLIDER
======================================*/
/*-------------------------------------
	owl slider 
-------------------------------------*/
/*-------------------------------------
	next pre btn
-------------------------------------*/
.owl-carousel .owl-nav {
  margin-top: 30px;
  text-align: center;
}

.owl-carousel .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

/*-------------------------------------
	owl dots button
-------------------------------------*/
.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.owl-carousel .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 5px;
  background: #444;
  display: block;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
  background: #00ABB3;
}

/*-------------------------------------
	owl button top to center
-------------------------------------*/
.owl-btn-top-center .owl-nav,
.owl-btn-top-left .owl-nav,
.owl-btn-top-right .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-center .owl-stage-outer,
.owl-btn-top-left .owl-stage-outer,
.owl-btn-top-right .owl-stage-outer {
  padding-top: 40px;
}

.owl-btn-top-center .owl-nav {
  text-align: center;
}

/*-------------------------------------
	owl button top to left
-------------------------------------*/
.owl-btn-top-left .owl-nav {
  text-align: left;
}

/*-------------------------------------
	owl button top to right
-------------------------------------*/
.owl-btn-top-right .owl-nav {
  text-align: right;
}

/*-------------------------------------
	owl button bottom to center [it is default position ]
-------------------------------------*/
.owl-btn-bottom-center .owl-nav {
  text-align: center;
}

/*-------------------------------------
	owl button bottom to left
-------------------------------------*/
.owl-btn-bottom-left .owl-nav {
  text-align: left;
}

/*-------------------------------------
	owl button bottom to right
-------------------------------------*/
.owl-btn-bottom-right .owl-nav {
  text-align: right;
}

/*-------------------------------------
	 owl button vertical to center
-------------------------------------*/
.owl-btn-vertical-center .owl-nav {
  margin: 0px;
}

.owl-btn-vertical-center .owl-nav .owl-prev,
.owl-btn-vertical-center .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  margin: -15px 0;
}

.owl-btn-vertical-center .owl-nav .owl-prev {
  left: 0;
}

.owl-btn-vertical-center .owl-nav .owl-next {
  right: 0;
}

/*-------------------------------------
	owl button show on hover
-------------------------------------*/
.owl-btn-hover .owl-nav {
  opacity: 0;
}

.owl-btn-hover:hover .owl-nav {
  opacity: 1;
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

/*-------------------------------------
    owl slider button
-------------------------------------*/
.owl-prev,
.owl-next {
  background-color: #fff;
  border-color: #fff;
  color: #000;
  padding: 8px 10px;
  text-align: center;
  font-size: 24px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.owl-prev {
  margin-right: 5px;
}

.owl-next {
  margin-left: 5px;
}

.owl-prev:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-next:hover,
.owl-next:active,
.owl-next:focus {
  background-color: #212121;
  border-color: #212121;
  color: #ffffff;
  opacity: 0.9;
}

/*-------------------------------------
	owl dots nav position
-------------------------------------*/
.owl-dots-bottom-left .owl-dots,
.owl-dots-bottom-right .owl-dots,
.owl-dots-bottom-center .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
}

.owl-dots-bottom-left .owl-dots {
  text-align: left;
}

.owl-dots-bottom-right .owl-dots {
  text-align: right;
}

/*____PAGINATION 
======================================*/
/*pagination style-1 */
.pagination-bx .pagination,
.cvf-universal-pagination .pagination {
  margin: 0;
}

.pagination {
  padding: 10px 0;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pagination > li > a,
.pagination > li > span {
  background-color: #fff;
  border: 1px solid #acacac;
  color: #767676;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 15px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #000;
  border-color: transparent;
  color: #fff;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #000;
  border-color: transparent;
  color: #fff;
}

.pagination > .previous > a,
.pagination > .next > a {
  font-size: 12px;
}

/*____FOOTER 
======================================*/
/*-------------------------------------
	 footer Large css
-------------------------------------*/
.footer-large .footer-bottom {
  padding: 20px 0px;
  color: #787878;
  font-size: 13px;
}

/*-------------------------------------
	footer fixed on bottom css
-------------------------------------*/
/*footer fixed with fullscreen view*/
.footer-fixed .site-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  border: none;
  z-index: 0;
}

.footer-fixed .page-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
}

@media only screen and (max-width: 1200px) {
  .footer-fixed .site-footer {
    position: static;
  }
  .footer-fixed .page-wraper {
    padding-bottom: 0 !important;
  }
}
/*footer fixed with boxed view*/
.site-footer .widget > .widget-title {
  font-weight: 600;
}

.site-footer p,
.site-footer li {
  line-height: 20px;
}

.site-footer {
  background-position: center;
  background-size: cover;
  position: relative;
}

.site-footer p {
  line-height: 20px;
}

.site-footer .widget ul {
  list-style: none;
  margin-top: 0px;
}

.footer-dark .footer-bottom .copyrights-text {
  color: #fff;
}

.footer-dark .footer-bottom .copyrights-nav {
  list-style: none;
  display: inline-block;
  margin-bottom: 0px;
}

.footer-dark .footer-bottom .copyrights-nav li {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}

.footer-dark .footer-bottom .copyrights-nav li:first-child {
  margin-left: 0;
}

.footer-dark .footer-bottom .copyrights-nav li:last-child {
  margin-right: 0;
}

.footer-dark .footer-bottom .copyrights-nav li:last-child:after {
  display: none;
}

.footer-dark .footer-bottom .copyrights-nav li:after {
  content: "/";
  position: relative;
  right: -8px;
}

.footer-dark .footer-bottom .copyrights-nav li a {
  color: #b7b7b7;
}

.footer-dark .footer-bottom .copyrights-nav li a:hover,
.footer-dark .footer-bottom .copyrights-nav li a:active,
.footer-dark .footer-bottom .copyrights-nav li a:focus,
.footer-dark .footer-bottom .copyrights-nav li.active a {
  color: #e2e2e2;
}

.footer-dark .widget_services ul li a {
  color: #e2e2e2;
}

.footer-bottom {
  padding: 100px 0px;
  color: #787878;
  font-size: 13px;
}

.footer-bottom .copyrights-text {
  color: #000;
}

.footer-bottom .copyrights-nav {
  list-style: none;
  display: inline-block;
}

.footer-bottom .copyrights-nav li {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}

.footer-bottom .copyrights-nav li:first-child {
  margin-left: 0;
}

.footer-bottom .copyrights-nav li:last-child {
  margin-right: 0;
}

.footer-bottom .copyrights-nav li:last-child:after {
  display: none;
}

.footer-bottom .copyrights-nav li:after {
  content: "/";
  position: relative;
  right: -8px;
}

.footer-bottom .copyrights-nav li a {
  color: #000;
}

.footer-bottom .copyrights-nav li a:hover,
.footer-bottom .copyrights-nav li a:active,
.footer-bottom .copyrights-nav li a:focus,
.footer-bottom .copyrights-nav li.active a {
  color: #333333;
}

@media only screen and (max-width: 991px) {
  .site-footer {
    padding: 0px;
  }
  .copyright-block {
    padding-top: 5px;
  }
}
/*-------------------------------------
	Footer dark version css
-------------------------------------*/
.footer-dark.site-footer {
  border-top: 0px solid #212121;
}

.footer-dark .footer-top {
  background-color: #3C4048;
}

.footer-dark .footer-bottom {
  background-color: #3C4048;
  border-top: 1px solid #2e313b;
}

.footer-dark .footer-top h1,
.footer-dark .footer-top h2,
.footer-dark .footer-top h3,
.footer-dark .footer-top h4,
.footer-dark .footer-top h5,
.footer-dark .footer-top h6,
.footer-dark .footer-top h1 a,
.footer-dark .footer-top h2 a,
.footer-dark .footer-top h3 a,
.footer-dark .footer-top h4 a,
.footer-dark .footer-top h5 a,
.footer-dark .footer-top h6 a {
  color: #fff;
}

.footer-dark .footer-top .widget-title::before {
  border-color: #fff;
}

.footer-dark .footer-top .widget-title {
  color: #00ABB3;
}

.footer-dark .call-to-action-left h5 {
  color: #00ABB3;
}

.footer-dark .footer-top p,
.footer-dark .footer-top strong,
.footer-dark .footer-top b,
.footer-dark .footer-top {
  color: #e2e2e2;
}

.footer-dark .site-button {
  background-color: #0e0e0e;
}

.footer-dark .site-button:hover {
  background-color: #fff;
  color: #000;
}

.footer-dark .footer-top a:active,
.footer-dark .footer-top a:focus,
.footer-dark .footer-top a:hover {
  color: #00ABB3;
}

.footer-dark .footer-top p a {
  color: #fff;
}

.footer-dark .footer-top .widget_newsletter .form-control {
  background-color: #2e313b;
  height: 50px;
}

.footer-light .footer-top {
  background-color: #eee;
}

.footer-light .footer-bottom {
  background-color: #c8c8c8;
}

.footer-top {
  padding: 60px 0px 60px 0px;
}

.footer-wide .container-fluid {
  width: 100%;
  max-width: 1170;
}

@media only screen and (max-width: 1366px) {
  .footer-wide .container-fluid {
    width: 100%;
    max-width: 1170px;
  }
}
@media only screen and (max-width: 991px) {
  .footer-wide .container-fluid {
    padding: 0px 80px;
  }
}
@media only screen and (max-width: 800px) {
  .footer-wide .container-fluid {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-top {
    padding: 60px 0px 60px 0px;
  }
}
/*-------------------------------------
	widget Getintuch
-------------------------------------*/
.footer-bottom ul {
  margin: 0;
}

.sx-footer-bot-left,
.sx-footer-bot-right,
.sx-footer-bot-center {
  padding-left: 15px;
  padding-right: 15px;
}

.sx-footer-bot-left {
  float: left;
}

.sx-footer-bot-right {
  float: right;
}

.sx-footer-bot-center {
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .footer-col-3 {
    clear: both;
  }
}
@media only screen and (max-width: 991px) {
  footer .container {
    width: 100%;
  }
  .sx-footer-bot-left,
  .sx-footer-bot-right {
    float: none;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sx-footer-bot-right .copyrights-nav {
    float: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .footer-clear {
    display: block !important;
    clear: both;
  }
  #footer .footer-4-col {
    width: 100%;
  }
  .footer-bottom [class*=clo-] {
    width: 100%;
  }
}
/*-------------------------------------
	scroll top btn css
-------------------------------------*/
button.scroltop {
  height: 40px;
  width: 40px;
  background: #000;
  border: none;
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: none;
  text-align: center;
  z-index: 999;
  color: #fff;
}

button.scroltop span {
  display: block;
  font-size: 24px;
  line-height: 24px;
}

.string {
  position: relative;
}

#btn-vibrate {
  animation: vibrate 2s linear 0s infinite;
  -moz-animation: vibrate 2s linear 0s infinite;
  /* Firefox */
  -webkit-animation: vibrate 2s linear 0s infinite;
  /* Safari and Chrome */
  -o-animation: vibrate 2s linear 0s infinite;
  /* Opera */
}

@keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}
/* Firefox */
@-moz-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}
/* Safari and Chrome */
@-webkit-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}
/* Opera */
@-o-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}
@media only screen and (max-width: 480px) {
  button.scroltop {
    font-size: 7px;
    height: 30px;
    width: 30px;
    line-height: 16px;
  }
  button.scroltop span {
    font-size: 10px;
    line-height: 10px;
  }
}
/*____PAGE-CONTENT
======================================*/
#bg {
  background-attachment: fixed;
  background-size: cover;
}

.page-wraper {
  background: #fff;
}

.page-content {
  position: relative;
}

.section-full {
  position: relative;
}

/*-------------------------------------
	Section Head Part 
-------------------------------------*/
.section-head {
  margin-bottom: 40px;
}

.section-head.no-margin {
  margin-bottom: 0;
}

.section-head h1,
.section-head h2,
.section-head h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}

.title-small {
  display: block;
  color: #7a959d;
  margin-bottom: 15px;
}

.section-head p {
  font-size: 14px;
}

.section-head p:last-child {
  margin-bottom: 0;
}

.text-center.section-head p {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.text-white {
  color: #FFF;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white .title-small {
  color: #FFF;
}

/*-------------------------------------
	Section Content Part 
-------------------------------------*/
@media only screen and (max-width: 1024px) {
  .section-full {
    background-attachment: scroll !important;
  }
}
@media only screen and (max-width: 767px) {
  .section-head h1,
  .section-head h2,
  .section-head h3,
  h1,
  h2,
  h3 {
    font-size: 22px;
  }
  .sx-post-title h2.post-title {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
}
/*____AFTER-LOGIN-CONTENT
======================================*/
/*-------------------------------------
	Contact us page 
-------------------------------------*/
.contact-form {
  padding-right: 100px;
}

.contact-info {
  margin-left: -100px;
  margin-top: 150px;
}

ul.contact-info li {
  padding: 0 0 15px 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  line-height: 20px;
}

ul.contact-info li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

ul.contact-info li h4 ul.contact-info li h5,
ul.contact-info li h6 {
  margin: 0 0 10px;
}

ul.contact-info li p {
  line-height: 20px;
  margin: 0;
}

ul.contact-info li .fa {
  position: absolute;
  left: 0;
  top: 2px;
  color: #3396d1;
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .contact-info {
    margin-left: 0px;
    margin-top: 30px;
  }
  .contact-form {
    padding-right: 30px;
  }
}
/*____THEMEFOREST REQUIRED CLASSES
======================================*/
.sticky {
  clear: both;
}

.gallery-caption {
  clear: both;
}

.bypostauthor {
  clear: both;
}

/*-------------------------------------
	Text meant only for screen readers
-------------------------------------*/
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/*____ BOX MAX-WIDTH css (  )
======================================*/
.max-w100 {
  max-width: 100px;
}

.max-w200 {
  max-width: 200px;
}

.max-w300 {
  max-width: 300px;
}

.max-w400 {
  max-width: 400px;
}

.max-w500 {
  max-width: 500px;
}

.max-w600 {
  max-width: 600px;
}

.max-w700 {
  max-width: 700px;
}

.max-w800 {
  max-width: 800px;
}

.max-w900 {
  max-width: 900px;
}

.max-w1000 {
  max-width: 1000px;
}

/*____ SHOP & PRODUCT DETAIL (Woocommerce)
======================================*/
/*-------------------------------------
	Product Box 
-------------------------------------*/
.sx-product-box .price {
  margin-bottom: 10px;
}

.sx-product-box .price del {
  font-size: 16px;
  color: #999999;
  margin: 4px 4px;
  font-weight: 600;
}

.sx-product-box .price ins {
  font-size: 16px;
  color: #111111;
  text-decoration: none;
  margin: 4px 4px;
  font-weight: 600;
}

.sx-product-box .rating-bx {
  padding-top: 10px;
  color: #ffa000;
}

.comment-reply-title {
  margin-top: 0;
}

.comment-form [class*=comment-form] {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .comment-form [class*=comment-form] {
    width: 100%;
  }
}
.comment-form [class*=-form-author],
.comment-form [class*=-form-email] {
  float: left;
}

.comment-form [class*=-form-comment] {
  width: 100%;
}

.comment-form label {
  display: block;
}

.star-Rating-input i {
  font-size: 18px;
}

.star-Rating-input .fa-star {
  color: #00ABB3;
}

.star-Rating-input i:hover {
  color: #00ABB3;
}

.comment-form [class*=comment-form] input,
.comment-form [class*=comment-form] textarea {
  border: 1px solid #CCC;
  padding: 8px 10px;
  width: 100%;
}

.comment-form [class*=comment-form],
.comment-form .form-submit {
  margin-bottom: 20px;
  padding: 0 15px;
}

.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
  background: #037a85;
}

/*____content show on image box () 
======================================*/
@media only screen and (max-width: 768px) {
  .sx-media > img,
  .sx-media a > img {
    width: 100%;
  }
}
/*____Customize  Grouping css 
======================================*/
/*-------------------------------------
	set-radius 
-------------------------------------*/
.btn,
.panel,
.form-control,
.img-thumbnail,
.panel-head ing,
.dropdown-menu,
.panel-group .panel,
.nav-tabs > li > a,
.modal-content,
.navbar-toggler,
.nav-pills > li > a,
.pager li > a,
.pager li > span,
.well,
.alert,
.list-group-item:first-child,
.list-group-item:last-child,
.input-group-addon,
.btn-group-divides > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-divides > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child),
.sx-booking-form .sx-extra-services li .sx-icon-box-lg,
.sx-booking-form .sx-extra-services li.active .sx-icon-box-lg:after,
.searchform input[type=text],
.searchform input[type=submit],
ol.comment-list li.comment .comment-body {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

/*------------------------------------- 
	transition 
-------------------------------------*/
a,
.btn,
.form-control,
.overlay-bx,
.overlay-icon li a i,
.sx-icon-box-xld,
.sx-icon-box-xl,
.sx-icon-box-lg,
.sx-icon-box-md,
.sx-icon-box-sm,
.sx-icon-box-xs,
.share-social-bx li,
.indicator,
.profile-menu ul li,
.staff-member .member-done,
.how-sx-work .sx-box .shadow-bx img,
.how-sx-work .sx-box .step-no-bx,
.sx-info-has,
.sx-we-find .sx-box,
.navbar-toggler,
.logo-header,
.header-nav .nav > li > a,
.extra-nav,
.acod-head .indicator .fa {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.animate-slow {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.animate-mid {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.animate-fast {
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.8s ease-out;
  -o-transition: all 0.8s ease-out;
  -ms-transition: all 0.8s ease-out;
  transition: all 0.8s ease-out;
}

/*------------------------------------- 
	float-clearfix 
-------------------------------------*/
.clearfix::after,
.filter-wrap::after,
.filter-wrap::after,
.sx-icon-box-wraper::after,
ol.commentlist li::after {
  content: "";
  display: table;
  clear: both;
}

/*____Inner pages 
======================================*/
/*------------------------------------- 
	Out Team
-------------------------------------*/
/*------------------------------------- 
	Product block
-------------------------------------*/
.product-block p {
  margin-bottom: 0px;
}

/*======================================
Background Effects
======================================*/
.bg-center {
  background-position: center;
}

.bg-top-left {
  background-position: top left;
}

.bg-top-right {
  background-position: top right;
}

.bg-top-center {
  background-position: top center;
}

.bg-bottom-left {
  background-position: bottom left;
}

.bg-bottom-right {
  background-position: bottom right;
}

.bg-bottom-center {
  background-position: bottom center;
}

.bg-left-center {
  background-position: left;
}

.bg-right-center {
  background-position: right;
}

.bg-auto {
  background-size: auto;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-full-width {
  background-size: 100% auto;
}

.bg-full-height {
  background-size: auto 100%;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-scroll {
  background-attachment: scroll;
}

/*=========================*/
.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav {
  position: absolute;
  margin-top: 0px;
  top: -10px;
  left: -80px;
}

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-prev {
  left: 40px;
  position: relative;
  background-color: #3C4048;
  margin: 0px;
}

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-next {
  left: 5px;
  position: relative;
  top: 40px;
  background-color: #3C4048;
  margin: 0px;
}

@media only screen and (max-width: 800px) {
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav {
    left: auto;
    right: 5px;
    top: auto;
    bottom: -47px;
  }
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-prev {
    left: 0px;
  }
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-next {
    left: 5px;
    top: 0px;
  }
}
/*____Contact Us
======================================*/
.contact-one {
  position: relative;
}

.contact-one .form-control {
  border: 0px;
  border-bottom: 1px solid #000;
  background: none;
  padding-left: 0px;
}

.contact-one .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #3C4048;
  font-size: 14px;
  font-weight: 600;
}

.contact-one .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #3C4048;
  font-size: 14px;
  font-weight: 600;
}

.contact-one .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #3C4048;
  font-size: 14px;
  font-weight: 600;
}

.contact-one .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #3C4048;
  font-size: 14px;
  font-weight: 600;
}

.contact-info .icon-content p {
  word-break: normal;
}

/*____Work carousal
======================================*/
/*====preview inner pages ====*/
.project-carousel {
  position: relative;
  z-index: 1;
}

.project-carousel1 {
  position: relative;
}

.project-carousel1 .image-single-carousel {
  height: 500px;
}

.project-carousel1 .image-single-carousel .sx-info {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  right: 50px;
  bottom: 50px;
  padding: 30px;
  max-width: 360px;
}

.project-carousel1 .image-single-carousel .sx-info:after {
  position: absolute;
  content: "";
  right: 10px;
  bottom: 10px;
  width: 40px;
  height: 40px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.project-carousel1 .image-single-carousel .sx-info .sx-tilte a {
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .project-carousel1 .image-single-carousel .sx-info {
    right: 0px;
    bottom: 0px;
    padding: 30px;
    max-width: 100%;
    margin: 15px;
  }
}
/*====Similar Projects pages ====*/
.similar-projects {
  position: relative;
  z-index: 1;
}

.similar-projects .sx-info {
  margin: 20px;
  background-color: #fff;
  position: relative;
  top: -50px;
}

/*____our-story
=========================*/
/*| line=====================*/
.our-story {
  position: relative;
  z-index: 0;
  display: flex;
  margin-bottom: 30px;
  border-bottom: 1px dashed #404040;
  padding-bottom: 25px;
  background-color: #1d1f26;
  padding: 20px;
}

.our-story:last-child {
  margin-bottom: 0px;
}

.our-story-detail {
  margin-left: 40px;
}

.our-story-time {
  min-width: 30px;
}

.our-story-detail p {
  margin-bottom: 0px;
}

.our-story-time .date {
  display: block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}

.our-story-time .month {
  display: block;
}

.our-story-year {
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid #fff;
  margin-bottom: 0px;
  color: #00ABB3;
}

.experience .our-story {
  position: relative;
  z-index: 0;
  display: flex;
  margin-bottom: 30px;
  border: 1px dashed #ddd;
  padding-bottom: 25px;
  background-color: #fff;
  padding: 20px;
}

@media only screen and (max-width: 640px) {
  .our-story {
    display: block;
  }
  .our-story .our-story-info {
    display: block !important;
    justify-content: inherit !important;
    margin-bottom: 10px;
  }
  .our-story-year {
    border-left: 0px;
    padding-left: 0px;
    margin-left: 0px;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
  }
  .our-story-time {
    min-width: auto;
    display: inline-block;
  }
  .our-story-time .date,
  .our-story-time .month {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }
  .our-story-time .date {
    padding-right: 3px;
  }
  .our-story-detail {
    margin-left: 0px;
  }
}
.btn-filter-wrap,
.btn-filter-wrap2 {
  list-style: none;
  margin: 0px 0 0;
}

.filter-pos-right {
  background-color: #fff;
  padding: 15px;
  margin-top: 0px;
}

.owl-carousel-filter .owl-item {
  opacity: 0;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.owl-carousel-filter .owl-item.active {
  opacity: 1;
}

.owl-carousel-filter .owl-item > .owl-item {
  opacity: 1;
}

.navigation-with-name .owl-nav .owl-prev,
.navigation-with-name .owl-nav .owl-next {
  font-size: 12px;
  border: 1px solid #ddd;
  padding: 5px 20px;
}

.filter-carousal-1-outer {
  position: relative;
}

.filter-pos-right .btn-filter-wrap li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 0 10px;
  cursor: pointer;
}

.filter-pos-right .btn-filter-wrap li:last-child {
  padding-right: 0;
}

.filter-pos-right .btn-filter-wrap li.btn-active {
  color: #00ABB3;
}

.filter-pos-right .btn-filter-wrap li:hover {
  color: #00ABB3;
}

.filter-pos-right .btn-filter-wrap li:after {
  content: "/";
  position: absolute;
  left: -2px;
  top: 2px;
  font-size: 10px;
  opacity: 1;
  color: #000;
}

.filter-pos-right .btn-filter-wrap li:first-child:after {
  display: none;
}

@media only screen and (max-width: 768px) {
  .filter-carousal-1 {
    padding-bottom: 0px;
    padding-top: 20px;
  }
  .filter-pos-right {
    top: 0px;
  }
}
@media only screen and (max-width: 540px) {
  .filter-pos-right {
    position: inherit;
    left: inherit;
    right: inherit;
    top: inherit;
    margin: 0px 0px;
    padding: 15px 15px 10px 15px;
    text-align: left !important;
  }
  .filter-pos-right .btn-filter-wrap li {
    border: 1px solid #fff;
    padding: 0px 8px;
    margin-bottom: 5px;
  }
  .filter-pos-right .btn-filter-wrap li:after {
    display: none;
  }
  .filter-pos-right .btn-filter-wrap {
    float: left;
  }
  .filter-pos-right .btn-filter-wrap li:last-child {
    padding-right: 15px;
  }
  .filter-pos-right .btn-filter-wrap li::after {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .filter-content-left,
  .filter-content-right {
    width: 100%;
    float: none;
    clear: both;
  }
  .filter-content-left .btn-filter-wrap li {
    display: block;
    font-size: 14px;
    padding: 0px 20px 10px 0px;
    float: left;
  }
}
/* =============== Img center icon ==================*/
.img-center-icon .overlay-bx .overlay-icon {
  right: 0px;
  bottom: 0px;
  left: auto;
  top: auto;
  transform: none;
}

.img-center-icon .overlay-bx .overlay-icon a {
  color: #fff;
  background-color: #00ABB3;
}

.img-center-icon .overlay-bx .overlay-icon a:hover {
  color: #000;
}

/* =============== Project Detail ==================*/
.project-detail-outer .project-detail-pic .sx-media img {
  width: 100%;
}

.project-detail-containt p {
  text-align: justify;
}

.product-block ul {
  margin-bottom: 0px;
  list-style: none;
}

.product-block ul li {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.product-block ul li:last-child {
  margin-bottom: 0px;
  border-bottom: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 991px) {
  .project-detail-outer {
    padding: 0px;
  }
  .project-detail-outer2 {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .project-detail-containt {
    padding-bottom: 30px;
    margin-top: 10px;
  }
  .project-detail-containt-2 {
    margin-top: 30px;
  }
}
/*____Modal
=============================================== */
.button-model {
  display: inline-block;
}

.button-model button {
  min-width: 200px;
  text-align: center !important;
  text-transform: uppercase;
  color: #fff;
  opacity: 1;
}

.modal-footer button {
  min-width: inherit;
}

.button-model button.close {
  min-width: inherit;
}

@media only screen and (max-width: 640px) {
  h2.font-45 {
    font-size: 32px;
    line-height: 38px;
  }
  h2 a.font-45 {
    font-size: 32px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 360px) {
  h2.font-45 {
    font-size: 22px;
    line-height: 28px;
  }
  h2 a.font-45 {
    font-size: 22px;
    line-height: 28px;
  }
}
/*-------------------------------------
	Top Part Search Bar
-------------------------------------*/
.site-search-btn {
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  width: 40px;
  display: inline-block;
  text-align: center;
}

.left-side-search a {
  font-size: 16px;
  display: block;
  width: 40px;
  line-height: 40px;
  text-align: center;
}

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #3C4048;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  display: none;
}

#search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  z-index: 999;
  display: block;
}

#search .input-group {
  display: flex;
  justify-content: flex-end;
  flex-wrap: inherit;
  position: relative;
}

#search .input-group:after {
  content: "";
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 22px;
  height: 20px;
  left: -10px;
  bottom: -10px;
  position: absolute;
}

#search .input-group:before {
  content: "";
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  width: 22px;
  height: 20px;
  right: -10px;
  top: -10px;
  position: absolute;
}

#search form {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -51px;
  width: 40%;
  margin-left: 30%;
  font-size: 22px;
  font-weight: 300;
  text-align: left;
  outline: none;
  padding: 10px;
  border-radius: 0px;
  background-color: #282b34;
}

#search input[type=search] {
  width: 100%;
  background: none;
  border: none;
  padding: 0px 12px;
  outline: none;
  color: #fff;
}

#search .search-btn {
  border: none;
  background: none;
  padding: 0px 30px;
  outline: none;
  color: #fff;
}

#search .search-btn i {
  font-size: 24px;
  transform: none;
}

#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  opacity: 1;
  font-size: 27px;
  color: #fff;
  width: 30px;
  height: 30px;
}

#search .close:after,
#search .close:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 32px;
  background-color: #fff;
  right: 20px;
  top: 0px;
}

#search .close:after {
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari */
  transform: rotate(45deg);
}

#search .close:before {
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  -webkit-transform: rotate(-45deg);
  /* Safari */
  transform: rotate(-45deg);
}

#search .close:hover:after,
#search .close:hover:before {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  #search form {
    width: 90%;
    margin-left: 5%;
    font-size: 26px;
  }
  #search input[type=search] {
    padding: 0px 0px 0px 30px;
  }
  #search .search-btn {
    padding: 0px 15px;
  }
  #search .search-btn i {
    font-size: 24px;
  }
}
@media only screen and (max-width: 400px) {
  #search form {
    font-size: 16px;
  }
  #search input[type=search] {
    padding: 0px 0px 0px 10px;
  }
  #search .search-btn {
    padding: 0px 15px;
  }
  #search .search-btn i {
    font-size: 18px;
  }
}
#contact_us_popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  display: none;
}

#contact_us_popup.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  z-index: 999;
  display: block;
}

#contact_us_popup form {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -51px;
  width: 60%;
  margin-left: 20%;
  color: #000;
  border-bottom: 5px solid rgb(0, 0, 0);
  font-size: 40px;
  font-weight: 300;
  text-align: left;
  outline: none;
  padding: 10px;
  border-radius: 0px;
}

#contact_us_popup input[type=search] {
  width: 100%;
  background: none;
  border: none;
  padding: 0px 30px;
}

#contact_us_popup .search-btn {
  border: none;
  background: none;
  padding: 0px 30px;
}

#contact_us_popup .search-btn i {
  font-size: 36px;
}

#contact_us_popup .close {
  position: fixed;
  top: 15px;
  right: 15px;
  opacity: 1;
  font-size: 27px;
  color: #fff;
  width: 30px;
  height: 30px;
}

#contact_us_popup .close:after,
#contact_us_popup .close:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 32px;
  background-color: #000;
  right: 20px;
  top: 0px;
}

#contact_us_popup .close:after {
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari */
  transform: rotate(45deg);
}

#contact_us_popup .close:before {
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  -webkit-transform: rotate(-45deg);
  /* Safari */
  transform: rotate(-45deg);
}

#contact_us_popup .close:hover:after,
#contact_us_popup .close:hover:before {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  #contact_us_popup form {
    width: 90%;
    margin-left: 5%;
    font-size: 26px;
  }
  #contact_us_popup input[type=search] {
    padding: 0px 0px 0px 30px;
  }
  #contact_us_popup .search-btn {
    padding: 0px 15px;
  }
  #contact_us_popup .search-btn i {
    font-size: 24px;
  }
}
@media only screen and (max-width: 400px) {
  #contact_us_popup form {
    font-size: 16px;
  }
  #contact_us_popup input[type=search] {
    padding: 0px 0px 0px 10px;
  }
  #contact_us_popup .search-btn {
    padding: 0px 15px;
  }
  #contact_us_popup .search-btn i {
    font-size: 18px;
  }
}
.sx-quik-search .form-control::-moz-placeholder {
  color: #fff;
}

.sx-quik-search .form-control:-moz-placeholder {
  color: #fff;
}

.sx-quik-search .form-control:-ms-input-placeholder {
  color: #fff;
}

.sx-quik-search .form-control::-webkit-input-placeholder {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .site-search {
    width: 100%;
  }
  .site-search .site-button {
    padding: 0;
  }
  .site-search form {
    padding-left: 0;
  }
}
/*____animated play btn
======================================*/
/*____ANIMATED PLAY BTN____*/
.play-now {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  border-radius: 50%;
  z-index: 10;
  width: 60px;
  height: 60px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  transform-origin: center center;
}

.play-now .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 65px;
  width: 65px;
  text-align: center;
  line-height: 65px;
  background-color: #fff;
  color: #000;
  z-index: 1;
  font-size: 20px;
  padding-left: 5px;
  display: block;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.1);
  -ms-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.1);
  -o-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.1);
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 65px;
  width: 65px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  -webkit-animation: ripple 3s infinite;
  -moz-animation: ripple 3s infinite;
  -ms-animation: ripple 3s infinite;
  -o-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.play-now .ripple:after {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@-webkit-keyframes ripple {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.slider-play-icon .play-now {
  right: 30%;
  left: auto;
  top: 45%;
}

/*================inner pages css=======*/
@media only screen and (max-width: 991px) {
  .m-about-containt {
    padding-top: 0px;
    margin-top: 0px;
  }
  .m-about-containt h2 {
    font-size: 36px;
    line-height: normal;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .m-about {
    padding: 0px;
    margin: 0px;
  }
  .m-about::after {
    display: none;
  }
}
/*-------------------------------------
	7. extra cell position
-------------------------------------*/
/*=============contact Popup =================*/
.contact-slide-hide {
  position: fixed;
  right: 100%;
  top: 0px;
  height: 100%;
  width: 100%;
  background: #fff;
  color: #000;
  z-index: 12;
  overflow-x: hidden;
  border-left: 1px solid #ddd;
}

.contact-nav {
  padding-top: 70px;
  display: flex;
  align-content: center;
  position: relative;
  height: 100%;
}

.contact-nav .contact-nav-form {
  max-width: 991px;
  margin: auto;
}

.contact-nav .contact-nav-info {
  position: relative;
  border: 10px solid #00ABB3;
  padding: 45px;
}

.contact-nav .contact_close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 100%;
  border: none;
  text-align: right;
  font-size: 36px;
}

@media only screen and (max-width: 540px) {
  .contact-slide-hide {
    right: -100%;
    width: 100%;
  }
}
.full-social-bg {
  text-align: left;
}

.full-social-bg ul {
  list-style: none;
}

.full-social-bg li {
  text-align: center;
}

.full-social-bg li {
  display: inline-block;
  margin-right: 3px;
}

.full-social-bg li:last-child {
  margin-right: 0px;
}

.full-social-bg li a {
  background-color: #fff;
  position: relative;
  top: 0px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  color: #000;
  border: 1px solid #000;
}

.full-social-bg li a:hover i {
  color: #fff;
}

.facebook:hover {
  background: #3b5998 !important;
}

.google:hover {
  background: #dd4b39 !important;
}

.instagram:hover {
  background: #517fa4 !important;
}

.tumblr:hover {
  background: #32506d !important;
}

.twitter:hover {
  background: #00aced !important;
}

.youtube:hover {
  background: #bb0000 !important;
}

/*====our ability counter=======*/
.our-ability [class*=col-] .sx-icon-box-wraper {
  position: relative;
}

.our-ability [class*=col-] .sx-icon-box-wraper:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 1px;
  top: 0px;
  right: -15px;
  background-color: #000;
}

.our-ability [class*=col-]:last-child .sx-icon-box-wraper:after {
  display: none;
}

@media only screen and (max-width: 991px) {
  .our-ability [class*=col-] .sx-icon-box-wraper:after {
    display: none;
  }
}
/*-------------------------
	Our Team 1
----------------------------*/
.our-team-1 {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  text-align: center;
  margin-top: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.our-team-1:hover {
  margin-top: -10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}

.our-team-1 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.our-team-1 .profile-image {
  padding: 15% 10% 0;
}

.our-team-1 .profile-image img {
  max-width: 100%;
  vertical-align: top;
  position: relative;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  max-width: 60%;
}

.our-team-1 .figcaption {
  padding: 5% 10% 15%;
}

.our-team-1 h5 {
  font-weight: 400;
  color: #222;
}

.our-team-1 .icons i {
  color: #999;
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
}

.our-team-1 .icons i:hover {
  color: #555;
}

.our-team-1 .icons i a {
  text-decoration: none;
}

/*-------------------------
	Our Team 2
----------------------------*/
.our-team-2 {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.our-team-2:hover {
  margin-top: -10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}

.our-team-2 .profile-image {
  position: relative;
}

.our-team-2 .profile-image img {
  max-width: 100%;
  width: 100%;
}

.our-team-2 .figcaption {
  background-color: #fff;
  padding: 20px;
}

.our-team-2 .figcaption h5 {
  font-weight: 400;
  margin-bottom: 0px;
}

.our-team-2 .icons {
  position: absolute;
  top: -100%;
}

.our-team-2:hover .icons {
  left: 50%;
  top: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.our-team-2:hover .icons a i {
  -webkit-animation: slide-in-elliptic-top-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-top-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}
.our-team-2 .icons i {
  color: #000;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  background-color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
}

.our-team-2 .icons i:hover {
  color: #fff;
  background-color: #222;
}

.our-team-2 .icons i a {
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  .our-team-2:hover {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .our-team-2 {
    margin-top: 0px;
  }
  .our-team-2 .figcaption h5 {
    font-size: 14px;
  }
}
/*=========== Flat Icons ============*/
.icon-font-block {
  display: inline-block;
  width: 24.7%;
  margin-bottom: 2px;
  margin-right: 2px;
  text-align: center;
  vertical-align: top;
  background: #fff;
  padding: 1%;
  border: 1px solid #e7e7e7;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.icon-font-block:hover {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  position: relative;
  z-index: 10;
}

.icon-font-block .flat-icon {
  padding: 10px;
  display: block;
  font-size: 44px;
  line-height: 1;
}

@media only screen and (max-width: 1200px) {
  .icon-font-block {
    width: 32%;
  }
}
@media only screen and (max-width: 767px) {
  .icon-font-block {
    width: 49.5%;
  }
}
@media only screen and (max-width: 620px) {
  .icon-font-block {
    width: 100%;
  }
}
/*____Clients logo grid
======================================*/
.client-grid {
  position: relative;
  overflow: hidden;
}

.client-logo-pic {
  text-align: center;
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
}

.client-logo-pic * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.client-logo-pic:before,
.client-logo-pic:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  background-color: #eef1f2;
  content: "";
  opacity: 0.9;
  z-index: 1;
}

.client-logo-pic:before {
  -webkit-transform: skew(45deg) translateX(-155%);
  transform: skew(45deg) translateX(-155%);
}

.client-logo-pic:after {
  -webkit-transform: skew(45deg) translateX(155%);
  transform: skew(45deg) translateX(155%);
}

.client-logo-pic img {
  backface-visibility: hidden;
  max-width: 100%;
  vertical-align: top;
}

.client-logo-pic div {
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%) scale(0.5);
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  -webkit-box-shadow: 0 0 10px #000000;
  box-shadow: 0 0 10px #000000;
}

.client-logo-pic span {
  background-color: #000000;
  color: #fff;
  font-size: 12px;
  letter-spacing: 4px;
  margin: 0;
  padding: 5px 10px;
  white-space: nowrap;
}

.client-logo-pic:hover > img {
  opacity: 0.5;
}

.client-logo-pic:hover:before {
  -webkit-transform: skew(45deg) translateX(-55%);
  transform: skew(45deg) translateX(-55%);
}

.client-logo-pic:hover:after {
  -webkit-transform: skew(45deg) translateX(55%);
  transform: skew(45deg) translateX(55%);
}

.client-logo-pic:hover div {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.client-logo-media + .tooltip {
  bottom: auto !important;
  top: 50px !important;
  z-index: 10;
}

@media only screen and (max-width: 767px) {
  .client-grid.m-b40 {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 480px) {
  .client-logo-pic {
    padding: 10px;
  }
}
.grid-5 [class*=col-] {
  width: 20%;
}

.grid-4 [class*=col-] {
  width: 25%;
}

.grid-3 [class*=col-] {
  width: 33.33%;
}

.grid-2 [class*=col-] {
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  .grid-5 [class*=col-] {
    width: 25%;
  }
}
@media only screen and (max-width: 1024px) {
  .grid-5 [class*=col-] {
    width: 33.333%;
  }
}
@media only screen and (max-width: 768px) {
  .grid-5 [class*=col-],
  .grid-4 [class*=col-],
  .grid-3 [class*=col-] {
    width: 50%;
  }
}
@media only screen and (max-width: 640px) {
  .grid-5 [class*=col-],
  .grid-4 [class*=col-],
  .grid-3 [class*=col-] {
    width: 100%;
  }
}
.column-grid-4 .container {
  max-width: 100%;
  padding: 0px 80px;
}

.column-grid-5 .container {
  max-width: 100%;
  padding: 0px 80px;
}

@media only screen and (max-width: 480px) {
  .column-grid-4 .container {
    padding: 0px 15px;
  }
  .column-grid-5 .container {
    padding: 0px 15px;
  }
}
.slider-half-part {
  margin-top: -90px;
}

.half-blocks .half-block-content {
  position: relative;
  z-index: 1;
  background-color: #f6f7f8;
}

.half-blocks-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.half-blocks:hover .half-block-content {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.half-blocks:hover .half-block-content .sx-tilte {
  color: #fff !important;
}

.half-blocks:hover .half-block-content .site-button-link {
  color: #fff !important;
}

@media only screen and (max-width: 991px) {
  .slider-half-part {
    margin-top: 80px;
  }
  .slider-half-part .no-col-gap [class*=col-xs-],
  .slider-half-part .no-col-gap [class*=col-sm-],
  .slider-half-part .no-col-gap [class*=col-md-],
  .slider-half-part .no-col-gap [class*=col-lg-] {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 768px) {
  .slider-half-part {
    margin: 30px 0px;
  }
}
/*====our-speciallization====*/
.our-speciallization {
  position: relative;
  z-index: 1;
}

/*____scale images
======================================*/
.scale-bg-top,
.scale-bg-bottom {
  position: relative;
}

.scale-bg-top:before,
.scale-bg-bottom:after {
  content: "";
  position: absolute;
  left: 0px;
  width: 100%;
  height: 50px;
  opacity: 0.4;
}

.scale-bg-top:before {
  /* background-image: url(../images/background/scale-top.png); */
  background-position: top;
  background-repeat: repeat-x;
  top: 0px;
}

.scale-bg-bottom:after {
  /* background-image: url(../images/background/scale-bottom.png); */
  background-position: bottom;
  background-repeat: repeat-x;
  bottom: 0px;
}

.onhover-box-shadow {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.onhover-box-shadow:hover {
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.4);
}

/*____Progress Bar 
-------------------------------------*/
.progress .progress-bar {
  position: relative;
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
}

.progress .tooltip {
  left: 100%;
  z-index: 1;
}

.tooltip {
  z-index: 1 !important;
}

.progress {
  overflow: visible;
}

.sx-probar-10 {
  height: 10px;
}

/*========block-sahdow====*/
.box-shadow {
  -webkit-box-shadow: 0px 0px 5px 0px #dcdcdc;
  box-shadow: 0px 0px 5px 0px #dcdcdc;
}

/*====multiline-animation====*/
.multi-line-animation {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 100vh;
}

@media only screen and (max-width: 991px) {
  .multi-line-animation {
    display: none;
  }
}
.multi-line-one,
.multi-line-two,
.multi-line-three,
.multi-line-four {
  position: absolute;
  bottom: 0px;
  height: 100vh;
  width: 4px;
  z-index: 20;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.multi-line-one {
  left: 20%;
}

.multi-line-two {
  left: 40%;
}

.multi-line-three {
  left: 60%;
}

.multi-line-four {
  left: 80%;
}

.animation-block1 {
  animation-duration: 40s;
  animation-iteration-count: infinite;
  background-color: #2a2a2a;
  height: 10px;
  margin: 0px 0px 0px -5px;
  transform-origin: bottom;
  width: 10px;
}

.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: linear;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
.animation-block2 {
  animation-duration: 80s;
  animation-iteration-count: infinite;
  background-color: #2a2a2a;
  height: 10px;
  width: 10px;
  margin: 0px 0px 0px -5px;
  transform-origin: bottom;
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: linear;
}

@keyframes bounce-2 {
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100vh);
  }
  0% {
    transform: translateY(0);
  }
}
/*====multiline-animation End====*/
.slider-block {
  color: #fff;
}

.hilite-title {
  border: 0px;
}

.hilite-title.hilite-dark strong {
  color: rgba(191, 191, 191, 0.2);
}

.hilite-title strong {
  display: inline-block;
  font-size: 80px;
  line-height: 80px;
  color: rgba(0, 0, 0, 0.1);
  font-weight: 700;
  letter-spacing: 20px;
  transform: rotate(270deg);
  position: absolute;
  right: 80px;
  top: 0;
  white-space: nowrap;
  transform-origin: center right;
}

@media only screen and (max-width: 1340px) {
  .hilite-title {
    display: none;
  }
}
.text-pop-up-top {
  -webkit-animation: text-pop-up-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: text-pop-up-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
}

@-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
/*-------------------------------------
	2. Count Section 
-------------------------------------*/
.icon-count-2-outer {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  overflow: hidden;
  padding: 5px 0px;
}

.icon-count-2-outer .icon-count-2 {
  padding: 100px 30px 30px 30px;
  margin: 0px 5px;
}

.icon-count-2-outer:after {
  position: absolute;
  content: "";
  left: 0px;
  right: 0px;
  bottom: -10px;
  width: 100%;
  height: 50%;
  background-color: #00ABB3;
  opacity: 0.4;
  z-index: -1;
  display: block;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.icon-count-2-outer .icon-count-2 span.icon-count-number {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 80px;
  font-weight: 600;
  color: rgba(177, 177, 177, 0.2);
  line-height: 120px;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.icon-count-2-outer .icon-count-2 span.icon-count-number:after {
  content: "";
  width: 15px;
  height: 2px;
  background-color: #000;
  left: -50%;
  top: 50%;
  position: absolute;
  margin-top: -2px;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.icon-count-2-outer:hover .icon-count-2 span.icon-count-number:after {
  width: 40px;
}

.icon-count-2-outer:hover {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.icon-count-2-outer:hover:after {
  height: 200%;
}

@media only screen and (max-width: 767px) {
  .icon-count-2-outer-section [class*=col-]:last-child .icon-count-2-outer {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-count-2-outer .icon-count-2 {
    margin: 0px;
  }
}
/*====site button effect====*/
.btn-effect {
  letter-spacing: 4px;
  position: relative;
  text-transform: uppercase;
}

.btn-effect:before,
.btn-effect:after {
  border-color: transparent;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  border-style: solid;
  border-width: 0;
  content: "";
  height: 24px;
  position: absolute;
  width: 24px;
}

.btn-effect:before {
  border-color: #c47135;
  border-left-width: 2px;
  border-top-width: 2px;
  left: -5px;
  top: -5px;
}

.btn-effect:after {
  border-bottom-width: 2px;
  border-color: #c47135;
  border-right-width: 2px;
  bottom: -5px;
  right: -5px;
}

.btn-effect:hover:before,
.btn-effect:hover:after {
  height: 100%;
  width: 100%;
}

.site-button-secondry.btn-effect:before,
.site-button-secondry.btn-effect:after {
  border-color: #222;
}

.site-button.btn-effect:before,
.site-button.btn-effect:after {
  border-color: #00ABB3;
}

.site-button:hover.btn-effect:before,
.site-button:hover.btn-effect:after {
  border-color: #222;
}

/*====Our Product Section ====*/
.product-stamp .filter-navigation li {
  display: block;
}

/*====Filter Hover Navigation====*/
.filter-navigation {
  margin-bottom: 0px;
  display: inline-block;
  border-radius: 0px 5px 20px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0px 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}

.filter-navigation * {
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.filter-navigation li {
  display: block;
  float: left;
  list-style: outside none none;
  margin: 0px 0px;
  overflow: hidden;
}

.filter-navigation a.btn {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}

.filter-navigation li a.btn {
  position: relative;
  padding: 15px 15px;
  font-size: 14px;
  color: #000;
  transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
  display: inline-block;
  font-weight: 500;
}

.filter-navigation li.active a {
  color: #00ABB3;
  display: block;
}

.filter-navigation li a.btn:before,
.filter-navigation li a.btn:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: -1;
}

.filter-navigation li a.btn:hover {
  color: #fff;
  transition-delay: 0.6s;
}

.filter-navigation li a.btn:hover:before {
  transition-delay: 0s;
}

.filter-navigation li a.btn:hover:after {
  background: #00ABB3;
  transition-delay: 0.4s;
}

@media only screen and (max-width: 768px) {
  .filter-navigation li a.btn {
    padding: 12px 10px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .filter-navigation li a.btn {
    padding: 12px 16px;
  }
}
@media only screen and (max-width: 640px) {
  .filter-navigation {
    box-shadow: none;
  }
  .filter-navigation li a.btn {
    padding: 8px 5px;
    border-bottom: 1px solid #ddd;
    margin-right: 5px;
    box-shadow: none;
  }
}
/* From Top */
.from-top:before,
.from-top:after {
  left: 0;
  height: 0;
  width: 100%;
}

.from-top:before {
  bottom: 0;
  border: 1px solid #00ABB3;
  border-top: 0;
  border-bottom: 0;
}

.from-top:after {
  top: 0;
  height: 0;
}

.from-top:hover:before,
.from-top:hover:after {
  height: 100%;
}

.from-top.active:before,
.from-top.active:after {
  height: 100%;
}

.filter-navigation li:first-child {
  padding-left: 0px;
  margin-left: 0px;
}

.load-more-btn-outer {
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .load-more-btn-outer {
    margin-top: 30px;
  }
  .load-more-btn-outer button {
    outline: none;
  }
}
/*------------------------
	Hover Image Block
---------------------------*/
.image-hover-block {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #000;
}

.image-hover-block *,
.image-hover-block a i,
.image-hover-block:before,
.image-hover-block:after {
  /* -webkit-box-sizing: border-box;
   box-sizing: border-box;
   -webkit-transition: all 0.4s ease;
   transition: all 0.4s ease; */
}

.image-hover-block img {
  max-width: 100%;
  backface-visibility: hidden;
  vertical-align: top;
}

.image-hover-block .sx-info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 15px 30px 15px 20px;
  width: 100%;
}

.image-hover-block .sx-info .sx-tilte,
.image-hover-block .sx-info p {
  opacity: 0;
}

.image-hover-block a {
  color: #fff;
}

.image-hover-block a.mfp-link {
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 1;
  display: inline-block;
  width: 0%;
}

.image-hover-block a i {
  font-size: 32px;
  color: #fff;
  visibility: hidden;
  margin: -16px 0px 0px -16px;
}

.image-hover-block:hover a i {
  visibility: visible;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.image-hover-block:hover img {
  zoom: 1;
  filter: alpha(opacity=20);
  -webkit-opacity: 0.2;
  opacity: 0.2;
}

.image-hover-block:hover .sx-info .sx-tilte,
.image-hover-block:hover .sx-info p {
  opacity: 1;
}

.image-hover-block:hover .sx-info .sx-tilte {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.image-hover-block:hover .sx-info p {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.image-hover-block:before,
.image-hover-block:after {
  position: absolute;
  top: 20px;
  right: 20px;
  content: "";
  background-color: #fff;
  z-index: 1;
  opacity: 0;
}

/* .image-hover-block:before {
  width: 0;
  height: 1px;
}

.image-hover-block:after {
  height: 0;
  width: 1px;
} */
.image-hover-block:hover:before,
.image-hover-block:hover:after {
  opacity: 1;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.image-hover-block:hover:before {
  width: 100%;
}

.image-hover-block:hover:after {
  height: 100%;
}

.focus-in-animation:hover {
  /* -webkit-animation: focus-in-contract 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: focus-in-contract 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
/*____CONTACT SECTION____*/
.contact-home1-left {
  position: relative;
}

.contact-home1-left .form-control {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(135, 135, 135);
  color: #fff;
  padding-left: 0px;
  text-transform: uppercase;
}

.contact-home1-left .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #878787;
}

.contact-home1-left .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #878787;
}

.contact-home1-left .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #878787;
}

.contact-home1-left .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #878787;
}

/*------------------------
    about-home-right
---------------------------*/
.about-home-left {
  position: relative;
  z-index: 1;
  padding: 30px;
  margin-bottom: 30px;
  border: 20px solid #e8d7cb;
}

.about-home-right {
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
}

.about-home-right .about-home-before {
  position: absolute;
  top: 70px;
  z-index: -1;
  right: 0px;
  max-width: 360px;
  width: 100%;
}

.about-home-right .about-home-before img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .about-home-right .about-home-before {
    top: 60px;
  }
}
@media only screen and (max-width: 640px) {
  .about-home-left .text-right {
    text-align: left !important;
  }
}
.about-home {
  max-width: 369px;
  width: 100%;
}

.about-home img {
  width: 100%;
}

.about-home .owl-carousel .owl-dots {
  position: absolute;
  right: 20px;
  margin-top: 0px;
  top: 50%;
  width: 0px;
  transform: translateX(-50%) translateY(-50%);
}

.about-home .owl-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 3px;
  border: none;
  float: right;
  clear: both;
  position: relative;
}

.about-home .owl-carousel .owl-dots .owl-dot span:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 3px;
  background-color: #000;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.about-home.owl-carousel .owl-dots .owl-dot span {
  background: #444;
}

.about-home.owl-carousel .owl-dots .owl-dot.active span {
  background: #f5be23;
}

.about-home-left.about-right-v2 {
  margin-left: 0px;
  margin-top: 0px;
  min-height: 375px;
}

@media only screen and (max-width: 480px) {
  .about-home-right .about-home-before {
    display: none;
  }
  .about-home {
    max-width: 100%;
    width: 100%;
  }
  .about-home-right {
    padding-bottom: 50px;
  }
}
.home-2-about {
  position: absolute;
  left: 15px;
  bottom: 0px;
  right: 15px;
  top: 0px;
  min-height: 300px;
}

.about-home-2 {
  position: relative;
  z-index: 1;
  padding: 80px 40px 80px 40px;
}

.about-home-2:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 45%;
  height: 100%;
  background-color: transparent;
  border: 20px solid #dfdfdf;
  z-index: -1;
}

.about-home-3 {
  position: relative;
  z-index: 1;
  padding: 30px;
}

@media only screen and (max-width: 991px) {
  .home-2-about {
    position: inherit;
    margin-bottom: 30px;
    left: auto;
    right: auto;
  }
  .about-home-2 {
    position: relative;
    z-index: 1;
    padding: 50px 50px 50px 0px;
  }
}
@media only screen and (max-width: 640px) {
  .about-home-2:after {
    display: none;
  }
  .about-home-2 {
    padding: 0px;
  }
}
/*---------------------------------------------
	Number slider
-----------------------------------------------*/
.number-slider .owl-dots {
  counter-reset: slides-num;
  position: absolute;
  top: 100%;
  left: 0px;
  margin-top: 30px;
}

.number-slider .owl-dots:after {
  content: counter(slides-num);
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  vertical-align: middle;
  padding-left: 0px;
}

.number-slider .owl-dot {
  display: block !important;
  counter-increment: slides-num;
  margin-right: 70px;
}

.number-slider .owl-dot span {
  display: none !important;
}

.number-slider .owl-dot.active:before {
  content: counter(slides-num);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 0;
}

.number-slider .owl-dot.active:after {
  content: "/";
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  left: 16px;
  top: 0;
}

@media only screen and (max-width: 390px) {
  .about-home.number-slider .owl-dots {
    left: 50%;
    right: auto;
    margin-left: -15px;
  }
}
.services-half-section-top {
  padding: 80px 0px 0px;
}

.services-half-section-bottom {
  margin-top: -200px;
  padding-top: 280px;
}

@media only screen and (max-width: 768px) {
  .services-half-section-top {
    padding: 30px 0px;
  }
}
/*-----------------------
	video part
-------------------------*/
.video-section-full-v2 {
  position: relative;
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 50px;
  margin-right: 40px;
}

.video-section-full-v2:after {
  content: "";
  position: absolute;
  top: -40px;
  right: -40px;
  width: 100%;
  height: auto;
  background-color: transparent;
  border: 20px solid #3C4048;
  z-index: 0;
  bottom: -40px;
}

.video-section-full {
  padding: 60px 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  bottom: -120px;
  margin-top: -100px;
}

.video-section-full-v2 .video-section-full {
  padding: 170px 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  bottom: 0px;
  margin-top: 0px;
  margin-left: 0px;
}

.video-section-full .overlay-main {
  z-index: -1;
}

.video-section-full:after,
.video-section-full:before,
.video-section-inner:after,
.video-section-inner:before {
  content: "";
  position: absolute;
  background-color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.video-section-full:after {
  width: 30px;
  height: 2px;
  left: 20px;
  top: 20px;
}

.video-section-full:before {
  width: 2px;
  height: 30px;
  left: 20px;
  top: 20px;
}

.video-section-inner:after {
  width: 30px;
  height: 2px;
  right: 20px;
  bottom: 20px;
}

.video-section-inner:before {
  width: 2px;
  height: 30px;
  right: 20px;
  bottom: 20px;
}

.video-section {
  position: relative;
  z-index: 1;
  margin-right: 31px;
}

.video-section-content {
  padding: 30px 0px;
  width: 100%;
}

.video-section-bottom {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.video-section-bottom .sx-title {
  margin-bottom: 0px;
}

@media only screen and (max-width: 991px) {
  .video-section-full {
    padding: 60px 40px;
    bottom: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .video-section-full-v2 {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 480px) {
  .video-section-full {
    width: 100%;
    margin-bottom: 15px;
  }
  .video-section-full-v2 .video-section-full {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 420px) {
  .video-section-full-v2 .video-section-full {
    padding: 150px 40px;
  }
}
/*------------------------
	Author-info
--------------------------*/
.author-info {
  display: table;
  width: 100%;
}

.author-signature,
.author-name {
  display: table-cell;
  vertical-align: middle;
}

/*--------------------------------
	block number design first
---------------------------------*/
.number-block-one-outer [class*=col-]:nth-child(2) .number-block-one {
  margin-top: 30px;
}

.number-block-one-outer [class*=col-]:nth-child(3) .number-block-one {
  margin-top: 60px;
}

.number-block-one .figcaption {
  position: relative;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.number-block-one .figcaption:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -24px;
  background-color: #000;
  width: 2px;
  height: 24px;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.number-block-one .figcaption-number {
  overflow: hidden;
}

.number-block-one .figcaption-number span {
  font-size: 42px;
  line-height: 42px;
  height: 36px;
  font-weight: 600;
  margin-top: 50px;
  display: block;
}

.number-block-one:hover .figcaption {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.number-block-one:hover .figcaption:after {
  height: 48px;
  bottom: -48px;
}

@media only screen and (max-width: 991px) {
  .number-block-one-outer [class*=col-]:nth-child(2) .number-block-one {
    margin-top: 0px;
  }
  .number-block-one-outer [class*=col-]:nth-child(3) .number-block-one {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .number-block-one-outer [class*=col-]:nth-child(2) .number-block-one {
    margin-top: 0px;
  }
  .number-block-one-outer [class*=col-]:nth-child(3) .number-block-one {
    margin-top: 0px;
  }
  .number-block-one-outer [class*=col-]:nth-child(3) {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 640px) {
  .number-block-one .figcaption-number span {
    font-size: 50px;
    line-height: 50px;
    height: 46px;
  }
}
/*----------------------------------
	Animation Top
-----------------------------------*/
.animate-in-to-top:hover .animate-in-to-top-content {
  -webkit-animation: animate-in-to-top-content 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: animate-in-to-top-content 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes animate-in-to-top-content {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes animate-in-to-top-content {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}
.number-block-two {
  position: relative;
}

.number-block-two .figcaption {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.number-block-two .figcaption:after {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 0px;
  top: 0px;
  height: 2px;
  width: 0px;
  z-index: -1;
  background-color: #00ABB3;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.number-block-two:hover .figcaption:after {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  z-index: -1;
}

.number-block-two .figcaption:before {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0px;
  height: 2px;
  width: 0px;
  z-index: -1;
  background-color: #00ABB3;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.number-block-two:hover .figcaption:before {
  width: 100%;
  right: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  z-index: -1;
}

.number-block-two .figcaption-number {
  overflow: hidden;
  position: absolute;
  right: 20px;
  bottom: 20px;
  opacity: 0.1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -ms-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  -o-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 1px;
  -ms-text-stroke-width: 1px;
  -moz-text-stroke-width: 1px;
  -o-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  -ms-text-stroke-color: #000;
  -moz-text-stroke-color: #000;
  -o-text-stroke-color: #000;
}

.number-block-two .figcaption-number span {
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  display: block;
}

.number-block-two:hover .figcaption {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.number-block-three {
  position: relative;
}

.number-block-three .figcaption {
  position: relative;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.number-block-three .figcaption:after {
  content: "";
  position: absolute;
  left: 0%;
  top: 0px;
  background-color: #000;
  width: 0px;
  height: 2px;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.number-block-three .figcaption-number {
  overflow: hidden;
  position: absolute;
  right: 20px;
  top: -73px;
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.5);
  -ms-text-fill-color: rgba(0, 0, 0, 0.5);
  -moz-text-fill-color: rgba(0, 0, 0, 0.5);
  -o-text-fill-color: rgba(0, 0, 0, 0.5);
  -webkit-text-stroke-width: 1px;
  -ms-text-stroke-width: 1px;
  -moz-text-stroke-width: 1px;
  -o-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  -ms-text-stroke-color: #fff;
  -moz-text-stroke-color: #fff;
  -o-text-stroke-color: #fff;
}

.number-block-three .figcaption-number span {
  font-size: 60px;
  line-height: 60px;
  font-weight: 600;
  display: block;
}

.number-block-three:hover .figcaption {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.number-block-three:hover .figcaption:after {
  width: 100%;
  right: 0%;
}

.slide-ani-top:hover .animate-top-content {
  -webkit-animation: slide-ani-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) alternate-reverse both;
  animation: slide-ani-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) alternate-reverse both;
}

@-webkit-keyframes slide-ani-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
@keyframes slide-ani-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
/*----------------------
	HOVER SHADOW
------------------------*/
.hover-shadow {
  -webkit-box-shadow: 0 0px 0px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 0px 0px 0 rgba(50, 50, 50, 0.16);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.hover-shadow:hover {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  position: relative;
  z-index: 10;
}

/*----------------------
INPUT ANIMATION
------------------------*/
.input-animate.input,
.login-animate.input label,
.login-animate.input input,
.login-animate.input textarea,
.login-animate.input .spin {
  transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input-animate.input input,
.input-animate.input textarea,
.input-animate.input label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}

.input-animate.input {
  margin-bottom: 30px;
  height: 44px;
}

.input-animate.input::before {
  content: "";
  background: #dcdcdc;
  z-index: 3;
}

.input-animate.input input {
  height: 44px;
  top: 10px;
  border: none;
  background: transparent;
}

.input-animate.input textarea {
  height: 40px;
  padding-top: 15px;
  top: 10px;
  border: none;
  background: transparent;
}

.input-animate.input label {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 2;
  cursor: pointer;
  line-height: 40px;
}

.input-animate.input input,
.input-animate.input textarea,
.input-animate.input label {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.input-animate.input::before,
.input-animate.input .spin {
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.input-animate.input,
.input-animate.input input,
.input-animate.input textarea {
  position: relative;
  width: 100%;
  outline: none;
}

.input-animate.input textarea {
  resize: none;
}

.input-animate.input .spin {
  background: #00ABB3;
  z-index: 4;
  width: 0;
}

.input-animate.input,
.input-animate.input label,
.input-animate.input input,
.input-animate.input textarea,
.input-animate.input .spin {
  transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: none;
}

/*-------------------------
Filter Hover Navigation
---------------------------*/
.hover-navigation {
  text-transform: uppercase;
  margin-bottom: 0px;
}

.hover-navigation * {
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.hover-navigation ul {
  border: 1px solid #e7e7e7;
}

.hover-navigation li {
  display: block;
  list-style: outside none none;
  overflow: hidden;
  border-bottom: 1px solid #e7e7e7;
}

.hover-navigation li:last-child {
  border-bottom: none;
}

.hover-navigation li:first-child {
  padding-left: 0px;
  margin-left: 0px;
}

.hover-navigation li:hover {
  -webkit-box-shadow: 0px 4px 7px 0px #ededed;
  box-shadow: 0px 4px 7px 0px #ededed;
}

.hover-navigation a {
  padding: 18px 20px;
  color: #000;
  position: relative;
  display: block;
  letter-spacing: 1px;
  margin: 0px;
  text-decoration: none;
  font-weight: 600;
}

.hover-navigation a:before,
.hover-navigation a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.hover-navigation a:before {
  bottom: 100%;
  display: block;
  height: 2px;
  width: 100%;
  content: "";
  /* background-color: #f5be23;*/
}

.hover-navigation a:after {
  padding: 18px 20px;
  position: absolute;
  bottom: 100%;
  left: 0;
  content: attr(data-hover);
  color: #f5be23;
  white-space: nowrap;
  width: 100%;
}

.hover-navigation li:hover a {
  transform: translateY(100%);
}

/*--------------------
scale-in-center
----------------------*/
.icon-font-block:hover .flat-icon {
  -webkit-animation: bounce-in-fwd 1.1s both;
  animation: bounce-in-fwd 1.1s both;
}

.sx-icon-box-wraper:hover .scale-in-center {
  -webkit-animation: bounce-in-fwd 1.1s both;
  animation: bounce-in-fwd 1.1s both;
}

.hover-animation-1:hover .scale-in-center {
  -webkit-animation: bounce-in-fwd 1.1s both;
  animation: bounce-in-fwd 1.1s both;
}

@-webkit-keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(0.84);
    transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(0.84);
    transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
/*-----------------------------
Image Effect Two
--------------------------------*/
.image-effect-two {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  -webkit-perspective: 800px;
  perspective: 800px;
}

.image-effect-two * {
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.image-effect-two img {
  max-width: 100%;
  width: 100%;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.image-effect-two .figcaption {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: rotateY(90deg) translate(0%, -50%);
  transform: rotateY(90deg) translate(0%, -50%);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  z-index: 1;
  opacity: 0;
  padding: 0 30px;
}

.image-effect-two:hover img,
.image-effect-two.hover img {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  opacity: 0;
}

.image-effect-two:hover .figcaption,
.image-effect-two.hover .figcaption {
  -webkit-transform: rotateY(0deg) translate(0, -50%);
  transform: rotateY(0deg) translate(0, -50%);
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.image-effect-two .mfp-link {
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  background-color: #222;
  margin-left: 10px;
  color: #fff;
}

.image-effect-two .mfp-link:hover {
  background-color: #00ABB3;
  color: #222;
}

/*==== revolution slider Navigation====*/
.home-rev-slider .metis.tparrows::before {
  color: #fff;
}

.home-rev-slider .metis.tparrows {
  background: #3C4048;
}

.home-rev-slider .metis.tparrows:hover {
  background: #fff;
}

.home-rev-slider .metis.tparrows:hover:before {
  color: #3C4048;
}

#rev_slider_26_1 .tp-bannertimer {
  height: 80px;
  background-color: rgba(35, 37, 45, 0.1);
  z-index: 20;
  bottom: 0px;
  position: absolute;
  top: auto;
  left: 0px;
}

@media (max-width: 778px) {
  .rev-slider-white-block {
    display: none !important;
  }
  #rev_slider_26_1 .tparrows.metis {
    width: 40px;
    height: 40px;
  }
  #rev_slider_26_1 .tp-leftarrow {
    -webkit-transform: matrix(1, 0, 0, 1, -100, -50) !important;
    transform: matrix(1, 0, 0, 1, -100, -50) !important;
  }
  #rev_slider_26_1 .tp-leftarrow {
    -webkit-transform: matrix(1, 0, 0, 1, -100, -50) !important;
    transform: matrix(1, 0, 0, 1, -100, -50) !important;
  }
  #rev_slider_26_1 .tparrows::before {
    line-height: 20px;
  }
  #rev_slider_three .tparrows {
    display: none !important;
  }
}
/*--------------------------------
	link underline
-----------------------------------*/
.anchor-line a {
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
  padding-bottom: 3px;
  font-weight: 600;
}

.anchor-line a:hover,
.anchor-line a:focus {
  background-size: 100% 2px;
}

/*--------------------------------
	page-notfound
-----------------------------------*/
.page-notfound strong {
  display: block;
  font-size: 24px;
  margin-bottom: 20px;
}

.page-notfound span.title {
  position: relative;
  display: block;
  color: #00ABB3;
  font-size: 45px;
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 13px 0 14px;
}

.page-notfound p {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .page-notfound {
    text-align: center;
  }
}
/*---------------------------
	contact-form
-----------------------------*/
.get-in-touch-form {
  position: relative;
}

.get-in-touch-form-section {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 520px;
}

.get-in-touch-info {
  position: absolute;
  bottom: 0px;
  max-width: 330px;
  left: 0px;
  box-shadow: -10px -10px #00ABB3;
}

@media only screen and (max-width: 991px) {
  .get-in-touch-form-section {
    max-width: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .mobile-page-padding {
    padding: 30px 0px;
  }
  .mobile-page-padding.p-b10 {
    padding-bottom: 0px;
  }
  .inner-page-padding {
    padding: 30px 0px;
  }
  .mobile-page-padding .row.team-item-four [class*=col-]:nth-of-type(3) {
    margin-bottom: 0px !important;
  }
  .mobile-page-padding .row [class*=col-]:last-child.m-b30 {
    margin-bottom: 0px;
  }
  .mobile-page-padding .row [class*=col-]:last-child .blog-post {
    margin-bottom: 0px;
  }
  .get-in-touch-form-section {
    position: inherit;
    left: auto;
    bottom: auto;
    margin: 0px auto;
  }
  .get-in-touch-info {
    position: relative;
  }
}
@media only screen and (max-width: 576px) {
  .mobile-page-padding .row.team-item-four [class*=col-]:nth-of-type(3) {
    margin-bottom: 30px !important;
  }
}
@media only screen and (max-width: 991px) {
  .service-about-left {
    text-align: center;
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .service-about-right h3 {
    font-size: 22px;
    line-height: inherit;
  }
  .faq-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.faq-list div:last-child p {
  margin-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .faq-list {
    margin-bottom: 30px;
  }
}
/*-----------------------------------------------
	boxed style
--------------------------------------------------*/
/*main bar with box view*/
.boxed .is-fixed .main-bar {
  max-width: 1200px;
  margin: 0 auto !important;
  left: inherit !important;
}

@media only screen and (max-width: 1200px) {
  .boxed .is-fixed .main-bar {
    margin: 0;
    left: 0;
  }
}
.boxed .site-header .container {
  width: auto;
}

#bg {
  background-attachment: fixed;
  background-size: cover;
}

.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.boxed .tp-banner-container,
.boxed .rev_slider_wrapper {
  left: 0 !important;
  width: 100% !important;
}

.boxed .tp-rightarrow {
  right: 0 !important;
}

.boxed .tp-leftarrow {
  left: 0 !important;
}

/*--------------------------------------
	slide-left-social
----------------------------------------*/
.slide-left-social {
  position: absolute;
  bottom: 30px;
  left: 40px;
  z-index: 20;
}

.slide-left-social ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.slide-left-social ul li {
  float: left;
  margin-right: 60px;
  position: relative;
}

.slide-left-social ul li:last-child {
  margin-right: 0px;
}

.slide-left-social ul li:after {
  content: "";
  position: absolute;
  width: 36px;
  height: 1px;
  right: -46px;
  top: 50%;
  margin-top: 1px;
  background-color: #000;
}

.slide-left-social ul li:last-child:after {
  display: none;
}

.slide-left-social ul li a {
  color: #000;
  font-size: 10px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: 600;
}

@media (max-width: 778px) {
  .slide-left-social {
    display: none;
  }
}
/*-------------------------
	Title Hover
---------------------------*/
.sx-title-swip:before,
.sx-title-swip:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.sx-title-swip:before {
  bottom: 0;
  display: block;
  height: 2px;
  width: 0%;
  content: "";
  background-color: #00ABB3;
}

.sx-title-swip:after {
  left: 0px;
  top: 4px;
  padding: 0px 0px 8px 0px;
  position: absolute;
  content: attr(data-hover);
  white-space: nowrap;
  max-width: 0%;
  overflow: hidden;
}

.sx-title-swip:hover:before {
  opacity: 1;
  width: 100%;
}

.sx-title-swip:hover:after {
  max-width: 100%;
}

/*---------------------------------------------
	right-half-bg-image
-----------------------------------------------*/
.right-half-bg-image-outer {
  position: relative;
  z-index: 1;
}

.right-half-bg-image {
  position: absolute;
  width: 2600px;
  height: 130%;
  left: calc(100% + 15px);
  top: -100px;
  z-index: 9;
}

@media only screen and (max-width: 1136px) {
  .right-half-bg-image {
    display: none;
  }
}
/*---------------------------------------------
	counter-blocks
-----------------------------------------------*/
.counter-blocks .sx-icon-box-wraper:hover {
  background-color: #00ABB3;
  color: #000;
}

.counter-blocks .sx-icon-box-wraper:hover .sx-text-primary {
  color: #000;
}

.counter-blocks .st-count-number {
  position: relative;
  display: inline-block;
  padding: 20px 0px 11px 10px;
  z-index: 1;
}

.counter-blocks .st-count-number:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 75px;
  height: 100%;
  border: 4px solid #efefef;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .counter-blocks-2 [class*=col-]:last-child .sx-count {
    margin-bottom: 0px;
  }
}
/*------------------------------------
	left-slide-animation
-------------------------------------*/
.left-slide-animation:hover img {
  -webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-hor-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
@keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/*------------------------------
Image effect one
--------------------------------*/
.image-effect-one {
  background-color: #000;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}

.image-effect-one * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.image-effect-one:before,
.image-effect-one:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  background-color: #000000;
  content: "";
  opacity: 0.6;
  z-index: 1;
}

.image-effect-one:before {
  -webkit-transform: skew(40deg) translateX(-155%);
  transform: skew(40deg) translateX(-155%);
}

.image-effect-one:after {
  -webkit-transform: skew(40deg) translateX(155%);
  transform: skew(40deg) translateX(155%);
}

.image-effect-one img {
  backface-visibility: hidden;
  max-width: 100%;
  width: 100%;
  vertical-align: top;
}

.image-effect-one .figcaption {
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%) scale(0.5);
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  -webkit-box-shadow: 0 0 10px #000000;
  box-shadow: 0 0 10px #000000;
}

.image-effect-one .mfp-link {
  background-color: #000000;
  border: 2px solid #00ABB3;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  padding: 5px 10px;
  text-transform: uppercase;
}

.image-effect-one:hover > img {
  opacity: 0.5;
}

.image-effect-one:hover:before {
  -webkit-transform: skew(40deg) translateX(-55%);
  transform: skew(40deg) translateX(-55%);
}

.image-effect-one:hover:after {
  -webkit-transform: skew(40deg) translateX(55%);
  transform: skew(40deg) translateX(55%);
}

.image-effect-one:hover .figcaption {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.project-mas p {
  margin-bottom: 0px;
}

/*=========== bg-change-section ============*/
.bg-change-section {
  position: relative;
}

.bgcall-block {
  height: 500px;
  overflow: hidden;
  position: relative;
  padding: 30px;
  z-index: 1;
  cursor: pointer;
  background-position: center;
}

.bgcall-block-number {
  position: absolute;
  font-size: 120px;
  font-weight: 700;
  opacity: 0.2;
  color: #fff;
  line-height: 1;
  transition: all 0.3s ease;
  top: -50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.bgcall-block:hover .bgcall-block-number {
  top: 42px;
  opacity: 1;
}

.bg-overlay {
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  width: auto;
  height: auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
}

.bgcall-block:hover .bg-overlay {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

.bgcall-block .site-button-link:hover {
  color: #fff;
}

.bgcall-block .icon-content {
  padding: 0px 20px 0px 20px;
}

/*-----------------------------------------------
	Team Style 2
-------------------------------------------------*/
.our-team-3 {
  position: relative;
}

.our-team-3 .our-team-info {
  position: relative;
  overflow: hidden;
  background: #3C4048;
}

.our-team-3 .our-team-info img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
}

.our-team-3 .our-team-info .our-team-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.our-team-3 .our-team-info .our-team-content a {
  color: #fff;
}

.our-team-3 .our-team-info .our-team-content a:hover {
  color: #00ABB3;
}

.our-team-info {
  background: #34495e;
}

.our-team-info .our-team-content {
  padding: 30px 15px 30px 30px;
}

.our-team-info .our-team-content:before,
.our-team-info .our-team-content:after {
  position: absolute;
  width: 200%;
  height: 200%;
  border-style: solid;
  border-color: #3C4048;
  content: "";
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  z-index: -1;
}

.our-team-info .our-team-content:before {
  right: -1px;
  bottom: -1px;
  border-width: 0 50px 96px 0;
  -webkit-transform: translate3d(50px, 96px, 0);
  transform: translate3d(50px, 96px, 0);
}

.our-team-info .our-team-content:after {
  top: 0;
  left: 0;
  border-width: 20px 0 0 20px;
  -webkit-transform: translate3d(-20px, -20px, 0);
  transform: translate3d(-30px, -30px, 0);
}

.our-team-info .sx-team-name {
  position: absolute;
  bottom: 50px;
  left: 20px;
  right: 20px;
  margin: 0px;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.our-team-info .sx-team-position {
  position: absolute;
  bottom: 15px;
  left: 20px;
  margin: 0px;
  right: 20px;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.our-team-info p {
  float: right;
  clear: both;
  text-align: left;
  text-transform: none;
}

.our-team-info p a {
  display: block;
  color: #fff;
  z-index: 10;
  opacity: 0;
  -webkit-transform: translate3d(90px, 0, 0);
  transform: translate3d(90px, 0, 0);
}

.our-team-info p a:hover,
.our-team-info p a:focus {
  color: #00ABB3;
}

.our-team-info:hover .our-team-content::before,
.our-team-info:hover .our-team-content::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.our-team-info:hover img {
  opacity: 0.6;
}

.our-team-info:hover .sx-team-name {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.our-team-info:hover .sx-team-position {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.our-team-info:hover p a {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.our-team-info:hover p a:first-child {
  -webkit-transition-delay: 0.025s;
  transition-delay: 0.025s;
}

.our-team-info:hover p a:nth-child(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.our-team-info:hover p a:nth-child(3) {
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

.our-team-info:hover p a:nth-child(4) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

@media only screen and (max-width: 767px) {
  .our-team-info .sx-team-name {
    font-size: 20px;
    line-height: inherit;
    font-weight: 500;
  }
  .our-team-info .sx-team-position {
    font-weight: 500;
  }
}
/*--------------------------------------------
large-title-block
----------------------------------------------*/
.large-title-block {
  margin-bottom: 0px;
  margin-top: 0px;
}

.large-title-block.full-content {
  padding: 30px;
  margin-bottom: 50px;
}

.large-title-block.full-content2 {
  padding-top: 56px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .large-title h3 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .large-title-block.full-content {
    padding-top: 30px;
    margin-bottom: 0px;
    margin-top: 30px;
  }
  .large-title-block.full-content2 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .large-title-block {
    margin-bottom: 20px;
  }
}
/*---------------------------------------------
	Team Single
----------------------------------------------*/
.team-single-social ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.team-single-social ul li span:first-child {
  padding-right: 30px;
}

.team-single-social ul li span i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  color: #3C4048;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.about-single-team-info .our-story-year {
  border-left: 1px solid #3C4048;
}

@media only screen and (max-width: 767px) {
  .about-single-team-info {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .about-single-team-info .our-story {
    display: block;
  }
  .about-single-team-info .our-story .our-story-info {
    display: block !important;
    justify-content: inherit !important;
  }
  .about-single-team-info .our-story-year {
    border-left: 0px;
    padding-left: 0px;
    margin-left: 0px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }
  .about-single-team-info .our-story-time {
    min-width: auto;
    display: inline-block;
  }
  .about-single-team-info .our-story-time .date,
  .about-single-team-info .our-story-time .month {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }
  .about-single-team-info .our-story-time .date {
    padding-right: 3px;
  }
  .about-single-team-info .our-story-detail {
    margin-left: 0px;
  }
}
/*-------------------------------
	Our History
----------------------------------*/
.our-history .our-history-pic {
  height: 400px;
  margin-right: 20%;
  margin-bottom: 120px;
  position: relative;
  z-index: 1;
}

.our-history .pic-bg-border {
  position: relative;
}

.our-history .pic-bg-border:after {
  content: "";
  width: 50%;
  height: 350px;
  position: absolute;
  right: 15px;
  bottom: 40px;
  border: 30px solid #f0f0f0;
  background-image: url(../images/background/cross-line2.png);
}

.our-history .our-history-content {
  margin-left: 20%;
  margin-bottom: 50px;
  text-align: left;
}

.our-history .row:nth-child(even) .our-history-pic {
  margin-left: 20%;
  margin-right: 0%;
}

.our-history .row:nth-child(even) .our-history-content {
  margin-left: 0%;
  margin-right: 20%;
  text-align: left;
}

.our-history .row:nth-child(even) .pic-bg-border:after {
  right: auto;
  left: 15px;
}

@media only screen and (max-width: 991px) {
  .our-history .our-history-content {
    margin-left: 0%;
    text-align: left;
  }
  .our-history .row:nth-child(even) .our-history-content {
    margin-left: 0%;
    margin-right: 0%;
    text-align: left;
  }
}
@media only screen and (max-width: 767px) {
  .our-history .our-history-pic {
    margin-right: 0%;
    margin-bottom: 30px;
    background-position: 0px !important;
  }
  .our-history .pic-bg-border:after {
    display: none;
  }
  .our-history .row:nth-child(even) .our-history-pic {
    margin-left: 0%;
    margin-right: 0%;
  }
  .our-history .our-history-content {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .our-history .row:last-child .our-history-content.m-b30,
  .our-history .row:last-child .our-history-content p {
    margin-bottom: 0px;
  }
}
/*--------------------------------
	project-carousel3
---------------------------------*/
.project-carousel3 .project-mas {
  margin: 0px;
}

@media only screen and (max-width: 767px) {
  .project-carousel3 {
    padding: 0px;
  }
  .project-carousel3 .project-mas.hover-shadow {
    box-shadow: none;
  }
}
/*--------------------------------
	GET IN TOUCH BTN
---------------------------------*/
.get-in-touch-btn {
  position: relative;
  padding: 15px 15px;
  font-size: 14px;
  color: #000;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
  display: inline-block;
  font-weight: 600;
  border: 1px solid #eef1f2;
}

.get-in-touch-btn:before,
.get-in-touch-btn:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: -1;
}

.get-in-touch-btn:hover {
  color: #fff;
  transition-delay: 0.6s;
}

.get-in-touch-btn:hover:before {
  transition-delay: 0s;
}

.get-in-touch-btn:hover:after {
  background: #3C4048;
  transition-delay: 0.4s;
}

/* From Top */
.get-in-touch-btn.from-top:before,
.get-in-touch-btn.from-top:after {
  left: 0;
  height: 0;
  width: 100%;
}

.get-in-touch-btn.from-top:before {
  bottom: 0;
  border: 1px solid #3C4048;
  border-top: 0;
  border-bottom: 0;
}

.get-in-touch-btn.from-top:after {
  top: 0;
  height: 0;
}

.get-in-touch-btn.from-top:hover:before,
.get-in-touch-btn.from-top:hover:after {
  height: 100%;
}

.get-in-touch-btn.from-top.active:before,
.get-in-touch-btn.from-top.active:after {
  height: 100%;
}

@media only screen and (max-width: 480px) {
  .get-in-touch-btn {
    padding: 5px 12px;
    font-size: 10px;
  }
}
/*=======================
	after before slider
========================*/
.rs-addon-beforeafter-btn i {
  color: #fff;
  background-color: #2e313b;
  padding: 0px 10px;
}

/*=================================================================================================================================
New Menu Style for mobile device (august/2021)
==================================================================================================================================*/
@media only screen and (max-width: 991px) {
  .header-nav .nav > li > a {
    padding: 10px 15px;
  }
  /*Header Style 1*/
  .header-style-1 .header-nav .nav {
    float: none;
    margin: 0px;
    width: 100%;
    display: block;
    height: 100%;
  }
  .header-style-1 .header-nav .nav > li > a {
    height: 50px;
  }
  /* .header-style-1 .navbar-collapse.collapse {display: inherit !important;}
  .header-style-1 .navbar-collapse.collapse.show {display: inherit !important;} */
  .header-style-1 .header-nav {
    margin: 0px;
  }
  .header-style-1 .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none;
  }
  .header-style-1.mobile-sider-drawer-menu .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }
  .header-style-1.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu {
    transition: none;
  }
  .header-style-1.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }
  .header-style-1.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }
  .header-style-1.mobile-sider-drawer-menu .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }
  .header-style-1.mobile-sider-drawer-menu #mobile-side-drawer {
    position: relative;
    z-index: 1;
  }
  .header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }
  .header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }
  .header-style-1.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }
  .header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }
  .header-style-1.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
  .header-style-1.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
  /*Header Style 3*/
  .header-style-3 .header-nav .nav {
    float: none;
    margin: 0px;
    width: 100%;
    display: block;
    height: 100%;
  }
  .header-style-3 .header-nav .nav > li > a {
    height: 50px;
  }
  .header-style-3 .navbar-collapse.collapse {
    display: inherit !important;
  }
  .header-style-3 .navbar-collapse.collapse.show {
    display: inherit !important;
  }
  .header-style-3 .header-nav {
    margin: 0px;
  }
  .header-style-3 .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none;
  }
  .header-style-3.mobile-sider-drawer-menu .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }
  .header-style-3.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu {
    transition: none;
  }
  .header-style-3.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }
  .header-style-3.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }
  .header-style-3.mobile-sider-drawer-menu .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }
  .header-style-3.mobile-sider-drawer-menu #mobile-side-drawer {
    position: relative;
    z-index: 1;
  }
  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }
  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }
  .header-style-3.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }
  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }
  .header-style-3.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
  .header-style-3.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
  /*Header transparent*/
  .nav-transparent .header-nav .nav {
    float: none;
    margin: 0px;
    width: 100%;
    display: block;
    height: 100%;
  }
  .nav-transparent .header-nav .nav > li > a {
    height: 50px;
  }
  .nav-transparent .navbar-collapse.collapse {
    display: inherit !important;
  }
  .nav-transparent .navbar-collapse.collapse.show {
    display: inherit !important;
  }
  .nav-transparent .header-nav {
    margin: 0px;
  }
  .nav-transparent .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none;
  }
  .nav-transparent.mobile-sider-drawer-menu .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }
  .nav-transparent.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu {
    transition: none;
  }
  .nav-transparent.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }
  .nav-transparent.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }
  .nav-transparent.mobile-sider-drawer-menu .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }
  .nav-transparent.mobile-sider-drawer-menu #mobile-side-drawer {
    position: relative;
    z-index: 1;
  }
  .nav-transparent.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }
  .nav-transparent.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }
  .nav-transparent.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }
  .nav-transparent.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }
  .nav-transparent.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
  .nav-transparent.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
}
/*================================================================================
	Shop Page Css Start
=================================================================================*/
.list-grid-control a {
  display: inline-block;
  padding-right: 5px;
  color: #b0b0b0;
  font-size: 18px;
}

.list-grid-control a.active {
  color: #000;
}

@media only screen and (max-width: 640px) {
  .product-filter-wrap.d-flex {
    display: block !important;
    position: relative;
    padding-bottom: 50px;
  }
  .list-grid-control {
    max-width: 150px;
    display: inline-block;
  }
}
/* Product Box */
.sx-product-box {
  position: relative;
}

.shop-pro-sale-bnr {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 16px;
  background-color: #00CC00;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  z-index: 1;
  padding: 2px 10px;
}

.shop-pro-size-list {
  display: flex;
  flex-wrap: wrap;
}

.shop-pro-size-list span {
  display: block;
  margin-right: 5px;
}

.shop-pro-size-list ul {
  list-style: none;
  margin-bottom: 0px;
}

.shop-pro-size-list ul li {
  display: inline-block;
  padding-right: 2px;
}

.shop-pro-size-list ul li button {
  background: none;
  border: 1px solid #ddd;
  outline: none !important;
  padding: 2px 10px;
  font-size: 12px;
}

.sx-product-box .price {
  padding-top: 10px;
  display: block;
}

.sx-product-box .price del {
  font-size: 14px;
  color: #a1a1a1;
  font-weight: 500;
}

.sx-product-box .price ins {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  color: #569bb3;
  font-weight: 600;
}

.sx-product-box .sx-thum-bx {
  border: 1px solid #eef1f2;
}

.sx-product-box .sx-thum-bx img {
  display: block;
  margin: 0px auto;
}

.sx-product-box .item-cart-view {
  position: absolute;
  bottom: 0px;
  left: 50%;
  opacity: 1;
  transform: scaleY(0) translateX(-50%);
  transition: transform 0.5s cubic-bezier(0.57, 0.04, 0.06, 0.84), opacity 0s 0.5s;
}

.sx-product-box .item-cart-view .item-cart-btn {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.sx-product-box .item-cart-view .item-cart-btn i {
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  display: block;
  text-align: center;
  background-color: #eef1f2;
  position: relative;
  color: #3C4048;
}

.sx-product-box .rating-bx {
  padding-top: 10px;
  color: red;
}

.sx-product-box:hover .item-cart-view {
  opacity: 1;
  z-index: 3;
  transform: scaleY(1) translateX(-50%);
}

/* Product Detail Page Slider */
.shop-item-price-slider {
  display: flex;
  white-space: nowrap;
}

.shop-item-price-slider b {
  font-weight: 500;
  color: #569bb3;
  font-size: 16px;
}

.shop-item-price-slider .slider.slider-horizontal {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.shop-item-price-slider .slider.slider-horizontal .slider-track {
  height: 3px;
  background-color: #3C4048;
  background-image: none;
  margin-top: 0px;
  margin-left: 0px;
}

.shop-item-price-slider .slider.slider-horizontal .slider-handle {
  background-color: #7f7f7f;
  background-image: none;
  width: 5px;
  height: 12px;
  top: 5px;
  margin-left: 0px;
}

/*select-box-border-style1*/
select {
  appearance: none;
  /* background-image: url(...); */
  padding: 10px 30px 10px 10px;
  background-color: #fff;
}

.select-box-border-style1 {
  border: 1px solid #ddd;
}

.select-box-border-style1 .btn {
  padding: 8px 20px;
  background-color: #fff;
}

.select-box-border-style1-wrapper .btn-light.dropdown-toggle {
  box-shadow: none;
  outline: none;
  background: none;
  border: none;
}

.select-box-border-style1-wrapper .btn-light.dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none;
  border: none;
  outline-offset: inherit;
}

form.woocommerce-ordering {
  position: relative;
}

form.woocommerce-ordering:after {
  position: absolute;
  right: 10px;
  top: 10px;
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 12px;
  color: #000;
}

@media only screen and (max-width: 640px) {
  form.woocommerce-ordering {
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
}
/*____ON SHOW SLIDER____*/
.on-show-slider {
  cursor: pointer;
}

.on-show-slider #sync2 .owl-item.current {
  position: relative;
  z-index: 1;
}

.on-show-slider #sync2 .owl-item.current:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 2px solid #ff5e14;
  z-index: 2;
}

.overlay-icon-1 {
  list-style: none;
  position: absolute;
  right: 50%;
  bottom: 50%;
  z-index: 1 !important;
  -webkit-transform: translateX(50%) translateY(50%);
  -ms-transform: translateX(50%) translateY(50%);
  transform: translateX(50%) translateY(50%);
}

.overlay-icon-1 a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
  color: #fff;
  background-color: transparent !important;
  border: 3px solid;
}

.overlay-icon-1 .sx-icon-box-xs {
  line-height: 40px;
}

/*Product Single*/
.product-detail-info {
  padding: 0px;
}

.site-cart-btn {
  letter-spacing: 0px;
  padding: 10px 15px;
}

.site-cart-btn i {
  padding-right: 5px;
}

.mt-product-gallery {
  border: 1px solid #ddd;
  padding-bottom: 1px;
}

.p-single-old-price {
  color: #777777;
  font-size: 17px;
  text-decoration: line-through;
  font-weight: 500;
  padding-right: 10px;
}

.p-single-new-price {
  font-size: 26px;
  color: #569bb3;
  font-weight: 700;
}

.product-single-availability {
  margin-bottom: 15px;
  margin-top: 15px;
  color: #979797;
  font-weight: 600;
  letter-spacing: 1px;
}

.product-single-availability span {
  color: #484848;
  font-weight: 500;
}

.product-single-rating {
  margin-bottom: 15px;
  color: #ffa700;
}

.product-single-price {
  margin-bottom: 30px;
}

.wt-product-text {
  margin-bottom: 30px;
}

.product_meta {
  text-transform: uppercase;
}

.product_meta a {
  color: #979797;
  font-weight: 600;
  letter-spacing: 1px;
}

.product_meta a:hover {
  color: red;
}

.product_meta .sku_wrapper {
  color: #979797;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 7px;
}

.product_meta .posted_in {
  color: #979797;
  font-weight: 600;
  letter-spacing: 1px;
}

.btn-quantity {
  max-width: 40px;
  width: 100%;
  margin-right: 30px;
  display: inline-block;
}

.btn-quantity .form-control {
  height: 42px;
  background-color: #eef1f2;
}

/*touchspin input type number*/
.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-left: -1px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  background: #eef1f2;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  border-top-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
  border-bottom-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 9px;
  font-weight: normal;
  color: #000;
}

/*Product List Style*/
.shop-item-list-wrap {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.shop-item-list-wrap .sx-product-box .sx-thum-bx {
  border: 1px solid #eef1f2;
  max-width: 170px;
  float: left;
}

.shop-item-list-wrap .sx-info {
  overflow: hidden;
  text-align: left !important;
  padding-left: 20px;
}

@media only screen and (max-width: 640px) {
  .shop-item-list-wrap .sx-product-box .sx-thum-bx {
    max-width: 100%;
    float: none;
    margin-bottom: 30px;
  }
  .shop-item-list-wrap .sx-info {
    padding-left: 0px;
  }
}
/* widget archive*/
.widget_archives ul {
  list-style: none;
  margin-bottom: 0;
}

.widget_archives ul li {
  margin-bottom: 13px;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
  padding: 15px 10px 15px 15px;
  margin-bottom: 0;
  line-height: 20px;
}

.widget_archives ul li:last-child {
  border-bottom: 0px;
}

.widget_archives ul li {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.widget_archives ul li a {
  color: #000;
  display: block;
}

.widget_archives ul li a span {
  text-align: right;
  display: block;
  float: right;
}

.widget_archives ul li a:hover {
  color: #00ABB3;
}

.widget_archives li {
  border-bottom: none;
  line-height: 28px;
}

.widget_archives li:before {
  top: 0;
  left: 1px;
}

.widget_archives.inline-links li {
  display: block;
  border-bottom: none;
}

/*pagination style-1 */
.pagination-st-1 {
  padding: 10px 0px 25px 0px;
  display: inline-flex;
  list-style: none;
}

.pagination-st-1 > li:first-child > a {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
}

.pagination-st-1 > li:last-child > a {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pagination-st-1 > li > a {
  background-color: #fff;
  color: #000;
  font-weight: 600;
  font-size: 14;
  padding: 10px 15px;
}

.pagination-st-1 > li > a:hover,
.pagination-st-1 > li > a:focus {
  color: #767676;
  border-color: transparent;
}

.pagination-st-1 > .active > a,
.pagination-st-1 > .active > a:hover,
.pagination-st-1 > .active > a:focus {
  color: #767676;
  border-color: transparent;
}

.pagination-st-1 > .previous > a,
.pagination-st-1 > .next > a {
  font-size: 12px;
}

@media only screen and (max-width: 991px) {
  .pagination-st-1 {
    padding: 10px 0px 0px 0px;
  }
}
.product-full-detail .sx-tabs .nav-tabs {
  border: 0px;
}

.product-full-detail .sx-tabs .nav-tabs > li {
  border: 0px;
}

.product-full-detail .sx-tabs .nav-tabs > li a.active {
  background-color: #f1f1f1;
  color: #000;
  border: 1px solid #ddd;
  border-bottom: 0px;
}

.product-full-detail .sx-tabs .nav-tabs > li > a {
  color: #000;
  background-color: #fff;
  font-size: 16px;
  padding: 15px;
  border: 0px;
  display: block;
  font-weight: 600;
  border: 1px solid transparent;
  border-bottom: 0px;
}

.product-full-detail .sx-tabs .nav-tabs {
  border: 0px;
}

.product-full-detail .tab-content {
  padding: 0px 20px;
  border: 1px solid #ddd;
}

.product-full-detail table td {
  padding: 10px 10px 10px 0px !important;
  border: 0px;
}

/*shopping-table*/
.shopping-table .cf th {
  color: #060000;
  border-bottom: 0px;
}

.shopping-cart-total .sub_total {
  position: relative;
  padding: 20px 30px;
  background: #eef1f2;
  border-radius: 3px;
  max-width: 600px;
  margin-left: auto;
}

.shopping-cart-total .sub_total .top {
  position: relative;
  overflow: auto;
  list-style: none;
}

.shopping-cart-total .sub_total .top {
  margin-bottom: 0px;
}

.shopping-cart-total .sub_total .top li {
  float: left;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.shopping-cart-total .shipping {
  position: relative;
  padding: 20px 0px;
  border-bottom: 1px solid #d0d0d0;
}

.shopping-cart-total .shipping h2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.shopping-cart-total .shipping ul {
  margin-bottom: 0px;
}

.shopping-cart-total .shipping ul li {
  padding-left: 0;
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}

.shopping-cart-total .shipping ul li span {
  float: right;
}

.shopping-cart-total .sub_total .top li:last-child {
  float: right;
  color: #569bb3;
  font-size: 14px;
}

.shopping-cart-total .total {
  position: relative;
  padding-top: 20px;
}

.shopping-cart-total .total ul {
  margin-bottom: 0px;
}

.shopping-cart-total .total ul li {
  display: inline-block;
  width: 49%;
  font-size: 20px;
  line-height: 20px;
  color: #362f62;
  font-weight: 500;
}

.shopping-cart-total .total ul li:last-child {
  text-align: right;
  color: #569bb3;
  font-size: 20px;
  float: right;
}

.wt-responsive-table td,
.wt-responsive-table th {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .shopping-cart-total .sub_total {
    margin: 0px;
  }
  .proceed-to-checkout.text-right {
    text-align: left !important;
  }
}
/*Checkout*/
.your-order-list {
  border: 1px solid #ddd;
}

.your-order-list ul {
  list-style: none;
  margin-bottom: 0px;
}

.your-order-list ul li {
  padding: 15px 10px;
  clear: both;
  border-bottom: 1px solid #eee;
  color: #999;
}

.your-order-list ul li:last-child {
  border-bottom: none;
  text-transform: uppercase;
  color: #000;
}

/*checkour form select box*/
.selectpicker + .dropdown-toggle {
  position: relative;
  font-size: 12px;
  background: none;
  line-height: 24px;
  color: #6e6e6e;
  border: 1px solid #e1e6eb;
}

.selectpicker + .dropdown-toggle:focus {
  position: relative;
  outline: none !important;
  box-shadow: none;
}

.selectpicker + .dropdown-toggle:after {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 8px;
  width: 0px;
  height: 0px;
  content: "";
  border-top: 6px solid #b6b6b6;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
}

/*dialog box*/
.sign-in-dialog {
  padding: 0px;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
}

.login-forgot-password {
  text-align: right;
}

.sign-in-dialog .tab-content {
  background-color: #f6f6f6;
  padding: 20px;
}

.sign-in-dialog .tab-content .tab-content {
  background-color: #fff;
  padding: 20px;
}

.sign-in-dialog .wt-tabs .tab-pane {
  padding: 30px;
}

@media only screen and (max-width: 420px) {
  .sign-in-dialog .wt-tabs .tab-pane {
    padding: 0px;
  }
}
.sign-in-dialog .wt-tabs .nav-tabs > li {
  margin-bottom: -2px;
}

.sign-in-dialog .wt-tabs .nav-tabs > li > a {
  border: 0px;
  border-bottom: 0px solid transparent;
  padding: 20px 30px;
  display: block;
}

.sign-in-dialog .wt-tabs .nav-tabs > li > a:hover {
  border: 0px;
}

.sign-in-dialog .wt-tabs .nav-tabs > li > a.active {
  border: 0px;
  background: #f6f6f6;
  color: #000;
}

.sign-in-dialog #register-one .wt-tabs .tab-pane {
  padding: 25px 0px 0px 0px;
}

.sign-in-dialog #register-one .wt-tabs .nav-tabs {
  border-bottom: 0px;
}

.sign-in-dialog #register-one .wt-tabs .nav-tabs > li a.active {
  background-color: #fff;
  color: #000 !important;
}

.sign-in-dialog #register-one .wt-tabs .nav-tabs > li a {
  padding: 10px 16px;
  margin-right: 0px;
}

.ls-inputicon-box {
  position: relative;
}

.ls-inputicon-box .fs-input-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 44px;
  z-index: 5;
  line-height: 44px;
  text-align: center;
  font-size: 16px;
}

.ls-inputicon-box .form-control {
  padding-left: 35px;
}

.ls-inputicon-box .bootstrap-select .dropdown-toggle {
  padding-left: 35px;
}

.ls-inputicon-box .remove_field {
  position: absolute;
  right: 4px;
  top: 2px;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background-color: #fff;
  display: block;
}

@media only screen and (max-width: 1200px) {
  .header-nav .nav > li > a {
    padding: 33px 8px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li > a {
    padding: 10px 15px;
  }
}
/*================================================================================
	Shop Page Css End
=================================================================================*/